.howitworks {
  nav {
    @include media-type(992px) {
      & .nav-tabs{
        display: block;
      }
    }

    background: none;
    min-height: unset;
    padding: unset!important;
    width: 100%;

    .nav-link {
      background-color: map-get($map: $palette, $key: dark-font);
      border-top-left-radius: .75rem;
      border-top-right-radius: .75rem;
      margin: 0 7.5px;
      width: 150px;
      height: 50px;
      justify-content: center;
      align-items: center;
      text-align: center;
      &#cws-tab{
        margin-left: 0;
      }
      // @media screen and (max-width: 992px) {
      //   &#faq-tab{
      //     margin-left: 0;
      //   }
      // }
      // @media screen and (max-width: 768px) {
      //   &#xp-tab{
      //     margin-left: 0;
      //   }
      //   &#faq-tab{
      //     margin-left: 7.5px;
      //   }
      // }
      @include media-type(992px) {
        width: 100%;
        margin: 0 !important;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
      &.active {
        background-color: map-get($map: $palette, $key: btn-light-blue);
        align-items: center;
        color: map-get($map: $palette, $key: white);
        border-color: #dee2e6 #dee2e6 #fff;
        display: flex!important;
      }
    }
  }
  
  #nav-tabContent.tab-content {
    //padding: 1;
    
    .bold.border-bottom.border-1 {
      border-color: map-get($map: $palette, $key: btn-light-blue)!important;
    }
  }
}

