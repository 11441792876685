#manage-opportunities {


  #hero {

  }

  & > .container {
    max-width: 1073px;
    margin-top: 80px;
  }

  .container {
    vertical-align: middle;


    .card-header { //for container card
      .border-bottom {
        height: 100%;
      }
    }
    .custom-select {
      max-width: 289px;
    }
  }

}
