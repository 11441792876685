html, body {
  min-height: 100%;
  min-width: 100%;
}

.show {
  display: block !important;
}

.fas, .fa, .fab {
  color: inherit;
}

.copywrite-mobile {
  display: none;
  @include media-type(phone) {
    display: block;
    margin-bottom: 10px;
  }
}

ul {
  padding-left: 15px;
  ul {
    padding: 15px;
  }
}

ul.dots {
  list-style: disc;
}

ul.numbered {
  list-style: decimal;
}

ul.letter {
  list-style: upper-alpha;
}

ul.letter.small {
  list-style: lower-alpha;
}

span.tip-action {
  align-self: center;
  width: 15px;
  max-width: 15px;
  margin: 0 10px;
  height: 15px;
  color: #fff;
  background: #1b282e;
  margin-top: 0 !important;
}

#profile {

  .tip-holder {
    width: 250px !important;
  }
}

.tip-action {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-style: normal;
}

.table-responsive .table {
  min-width: 893px;
}

.copywrite-desktop {
  display: flex;
  @include media-type(phone) {
    display: none !important;
  }
}

.z-index-1 {
  z-index: 1;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.btn-clear {
  padding: 0;
  background: none;
}

.shrink {
  max-height: 0px !important;
  width: 100%;
  transition: max-height .5s linear;
}

.stretch {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col.l2 {
  @include flex(flex);
  flex: 0 0 20%;
  max-width: 20%;
}

.col.l3 {
  @include flex(flex);
  flex: 0 0 25%;
  max-width: 25%;
}

a:hover {
  text-decoration: none;
}

body {
  background-color: $white;

  main {
    min-height: calc(100vh - 145px);
    background-color: $light-grey;
  }

  &.home {
    main {

      background: linear-gradient(
                      to top,
                      map-get($palette, off-white), $white 55%
      );

      padding: $front-page-main-padding;
    }
  }
  // NOTE: end of home

  &.no-hero {
    main {
      padding: 150px 0 98px 0;
      background-color: $white;
    }
    // main > section {
    //   padding: 150px 0 98px 0;
    // }
  }

  &.search {
    main {
      background-color: $white;
    }
  }
  &.login-page {
    & main {
      @include media-type(638px) {
        padding: 100px 0 20px 0;
      }
    }
  }
}

.jumbotron {
  border-radius: 0;
}

#hero {
  min-height: 428px;
  background-color: $med-grey;
  @include flex(flex, ai_center, jc_center);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 110px;
  &.main-panel {
    padding-top: 0;
    @include media-type(phone) {
      padding-top: 110px;
    }
  }

  h1, p, a {
    font-family: 'Roboto', sans-serif !important;
  }

  & .content > .row {
    height: 100%;
  }

  section {
    width: 100%;

    h1, h2 {
      text-align: center;
      width: 100%;
      color: $white;
    }

    p {
      text-align: center;
      width: 100%;
      color: $white;
    }
  }
}

.light {
  font-weight: 300 !important;
}

.reg {
  font-weight: 400 !important;
  margin-bottom: 20px;
}

.med {
  font-weight: 500 !important;
  // margin-bottom: 15px;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold, b, strong {
  font-weight: 700 !important;
}

.tiny {
  font-size: 14px !important;
}

.text {
  color: $white;
}

.text-18 {
  font-size: 18px !important;
  letter-spacing: .025em;
  line-height: 26px;
}

.btn {
  font-size: 14px;
  letter-spacing: .025em;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

.full-width {
  width: 100%;
}

li, time, div, input, textarea {
  font-family: 'Fira Sans', sans-serif;
}

p {
  padding-bottom: 0;
  // margin-bottom: 11px;
  font-family: 'Fira Sans', sans-serif;
}

h1 {
  font-size: 61px;
  font-weight: normal;
  font-family: 'Fira Sans', sans-serif;
}

h2 {
  color: map-get($palette, dark-font);
  font-size: 42px;
  line-height: 42px;
  letter-spacing: .025em;
  font-family: 'Fira Sans', sans-serif;
}

h3 {
  color: map-get($palette, dark-font);
  font-size: 30px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

a {
  color: #082a49;
  font-weight: bold;
  font-family: 'Fira Sans', sans-serif;
}

label {
  font-weight: normal !important;
  color: map-get($palette, dark-font);
  font-family: 'Fira Sans', sans-serif;
}

.inline {
  display: inline !important;
}

.title {
  font-size: 14px;
  line-height: 20px;
}

.content {
  font-size: 14px;
  line-height: 22px;
}

.quote {
  font-size: 32px !important;
  line-height: 18px !important;
  color: map-get($palette, btn-dark-blue);
}

.home-overlay {
  @include active-overlay;
  background: linear-gradient(to top, $hero-bg-color, transparent 77%);
}

.overlay {
  @include active-overlay;
  background: linear-gradient(to bottom, rgba($hero-bg-color, .77), rgba($black, .77));
}

.darken-overlay {
  @include active-overlay;
  //background-color: rgba($black, 50%);
}

.bg-btn-red.btn-lg {
  padding: 1.6em 3.25em !important;
  font-size: 17px !important;
  line-height: 21px !important;
  letter-spacing: .025em !important;
  font-weight: 500;
  @include media-type(420px) {
    padding: 20px !important;
  }
}

.hide {
  display: none !important;
}

.flex {
  @include flex(flex, wrap);
}

.space-between {
  justify-content: space-between;
}

.jc-center {
  @include flex(jc_center);
}

.fauser {
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 4em;
    color: white;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.profile-links {
  height: 40px;
  @include flex(jc_center);
  .nav-link {
    padding: 0;
    width: 40px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
    a {
      width: 100%;
      background-color: #e8ecef;
      @include flex(ai_center, jc_center);
      position: relative;
      & .icon-notify {
        position: absolute;
        top: -6px;
        right: 0;
        color: #ffffff;
        background: #a11328;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        text-align: center;
        padding: 1px 4px;
        font-size: 10px;
      }
    }
  }
}

.add-pointer, .lightbox-trigger, .datepicker {
  cursor: pointer;
}

.rounded-div {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  background-color: $med-grey;
  min-width: 40px;
  min-height: 40px;
  position: relative;

  * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
  }
}

.rounded-div.people {
  background-color: $med-grey;
  border-radius: 50%;
  height: 103px !important;
  width: 103px !important;
}

ul {
  li {
    padding: 0;
  }
}

.tooltip-plane {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip-inner {
  padding: 10px 25px;
  right: 100%;
  background-color: map-get($palette, off-white);
  color: map-get($palette, btn-dark-blue);
  font-weight: bold;

  &:before {

    background-color: map-get($palette, off-white);
  }
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #00cc00 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-right-color: #00cc00 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-right-color: #00cc00 !important;
  background-color: #00cc00 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-right-color: #00cc00 !important;
}

// NOTE: search

.search #hero {
  h1, h2 {
    margin-bottom: .5em;
    color: map-get($palette, font);
  }
  p {
    color: map-get($palette, font);
  }
}

.animation-wrapper {
  @include flex(flexi, wrap);
}

// modal fix
.lightbox {
  position: fixed;
  height: 100vh !important;
}

// NOTE: notification message empty

// .fas.fa-comment-alt.position-relative {
//   .fab {
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-70%);
//   }
// }
// NOTE: link-fill

.link-fill {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.stretch {
  flex: 1;
}

.no-stretch {
  flex: unset !important;
}

// NOTE: Figure image

figure {
  a {

    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.user-bio figure {
  height: 230px;
  width: 230px;
  // height: 167px;
  // width: 167px;
}

.card figure {
  height: 230px;
  width: 230px;

  img {
    width: 100%;
    height: auto;
  }
}

.item figure {
  position: relative !important;
  height: 40px;
  width: 40px;
  top: auto !important;
  left: auto !important;
  transform: none !important;
  margin: 0;
}

figure.sm {
  height: 40px !important;
  width: 40px !important;
}

figure.md {
  height: 140px !important;
  width: 140px !important;
}

figure.lg {
  height: 230px !important;
  width: 230px !important;
}

.card-notification figure {
  height: 40px;
  width: 40px;
}

#hero figure {
  height: 140px;
  width: 140px;
}

.favorited {
  color: map-get($palette, yellow);
}

.status-active {
  color: map-get($palette, active);
}

.item.waiting-approval {
  &:hover {

    p a {
      color: #777;
    }
    img {
      filter: grayscale(75%);
      -webkit-filter: grayscale(75%);
      -moz-filter: grayscale(75%);
      -ms-filter: grayscale(75%);
      -o-filter: grayscale(75%);
      filter: url(/assets/svg/desaturate.svg#greyscale);
      filter: gray;
      -webkit-filter: grayscale(.75);
    }
  }
  p a {
    color: $med-grey;
  }
  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: url(/assets/svg/desaturate.svg#greyscale);
    filter: gray;
    -webkit-filter: grayscale(1);
  }

}

// NOTE: price box

.price-box {
  width: 160px;

  .subtotal, .taxes, .total {

    padding-top: 10px;
    padding-bottom: 10px;
  }
  .subtotal, .taxes {
    border-bottom: 1px solid map-get($palette, light-grey);
  }
  .subtotal {

  }
  .taxes {
    .result {
      margin-left: 19px;
    }
  }
  .total {
    .result {
      margin-left: 21px;
    }

  }
}

// NOTE: quick fix for green stuff
.fill.green {
  background-color: map-get($palette, btn-dark-blue) !important;
}

.form-field .switch-box:checked ~ .switch:after {
  background-color: map-get($palette, btn-dark-blue);

}

// NOTE: search people form no results found
.empty {
  i {
    font-size: 24px !important;
    color: map-get($palette, btn-dark-blue);
  }
  .text {
    color: map-get($palette, font);
  }
}

// Content pages
#content {
  & .row {
    & p {
      line-height: 22px;
    }
    & ul {
      & li {
        line-height: 22px;
      }
    }
  }
}
