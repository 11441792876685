#hero{
  section{
    p{
      &.classification-text{
        text-align: left;
      }
    }
  }
}
.op-card{
  p.classification-text{
    color: $h2-color;
  }
}
.row {
  .importer-progress-container {
    background: #fff;
    overflow: hidden;
    padding: 0;
    margin: 0 0 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
    color: #555;
    text-align: left;

    header {
      border-bottom: 1px solid #eee;
      margin: 0;
      padding: 24px 24px 0;
    }

    section {
      padding: 24px 24px 0;
    }

    h2 {
      margin: 0 0 24px;
      color: #555;
      font-size: 24px;
      font-weight: normal;
      line-height: 1em;
    }

    p {
      font-size: 1em;
      line-height: 1.75em;
      font-size: 16px;
      color: #555;
      margin: 0 0 24px;
    }

    .spinner {
      display: none;
    }

    progress {
      width: 100%;
      height: 42px;
      margin: 0 auto 24px;
      display: block;
      -webkit-appearance: none;
      border: none;
      display: none;
      background: #f5f5f5;
      border: 2px solid #eee;
      border-radius: 4px;
      padding: 0;
      box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2);
    }

    progress::-webkit-progress-bar {
      background: transparent none;
      border: 0;
      border-radius: 4px;
      padding: 0;
      box-shadow: none;
    }

    progress::-webkit-progress-value {
      border-radius: 3px;
      box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
      background: #2e4d9c;
      background: linear-gradient(to bottom, #2e4d9c, #06245c), #073b93;
      transition: width 1s ease;
    }

    progress::-moz-progress-bar {
      border-radius: 3px;
      box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
      background: #2e4d9c;
      background: linear-gradient(to bottom, #2e4d9c, #06245c), #073b93;
      transition: width 1s ease;
    }

    progress::-ms-fill {
      border-radius: 3px;
      box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
      background: #2e4d9c;
      background: linear-gradient(to bottom, #2e4d9c, #06245c), #073b93;
      transition: width 1s ease;
    }
    &.importing{
      .spinner{
        display: block;
      }
      progress{
        display: block;
      }
    }
  }

  & .action-controls {
    .fa-star {
      color: $med-grey;
      margin-right: 8px;
      cursor: pointer;

      &.favourited {
        color: map-get($palette, yellow) !important;
      }
    }
  }
}

#opps, #manage-rep, #manage-adverts {

  & > .container {
    max-width: 1073px;
    padding-top: 80px;


    .card.mb-3 {
      .card-body {
        @include media-type(phone) {

          justify-content: center;
        }
      }
    }

    .card-header {

      padding-left: 40px;
      padding-right: 40px;

      .title, .extra {

        padding-left: 8px;
        padding-right: 8px;
      }

      .title {
        @include media-type(phone) {
          margin-bottom: 15px;
        }

        a {
          @include media-type(phone) {

            width: 100%;
          }
        }
      }
    }

    .card-body {
      padding: 35px 40px;

      .notice {
        padding-left: 8px;
      }
    }
  }

  #hero {
    @include flex(flex, ai_center);

    section {
      width: 100%;
      h1 {
        text-align: center;
        width: 100%;
        color: $white;
      }
    }
  }

  // NOTE: end of hero

  .container {
    vertical-align: middle;

    .custom-select {
      max-width: 289px;
    }


  }
}
