html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 14px;
  color: #5d5d5d;
  word-break: normal; }

input, textarea, select, button {
  font-family: 'Roboto', sans-serif;
  font-weight: normal; }

ul {
  list-style: none; }

h1 {
  font-size: 61px;
  font-weight: normal;
  font-family: 'Fira Sans', sans-serif; }
  @media only screen and (max-width: 1366px) {
    h1 {
      font-size: 55px !important; } }
  @media only screen and (max-width: 825px) {
    h1 {
      font-size: 40px !important; } }

h2 {
  color: #2f2f2f;
  font-size: 42px;
  line-height: 42px;
  letter-spacing: .025em;
  font-family: 'Fira Sans', sans-serif; }
  @media only screen and (max-width: 1366px) {
    h2 {
      font-size: 35px; } }
  @media only screen and (max-width: 825px) {
    h2 {
      font-size: 30px; } }

h3 {
  color: #2f2f2f;
  font-size: 30px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif; }
  @media only screen and (max-width: 1366px) {
    h3 {
      font-size: 30px; } }

a {
  color: #082a49;
  font-weight: bold;
  font-family: 'Fira Sans', sans-serif; }

label {
  font-weight: normal !important;
  color: #2f2f2f;
  font-family: 'Fira Sans', sans-serif; }

p {
  padding-bottom: 0;
  font-family: 'Fira Sans', sans-serif; }
  @media only screen and (max-width: 825px) {
    p {
      font-size: 14px; } }

.bg-transparent {
  background-color: transparent !important; }

.text-transparent {
  color: transparent !important; }

.border-transparent {
  border-color: transparent !important; }

.bg-white {
  background-color: #fefefe !important; }

.text-white {
  color: #fefefe !important; }

.border-white {
  border-color: #fefefe !important; }

.bg-off-white {
  background-color: #f4f4f4 !important; }

.text-off-white {
  color: #f4f4f4 !important; }

.border-off-white {
  border-color: #f4f4f4 !important; }

.bg-light-grey {
  background-color: #dedede !important; }

.text-light-grey {
  color: #dedede !important; }

.border-light-grey {
  border-color: #dedede !important; }

.bg-nav-blue {
  background-color: #cc4b37 !important; }

.text-nav-blue {
  color: #cc4b37 !important; }

.border-nav-blue {
  border-color: #cc4b37 !important; }

.bg-hero-blue {
  background-color: #0a3761 !important; }

.text-hero-blue {
  color: #0a3761 !important; }

.border-hero-blue {
  border-color: #0a3761 !important; }

.bg-btn-light-blue {
  background-color: #0f5291 !important; }

.text-btn-light-blue {
  color: #0f5291 !important; }

.border-btn-light-blue {
  border-color: #0f5291 !important; }

.bg-btn-dark-blue {
  background-color: #1a2344 !important; }

.text-btn-dark-blue {
  color: #1a2344 !important; }

.border-btn-dark-blue {
  border-color: #1a2344 !important; }

.bg-btn-red {
  background-color: #e83b37 !important; }

.text-btn-red {
  color: #e83b37 !important; }

.border-btn-red {
  border-color: #e83b37 !important; }

.bg-btn-grey {
  background-color: #2f2f2f !important; }

.text-btn-grey {
  color: #2f2f2f !important; }

.border-btn-grey {
  border-color: #2f2f2f !important; }

.bg-active {
  background-color: #58d25d !important; }

.text-active {
  color: #58d25d !important; }

.border-active {
  border-color: #58d25d !important; }

.bg-pink {
  background-color: #ad2b53 !important; }

.text-pink {
  color: #ad2b53 !important; }

.border-pink {
  border-color: #ad2b53 !important; }

.bg-font {
  background-color: #5d5d5d !important; }

.text-font {
  color: #5d5d5d !important; }

.border-font {
  border-color: #5d5d5d !important; }

.bg-dark-font {
  background-color: #2f2f2f !important; }

.text-dark-font {
  color: #2f2f2f !important; }

.border-dark-font {
  border-color: #2f2f2f !important; }

.bg-yellow {
  background-color: #f2d900 !important; }

.text-yellow {
  color: #f2d900 !important; }

.border-yellow {
  border-color: #f2d900 !important; }

.bg-unfavourite {
  background-color: #f2d900 !important; }

.text-unfavourite {
  color: #f2d900 !important; }

.border-unfavourite {
  border-color: #f2d900 !important; }

.bg-muted {
  background-color: #939393 !important; }

.text-muted {
  color: #939393 !important; }

.border-muted {
  border-color: #939393 !important; }

.bg-green {
  background-color: #28a745 !important; }

.text-green {
  color: #28a745 !important; }

.border-green {
  border-color: #28a745 !important; }

.bg-orange {
  background-color: orange !important; }

.text-orange {
  color: orange !important; }

.border-orange {
  border-color: orange !important; }

.mb-0 {
  margin-bottom: 0; }

.mb-1 {
  margin-bottom: 1em; }

.mb-2 {
  margin-bottom: 2em; }

.mb-3 {
  margin-bottom: 3em; }

.mb-4 {
  margin-bottom: 4em; }

.mb-5 {
  margin-bottom: 5em; }

.text-12 {
  font-size: 12px !important; }

.text-13 {
  font-size: 13px !important; }

.text-14 {
  font-size: 14px !important; }

.text-15 {
  font-size: 15px !important; }

.text-16 {
  font-size: 16px !important; }

.text-20 {
  font-size: 20px !important; }

.text-30 {
  font-size: 30px !important; }

.text-80 {
  font-size: 80px !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.btn {
  padding: 12px 15px;
  border-radius: 0.55rem;
  font-size: 14px;
  line-height: 17.54px;
  font-weight: 400; }

.scr-reader-text {
  top: -1000%;
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute !important; }

.scr-reader-text:focus {
  left: 6px;
  top: 7px;
  height: auto;
  width: auto;
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 23px 14px;
  background: #fefefe;
  color: #0a0a0a;
  z-index: 999999;
  line-height: normal;
  text-decoration: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6); }

input, input.form-control,
select, select.custom-select,
.custom-file-label,
textarea, textarea.form-control,
button.form-button,
.custom-checks-box .checks-box,
.form-field input.input,
.form-field select.input,
.form-field textarea.input,
.file-upload .file-upload-wrapper .file-uploader .file-container {
  color: #5d5d5d;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: .025em;
  border: 2px solid rgba(0, 0, 0, 0.1); }

body:not(.search) .form-field input.input,
body:not(.search) .form-field select.input {
  height: 42.3333px; }

textarea.profile {
  height: 164px; }

.form-field .input:focus {
  border-color: #1a2344; }

.form-field-label {
  text-transform: capitalize; }

.checkbox-item {
  display: block; }
  .checkbox-item input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0; }
    .checkbox-item input[type="checkbox"]:checked + label span.checked,
    .checkbox-item input[type="checkbox"]:checked:active + label span.checked {
      display: block; }
    .checkbox-item input[type="checkbox"]:checked + label span.not-checked,
    .checkbox-item input[type="checkbox"]:checked:active + label span.not-checked {
      display: none; }
  .checkbox-item label {
    cursor: pointer;
    display: flex;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center; }
    .checkbox-item label span {
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      color: #7a7a7a;
      letter-spacing: .1em;
      text-transform: capitalize; }
      .checkbox-item label span.text {
        margin-left: 10px; }
      .checkbox-item label span.checked {
        display: none; }
        .checkbox-item label span.checked div {
          padding-top: 2px;
          height: 14px;
          width: 14px;
          background-color: #1a2344;
          border: thin solid #1a2344;
          box-shadow: inset 0 0 0 3px #fefefe; }
      .checkbox-item label span.not-checked div {
        padding-top: 2px;
        height: 14px;
        width: 14px;
        box-shadow: inset 0 0 0 1px #7a7a7a; }

.checks-box {
  padding: 10px 0 !important;
  height: 100%;
  max-height: 200px !important;
  overflow: scroll; }
  .checks-box .list-item {
    padding: 0px !important;
    padding-left: 20px !important; }

.custom-checks-box .checks-box ul.checks-list li .check-field input[type="checkbox"] + label {
  border: 0;
  position: relative;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent; }

.custom-checks-box .checks-box ul.checks-list li .check-field input[type="checkbox"]:checked + label {
  border: 0;
  background-color: transparent; }

.form-field.checkbox-container .check-wrap .check-field input[type=checkbox]:checked + label {
  background-color: #1a2344;
  border-color: #1a2344 !important; }

.custom-checks-box .check-field input[type="checkbox"] + label {
  visibility: hidden; }

.check-field input[type="checkbox"] + label {
  color: #0f5291; }
  .check-field input[type="checkbox"] + label i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.check-field {
  display: flex;
  display: flex;
  align-items: center; }

.list-item:hover {
  background-color: rgba(15, 82, 145, 0.2); }

.check-field input[type="checkbox"]:checked + label {
  visibility: visible; }

.check-label {
  width: 100%; }
  .check-label label {
    width: 100%;
    font-weight: 300 !important;
    padding: 9px 0;
    color: #2f2f2f !important; }

.btn-container button.button {
  display: inline-block !important; }

.input-group-append button {
  padding: .375rem .75rem; }

.fa-edit {
  cursor: pointer; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: ""; }

.custom-file-label::after {
  padding: 0;
  border: 0; }

.file-container {
  background-color: white; }
  .file-container .filename {
    background-color: white; }

.form-field .icons-container .spinner-container {
  opacity: 0;
  transform: transformY(-50%) scale(1) !important; }

.form-field.show-spinner .icons-container .spinner-container {
  opacity: 1;
  transform: transformY(-50%) scale(1) !important; }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  margin-bottom: 10px; }

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 13px;
  height: 15px;
  border: 1px solid #cccccc;
  background: #fff; }

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 7px;
  height: 7px;
  background: #0c4173;
  position: absolute;
  top: 6px;
  left: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 2px; }

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

nav {
  background-color: #082a49;
  min-height: 92px;
  padding: 0 !important; }
  @media only screen and (max-width: 1112px) {
    nav {
      min-height: 85px; } }
  @media only screen and (max-width: 1366px) {
    nav .navbar-brand {
      height: 85px !important;
      margin-right: 10px !important; } }
  nav > .container {
    max-width: 100%;
    max-height: none;
    padding: 0 4rem !important; }
    @media only screen and (max-width: 1366px) {
      nav > .container {
        padding: 0 2rem !important; }
        nav > .container .form-inline .navbar-nav {
          width: 100%; }
          nav > .container .form-inline .navbar-nav .nav-item {
            padding: 10px 0; }
            nav > .container .form-inline .navbar-nav .nav-item a, nav > .container .form-inline .navbar-nav .nav-item span {
              width: 100%;
              justify-content: center; } }
    @media only screen and (max-width: 1112px) {
      nav > .container .navbar-nav {
        margin-right: inherit !important;
        width: 100%;
        order: 2; } }
    @media only screen and (max-width: 1112px) {
      nav > .container .navbar-nav .nav-item a {
        justify-content: center; } }
    nav > .container .collapse.show {
      max-height: calc(100vh - 85px);
      overflow: hidden;
      overflow-y: auto;
      padding-bottom: 20px; }
    @media screen and (min-width: 1113px) {
      nav > .container {
        flex-wrap: nowrap !important; }
        nav > .container .hamburger {
          display: none !important; }
        nav > .container .collapse {
          display: flex !important;
          padding-bottom: 0 !important; }
          nav > .container .collapse .flex {
            flex: 1; }
        nav > .container .navbar-nav {
          flex-direction: row; } }
  nav .navbar-brand, nav .nav-link, nav .dropdown-item {
    color: #fefefe;
    height: 92px;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: .025em; }
    @media only screen and (max-width: 1112px) {
      nav .navbar-brand, nav .nav-link, nav .dropdown-item {
        height: 80px; } }
    nav .navbar-brand i, nav .nav-link i, nav .dropdown-item i {
      margin-right: 8px;
      color: inherit; }
    nav .navbar-brand .nav-logo, nav .navbar-brand .nav-item, nav .nav-link .nav-logo, nav .nav-link .nav-item, nav .dropdown-item .nav-logo, nav .dropdown-item .nav-item {
      height: 100%; }
  nav .nav-link {
    width: 100%; }
  nav .nav-item.dropdown {
    display: block; }
  nav .dropdown-menu {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding-top: .525rem; }
    nav .dropdown-menu .dropdown-inner-menu {
      background-color: #082a49;
      border-radius: .25rem; }
      nav .dropdown-menu .dropdown-inner-menu .dropdown-item {
        height: auto;
        padding: 10px 20px; }
        nav .dropdown-menu .dropdown-inner-menu .dropdown-item:hover {
          background-color: inherit;
          color: #fefefe;
          border-radius: .25rem; }
  nav .dropdown-toggle::after {
    content: none; }
  nav .navbar-collapse {
    background-color: #082a49; }
  @media only screen and (max-width: 1112px) {
    nav .form-inline {
      width: 100%;
      order: 1; } }
  nav .form-inline .navbar-nav {
    padding: 0; }
    nav .form-inline .navbar-nav .nav-item {
      padding-bottom: 0; }
      nav .form-inline .navbar-nav .nav-item .btn {
        font-size: 14px;
        letter-spacing: .025em; }
  nav .navbar-nav {
    padding-bottom: 0; }
    nav .navbar-nav .nav-item {
      padding-bottom: 0; }
      @media only screen and (min-width: 1112px) {
        nav .navbar-nav .nav-item:hover .dropdown-menu,
        nav .navbar-nav .nav-item a[aria-expanded="true"] + .dropdown-menu {
          display: block;
          position: absolute; } }
      @media only screen and (min-width: 1113px) and (max-width: 1200px) {
        nav .navbar-nav .nav-item.nav-btn {
          display: none; } }
  nav .nav-item {
    display: flex;
    display: flex;
    align-items: center; }
  nav a {
    display: flex !important;
    align-items: center;
    vertical-align: middle; }
  nav .action-container ul li:first-child {
    margin-right: 20px; }
    @media only screen and (max-width: 1111px) {
      nav .action-container ul li:first-child {
        margin-right: 0px; } }
  nav .action-container ul.log-in li:first-child {
    margin-right: 40px; }
  nav .action-container .bg-btn-red.lightbox-trigger {
    padding-left: 3.5em;
    padding-right: 3.5em; }
  @media only screen and (min-width: 1112px) {
    nav .desktop-hide {
      display: none; } }
  @media only screen and (max-width: 1112px) {
    nav .mobile-hide {
      display: none; } }

.navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem; }

@media only screen and (max-width: 500px) {
  nav .navbar-nav {
    margin-top: 10px; } }

@media only screen and (max-width: 500px) {
  nav .navbar-nav .nav-item a.nav-link {
    height: 60px; } }

html, body {
  min-height: 100%;
  min-width: 100%; }

.show {
  display: block !important; }

.fas, .fa, .fab {
  color: inherit; }

.copywrite-mobile {
  display: none; }
  @media only screen and (max-width: 825px) {
    .copywrite-mobile {
      display: block;
      margin-bottom: 10px; } }

ul {
  padding-left: 15px; }
  ul ul {
    padding: 15px; }

ul.dots {
  list-style: disc; }

ul.numbered {
  list-style: decimal; }

ul.letter {
  list-style: upper-alpha; }

ul.letter.small {
  list-style: lower-alpha; }

span.tip-action {
  align-self: center;
  width: 15px;
  max-width: 15px;
  margin: 0 10px;
  height: 15px;
  color: #fff;
  background: #1b282e;
  margin-top: 0 !important; }

#profile .tip-holder {
  width: 250px !important; }

.tip-action {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-style: normal; }

.table-responsive .table {
  min-width: 893px; }

.copywrite-desktop {
  display: flex; }
  @media only screen and (max-width: 825px) {
    .copywrite-desktop {
      display: none !important; } }

.z-index-1 {
  z-index: 1; }

.full-height {
  height: 100%; }

.full-width {
  width: 100%; }

.btn-clear {
  padding: 0;
  background: none; }

.shrink {
  max-height: 0px !important;
  width: 100%;
  transition: max-height .5s linear; }

.stretch {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col.l2 {
  display: flex;
  display: flex;
  flex: 0 0 20%;
  max-width: 20%; }

.col.l3 {
  display: flex;
  display: flex;
  flex: 0 0 25%;
  max-width: 25%; }

a:hover {
  text-decoration: none; }

body {
  background-color: #fefefe; }
  body main {
    min-height: calc(100vh - 145px);
    background-color: #f0f2f5; }
  body.home main {
    background: linear-gradient(to top, #f4f4f4, #fefefe 55%);
    padding: 0 0 145px 0; }
  body.no-hero main {
    padding: 150px 0 98px 0;
    background-color: #fefefe; }
  body.search main {
    background-color: #fefefe; }
  @media only screen and (max-width: 638px) {
    body.login-page main {
      padding: 100px 0 20px 0; } }

.jumbotron {
  border-radius: 0; }

#hero {
  min-height: 428px;
  background-color: #555555;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 110px; }
  #hero.main-panel {
    padding-top: 0; }
    @media only screen and (max-width: 825px) {
      #hero.main-panel {
        padding-top: 110px; } }
  #hero h1, #hero p, #hero a {
    font-family: 'Roboto', sans-serif !important; }
  #hero .content > .row {
    height: 100%; }
  #hero section {
    width: 100%; }
    #hero section h1, #hero section h2 {
      text-align: center;
      width: 100%;
      color: #fefefe; }
    #hero section p {
      text-align: center;
      width: 100%;
      color: #fefefe; }

.light {
  font-weight: 300 !important; }

.reg {
  font-weight: 400 !important;
  margin-bottom: 20px; }

.med {
  font-weight: 500 !important; }

.semi-bold {
  font-weight: 600 !important; }

.bold, b, strong {
  font-weight: 700 !important; }

.tiny {
  font-size: 14px !important; }

.text {
  color: #fefefe; }

.text-18 {
  font-size: 18px !important;
  letter-spacing: .025em;
  line-height: 26px; }

.btn {
  font-size: 14px;
  letter-spacing: .025em;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif; }

.full-width {
  width: 100%; }

li, time, div, input, textarea {
  font-family: 'Fira Sans', sans-serif; }

p {
  padding-bottom: 0;
  font-family: 'Fira Sans', sans-serif; }

h1 {
  font-size: 61px;
  font-weight: normal;
  font-family: 'Fira Sans', sans-serif; }

h2 {
  color: #2f2f2f;
  font-size: 42px;
  line-height: 42px;
  letter-spacing: .025em;
  font-family: 'Fira Sans', sans-serif; }

h3 {
  color: #2f2f2f;
  font-size: 30px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif; }

a {
  color: #082a49;
  font-weight: bold;
  font-family: 'Fira Sans', sans-serif; }

label {
  font-weight: normal !important;
  color: #2f2f2f;
  font-family: 'Fira Sans', sans-serif; }

.inline {
  display: inline !important; }

.title {
  font-size: 14px;
  line-height: 20px; }

.content {
  font-size: 14px;
  line-height: 22px; }

.quote {
  font-size: 32px !important;
  line-height: 18px !important;
  color: #1a2344; }

.home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #0c4173, transparent 77%); }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(12, 65, 115, 0.77), rgba(10, 10, 10, 0.77)); }

.darken-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.bg-btn-red.btn-lg {
  padding: 1.6em 3.25em !important;
  font-size: 17px !important;
  line-height: 21px !important;
  letter-spacing: .025em !important;
  font-weight: 500; }
  @media only screen and (max-width: 420px) {
    .bg-btn-red.btn-lg {
      padding: 20px !important; } }

.hide {
  display: none !important; }

.flex {
  display: flex;
  display: flex;
  flex-wrap: wrap; }

.space-between {
  justify-content: space-between; }

.jc-center {
  justify-content: center; }

.fauser {
  position: relative; }
  .fauser::before {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 4em;
    color: white;
    transform: translate(-50%, -50%);
    z-index: 1; }

.profile-links {
  height: 40px;
  justify-content: center; }
  .profile-links .nav-link {
    padding: 0;
    width: 40px;
    margin-right: 20px; }
    .profile-links .nav-link:last-child {
      margin-right: 0px; }
    .profile-links .nav-link a {
      width: 100%;
      background-color: #e8ecef;
      align-items: center;
      justify-content: center;
      position: relative; }
      .profile-links .nav-link a .icon-notify {
        position: absolute;
        top: -6px;
        right: 0;
        color: #ffffff;
        background: #a11328;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        text-align: center;
        padding: 1px 4px;
        font-size: 10px; }

.add-pointer, .lightbox-trigger, .datepicker {
  cursor: pointer; }

.rounded-div {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  background-color: #555555;
  min-width: 40px;
  min-height: 40px;
  position: relative; }
  .rounded-div * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px; }

.rounded-div.people {
  background-color: #555555;
  border-radius: 50%;
  height: 103px !important;
  width: 103px !important; }

ul li {
  padding: 0; }

.tooltip-plane {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.tooltip-inner {
  padding: 10px 25px;
  right: 100%;
  background-color: #f4f4f4;
  color: #1a2344;
  font-weight: bold; }
  .tooltip-inner:before {
    background-color: #f4f4f4; }

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #00cc00 !important; }

.tooltip.bs-tooltip-left .arrow:before {
  border-right-color: #00cc00 !important; }

.tooltip.bs-tooltip-bottom .arrow:before {
  border-right-color: #00cc00 !important;
  background-color: #00cc00 !important; }

.tooltip.bs-tooltip-top .arrow:before {
  border-right-color: #00cc00 !important; }

.search #hero h1, .search #hero h2 {
  margin-bottom: .5em;
  color: #5d5d5d; }

.search #hero p {
  color: #5d5d5d; }

.animation-wrapper {
  display: flex !important;
  flex-wrap: wrap; }

.lightbox {
  position: fixed;
  height: 100vh !important; }

.link-fill {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; }

.stretch {
  flex: 1; }

.no-stretch {
  flex: unset !important; }

figure a {
  width: 100%;
  height: 100%; }

figure img {
  width: 100%;
  height: 100%; }

.user-bio figure {
  height: 230px;
  width: 230px; }

.card figure {
  height: 230px;
  width: 230px; }
  .card figure img {
    width: 100%;
    height: auto; }

.item figure {
  position: relative !important;
  height: 40px;
  width: 40px;
  top: auto !important;
  left: auto !important;
  transform: none !important;
  margin: 0; }

figure.sm {
  height: 40px !important;
  width: 40px !important; }

figure.md {
  height: 140px !important;
  width: 140px !important; }

figure.lg {
  height: 230px !important;
  width: 230px !important; }

.card-notification figure {
  height: 40px;
  width: 40px; }

#hero figure {
  height: 140px;
  width: 140px; }

.favorited {
  color: #f2d900; }

.status-active {
  color: #58d25d; }

.item.waiting-approval:hover p a {
  color: #777; }

.item.waiting-approval:hover img {
  filter: grayscale(75%);
  -webkit-filter: grayscale(75%);
  -moz-filter: grayscale(75%);
  -ms-filter: grayscale(75%);
  -o-filter: grayscale(75%);
  filter: url(/assets/svg/desaturate.svg#greyscale);
  filter: gray;
  -webkit-filter: grayscale(0.75); }

.item.waiting-approval p a {
  color: #555555; }

.item.waiting-approval img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: url(/assets/svg/desaturate.svg#greyscale);
  filter: gray;
  -webkit-filter: grayscale(1); }

.price-box {
  width: 160px; }
  .price-box .subtotal, .price-box .taxes, .price-box .total {
    padding-top: 10px;
    padding-bottom: 10px; }
  .price-box .subtotal, .price-box .taxes {
    border-bottom: 1px solid #dedede; }
  .price-box .taxes .result {
    margin-left: 19px; }
  .price-box .total .result {
    margin-left: 21px; }

.fill.green {
  background-color: #1a2344 !important; }

.form-field .switch-box:checked ~ .switch:after {
  background-color: #1a2344; }

.empty i {
  font-size: 24px !important;
  color: #1a2344; }

.empty .text {
  color: #5d5d5d; }

#content .row p {
  line-height: 22px; }

#content .row ul li {
  line-height: 22px; }

footer nav.navbar {
  padding: 30px 0 !important; }
  @media only screen and (max-width: 980px) {
    footer nav.navbar {
      padding: 20px 0 !important; } }
  footer nav.navbar .nav {
    margin-bottom: 5px; }
    footer nav.navbar .nav li.nav-item .nav-link {
      height: auto;
      font-size: 14px; }
  footer nav.navbar .container {
    display: flex;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .navbar-brand {
        width: 100%;
        justify-content: center; } }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .mid {
        width: 100%; } }
    footer nav.navbar .container .mid .col-lg-12 {
      display: flex;
      display: flex;
      justify-content: center; }
      @media only screen and (max-width: 1466px) {
        footer nav.navbar .container .mid .col-lg-12 {
          flex-direction: column; } }
      footer nav.navbar .container .mid .col-lg-12 .nav {
        justify-content: center; }
        @media only screen and (max-width: 1024px) {
          footer nav.navbar .container .mid .col-lg-12 .nav {
            justify-content: flex-end; } }
        @media only screen and (max-width: 980px) {
          footer nav.navbar .container .mid .col-lg-12 .nav {
            justify-content: center; } }
    @media only screen and (max-width: 1466px) {
      footer nav.navbar .container .mid .footer-text {
        text-align: center; } }
    @media only screen and (max-width: 1024px) {
      footer nav.navbar .container .mid .footer-text {
        text-align: right; } }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .mid .footer-text {
        text-align: center; } }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .end {
        width: 100%; } }
    @media only screen and (max-width: 1024px) {
      footer nav.navbar .container .end > div {
        width: 100%; } }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .end ul {
        justify-content: center !important; } }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .end .footer-text {
        text-align: center; } }
    @media only screen and (max-width: 980px) {
      footer nav.navbar .container .end {
        margin-top: 10px; } }

body.home #hero {
  min-height: 108vh;
  background-color: #0c4173;
  background-image: none !important; }
  @media only screen and (max-width: 825px) {
    body.home #hero {
      padding-top: 110px;
      padding-bottom: 150px; } }
  body.home #hero .home-buttons {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0; }
    @media only screen and (max-width: 825px) {
      body.home #hero .home-buttons {
        flex-direction: column; } }
    body.home #hero .home-buttons a:first-child {
      margin-right: 20px; }
      @media only screen and (max-width: 825px) {
        body.home #hero .home-buttons a:first-child {
          margin-right: 0;
          margin-bottom: 20px; } }
  body.home #hero .btn-group {
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.2); }
    @media only screen and (max-width: 1112px) {
      body.home #hero .btn-group {
        text-align: center; } }
    @media only screen and (max-width: 825px) {
      body.home #hero .btn-group {
        flex-direction: column; } }
  body.home #hero .btn-group > .btn-group:not(:first-child) > .btn,
  body.home #hero .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #515151; }
    @media only screen and (max-width: 825px) {
      body.home #hero .btn-group > .btn-group:not(:first-child) > .btn,
      body.home #hero .btn-group > .btn:not(:first-child) {
        border-left: 0px;
        border-top-right-radius: 0;
        border-bottom-left-radius: .25rem; } }
  body.home #hero .btn-group > .btn-group:not(:last-child) > .btn,
  body.home #hero .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #515151; }
    @media only screen and (max-width: 825px) {
      body.home #hero .btn-group > .btn-group:not(:last-child) > .btn,
      body.home #hero .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0;
        border-right: 0px;
        border-bottom: 1px solid #515151; } }
  body.home #hero .tag-container {
    display: flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    max-width: 1300px; }
    @media only screen and (max-width: 1112px) {
      body.home #hero .tag-container {
        margin-right: 0px;
        margin-left: 0px; } }
    @media only screen and (max-width: 1144px) {
      body.home #hero .tag-container {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center !important; } }
  @media only screen and (max-width: 1366px) {
    body.home #hero .tagline {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; } }
  @media only screen and (max-width: 825px) {
    body.home #hero .tagline {
      text-align: center; } }
  @media only screen and (max-width: 1144px) {
    body.home #hero .tagline h1, body.home #hero .tagline p, body.home #hero .tagline h2 {
      text-align: center; } }
  @media only screen and (max-width: 1144px) {
    body.home #hero .tagline .btn-group {
      margin: auto; } }
  @media only screen and (max-width: 825px) {
    body.home #hero .tagline .btn-group .search-btn {
      border-bottom: 0 !important; } }
  @media only screen and (max-width: 825px) {
    body.home #hero .tagline .btn-group .opp-btn {
      display: none; } }
  @media only screen and (max-width: 1144px) {
    body.home #hero .image {
      display: none; } }
  @media only screen and (max-width: 825px) {
    body.home #hero.main-panel .mobile-hide {
      display: none; } }

body.home .tabs-panel {
  width: 100%;
  padding: 55px 20px;
  max-width: 1050px;
  margin: 0 auto; }
  @media only screen and (max-width: 639px) {
    body.home .tabs-panel {
      padding-top: 25px; } }
  body.home .tabs-panel nav.tabs {
    background-color: transparent;
    min-height: 0;
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 825px) {
      body.home .tabs-panel nav.tabs {
        display: none; } }
    body.home .tabs-panel nav.tabs a {
      display: block !important;
      padding: 10px 15px;
      margin: 0 5px;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      -webkit-transition: border 0.3s;
      -moz-transition: border 0.3s;
      -ms-transition: border 0.3s;
      -o-transition: border 0.3s;
      transition: border 0.3s;
      background: transparent;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      color: #5d5d5d;
      text-align: center; }
      @media only screen and (max-width: 1040px) {
        body.home .tabs-panel nav.tabs a {
          padding: 10px; } }
      body.home .tabs-panel nav.tabs a:hover {
        border-color: #e0e0e0;
        -webkit-box-shadow: 3px 3px 15px rgba(145, 145, 145, 0.2);
        -moz-box-shadow: 3px 3px 15px rgba(145, 145, 145, 0.2);
        box-shadow: 3px 3px 15px rgba(145, 145, 145, 0.2);
        color: #5d5d5d; }
      body.home .tabs-panel nav.tabs a.active {
        background: #0056b3;
        color: white; }
  body.home .tabs-panel .tab-section {
    position: relative; }
    body.home .tabs-panel .tab-section .tab-arrows {
      width: 100%; }
      @media only screen and (max-width: 825px) {
        body.home .tabs-panel .tab-section .tab-arrows {
          display: none; } }
      body.home .tabs-panel .tab-section .tab-arrows .arrow {
        position: absolute;
        left: -35px;
        right: auto;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
        @media only screen and (max-width: 1366px) {
          body.home .tabs-panel .tab-section .tab-arrows .arrow {
            left: 0; } }
        body.home .tabs-panel .tab-section .tab-arrows .arrow.right-arrow {
          right: -35px;
          left: auto; }
          @media only screen and (max-width: 1366px) {
            body.home .tabs-panel .tab-section .tab-arrows .arrow.right-arrow {
              right: 0; } }
        body.home .tabs-panel .tab-section .tab-arrows .arrow .fa {
          font-size: 60px; }
          @media only screen and (max-width: 1112px) {
            body.home .tabs-panel .tab-section .tab-arrows .arrow .fa {
              font-size: 40px; } }
  body.home .tabs-panel button.tab {
    display: none;
    width: 100%;
    padding: 15px 20px;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background: #0a3761;
    -webkit-transition: border 0.3s;
    -moz-transition: border 0.3s;
    -ms-transition: border 0.3s;
    -o-transition: border 0.3s;
    transition: border 0.3s;
    color: white;
    cursor: pointer;
    outline: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-top: 5px; }
    @media only screen and (max-width: 825px) {
      body.home .tabs-panel button.tab {
        display: block; } }
  body.home .tabs-panel .tab-content {
    display: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    max-width: 820px;
    margin: 0 auto;
    min-height: 550px; }
    @media only screen and (max-width: 825px) {
      body.home .tabs-panel .tab-content {
        min-height: 0; } }
    body.home .tabs-panel .tab-content.active {
      display: flex;
      display: flex;
      align-items: center; }
    body.home .tabs-panel .tab-content .tab-content-holder {
      padding: 40px 20px; }
      @media only screen and (max-width: 1366px) {
        body.home .tabs-panel .tab-content .tab-content-holder {
          padding-left: 40px;
          padding-right: 40px; } }
    body.home .tabs-panel .tab-content h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      color: #2f2f2f;
      margin-bottom: 20px; }
      @media only screen and (max-width: 499px) {
        body.home .tabs-panel .tab-content h2 {
          font-size: 20px;
          line-height: 24px; } }
    body.home .tabs-panel .tab-content p {
      color: #2f2f2f;
      font-weight: 400;
      padding-bottom: 20px;
      font-size: 18px;
      letter-spacing: .105px;
      line-height: 28px; }
    body.home .tabs-panel .tab-content .img-col {
      text-align: center; }
      body.home .tabs-panel .tab-content .img-col img {
        max-width: 200px; }

#home .container {
  max-width: 1811px; }
  #home .container.marketing {
    margin-top: -128px; }
    @media only screen and (max-width: 1112px) {
      #home .container.marketing .info > div {
        margin-top: 2em; } }
    #home .container.marketing a.action-item .img-wrap {
      max-width: 148px;
      margin: 0 auto; }
      #home .container.marketing a.action-item .img-wrap > img {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }
    #home .container.marketing a.action-item:hover .img-wrap > img {
      transform: scale(1.1); }
  #home .container .row {
    max-width: 1300px;
    align-items: center; }
    #home .container .row.featurette {
      margin: auto; }
      @media only screen and (max-width: 1112px) {
        #home .container .row.featurette {
          margin-bottom: 6em; } }
      @media only screen and (max-width: 825px) {
        #home .container .row.featurette {
          margin-bottom: 6em; } }
      #home .container .row.featurette .featurette-heading {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: .025em;
        margin-bottom: 25px; }
      #home .container .row.featurette .featurette-body {
        font-size: 18px;
        letter-spacing: .105px;
        line-height: 28px;
        margin-bottom: 50px; }
      #home .container .row.featurette a {
        padding: .8rem 1.5rem;
        font-size: 15px;
        line-height: 17.5px;
        letter-spacing: .025em; }
      @media only screen and (max-width: 825px) {
        #home .container .row.featurette .img-container {
          margin-top: 3em; } }
      #home .container .row.featurette .has-image {
        height: 757px; }
        @media only screen and (max-width: 499px) {
          #home .container .row.featurette .has-image {
            height: 500px; } }
        #home .container .row.featurette .has-image img {
          left: 0; }
    #home .container .row.get-started {
      max-height: 640px;
      height: 61vh;
      padding: 100px 0; }
    #home .container .row.mission {
      padding: 180px 0 50px 0; }
      @media only screen and (max-width: 825px) {
        #home .container .row.mission {
          padding: 50px 0;
          margin-bottom: 0; } }
    #home .container .row.info {
      padding: 10rem 0;
      margin: auto; }
      #home .container .row.info div {
        text-align: center; }
        #home .container .row.info div img {
          margin-bottom: 2em; }
      #home .container .row.info a.btn {
        padding: 1.2rem 2.5rem;
        font-size: 17px;
        line-height: 21px;
        letter-spacing: .025em; }

#home .row.full-featurette {
  padding: 40px 0;
  background-color: #0a3761; }
  #home .row.full-featurette h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: white; }
  #home .row.full-featurette .button-col {
    padding-left: 50px; }

#home .bottom-container {
  position: absolute;
  bottom: 12%;
  width: 100%; }
  #home .bottom-container img {
    max-width: 100%; }
  #home .bottom-container .home-Leftside-Arrow {
    left: 0;
    width: 450px; }
    @media only screen and (max-width: 1366px) {
      #home .bottom-container .home-Leftside-Arrow {
        top: 100px;
        width: 400px; } }
    @media only screen and (max-width: 1205px) {
      #home .bottom-container .home-Leftside-Arrow {
        visibility: hidden; } }
  #home .bottom-container .home-Rightside-Arrow {
    right: 0;
    top: 100px;
    width: 500px; }
    @media only screen and (max-width: 1366px) {
      #home .bottom-container .home-Rightside-Arrow {
        top: 170px;
        width: 400px; } }
    @media only screen and (max-width: 1205px) {
      #home .bottom-container .home-Rightside-Arrow {
        visibility: hidden; } }
    @media only screen and (max-width: 1205px) {
      #home .bottom-container .home-Rightside-Arrow {
        visibility: hidden; } }
  #home .bottom-container .get-started-cube-left {
    top: 338px;
    left: 30%; }
    @media only screen and (max-width: 1112px) {
      #home .bottom-container .get-started-cube-left {
        top: 438px; } }
    @media only screen and (max-width: 825px) {
      #home .bottom-container .get-started-cube-left {
        top: 538px;
        left: 20%; } }

#home .shadowed {
  margin-bottom: 15px;
  background: linear-gradient(to bottom, #f4f4f4, #fefefe 75%);
  border-radius: 20px;
  box-shadow: 0px -20px 10px rgba(0, 0, 0, 0.1); }

#home .shadowed-2 {
  margin-bottom: 155px;
  background-color: #f4f4f4;
  border-radius: 20px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 1112px) {
    #home .shadowed-2 {
      padding-top: 4.5em; } }

#home .firms-cube-top-left {
  top: -100px;
  left: 40px;
  z-index: 1; }

#home .firm-right-cube {
  right: 0;
  bottom: 0; }

#home .business-cube-center {
  left: 0;
  right: 0;
  bottom: -80px; }
  @media only screen and (max-width: 825px) {
    #home .business-cube-center {
      visibility: hidden; } }

#register .container {
  max-width: 100%; }
  @media only screen and (max-width: 991px) {
    #register .container {
      max-width: 720px; } }

@media only screen and (max-width: 1570px) {
  #register .tag-container .tag {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; } }

@media only screen and (max-width: 991px) {
  #register .tag-container .tag {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

#register .register-content h2 {
  padding-bottom: 20px;
  font-size: 24px;
  line-height: 30px; }

#register .register-content p {
  line-height: 22px;
  padding-bottom: 20px; }

#register .form-container {
  display: flex;
  display: flex;
  justify-content: center; }
  #register .form-container form {
    width: 526px;
    background-color: #f0f2f5;
    padding: 2rem;
    position: relative; }
    @media only screen and (max-width: 991px) {
      #register .form-container form {
        width: 100%; } }
    #register .form-container form button {
      position: absolute;
      left: 0;
      bottom: -4%;
      background-color: #2f2f2f;
      width: 100%;
      border-radius: 10px; }

#register form label .membership-info {
  font-size: 12px; }
  #register form label .membership-info a {
    font-size: 12px; }

#register form .business-wrapper {
  margin-bottom: 30px; }
  #register form .business-wrapper .check-wrap {
    padding: 0; }
    #register form .business-wrapper .check-wrap .check-label label {
      padding: 0;
      cursor: pointer; }

.profile-image-container.premium-member {
  border: 3px solid #1a2344; }

#dashboard #hero {
  height: 345px;
  background-color: #555555; }
  @media only screen and (max-width: 500px) {
    #dashboard #hero {
      min-height: 300px;
      height: 300px; } }
  #dashboard #hero .become-partner-section {
    display: flex;
    display: flex;
    justify-content: flex-end;
    text-align: center; }
    #dashboard #hero .become-partner-section a {
      color: white;
      font-size: 24px;
      line-height: 30px; }
      @media only screen and (max-width: 768px) {
        #dashboard #hero .become-partner-section a {
          font-size: 16px;
          line-height: 22px; } }
    @media only screen and (max-width: 768px) {
      #dashboard #hero .become-partner-section.together-strong-section {
        padding: 0 10px; } }
    #dashboard #hero .become-partner-section.together-strong-section span {
      display: block;
      color: white;
      font-size: 12px;
      line-height: 16px;
      padding: 0 20px; }
      #dashboard #hero .become-partner-section.together-strong-section span.text-main {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 8px;
        padding: 0; }

#dashboard #dash-user {
  padding: 0;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  max-width: 335px; }
  @media only screen and (max-width: 1271px) {
    #dashboard #dash-user {
      -webkit-box-flex: 0;
      flex: 0 0 90%;
      max-width: 90%; } }
  #dashboard #dash-user p {
    font-size: 13px;
    line-height: 15px; }
  #dashboard #dash-user li {
    font-size: 13px;
    line-height: 15px; }
  #dashboard #dash-user a {
    line-height: 15px;
    font-size: 13px; }
  #dashboard #dash-user .membership-status {
    padding-bottom: 5px; }
    @media only screen and (max-width: 1271px) {
      #dashboard #dash-user .membership-status {
        order: 1; } }
    @media only screen and (max-width: 499px) {
      #dashboard #dash-user .membership-status {
        order: 0; } }
  #dashboard #dash-user .firm-members {
    padding: 3.5em 3em; }
    #dashboard #dash-user .firm-members .col.l3 {
      padding-bottom: 0;
      padding: 1em 0;
      flex: 0 0 100%;
      max-width: 100%; }
  #dashboard #dash-user .container {
    padding: 0; }
  #dashboard #dash-user .med {
    margin-bottom: 13px; }
  #dashboard #dash-user .bio-wrap figure {
    width: 230px;
    height: 230px; }
    #dashboard #dash-user .bio-wrap figure.rounded-div {
      margin-top: -65px;
      margin-bottom: 40px; }
      @media only screen and (max-width: 1271px) {
        #dashboard #dash-user .bio-wrap figure.rounded-div {
          height: 40px;
          width: 40px;
          margin-top: 0;
          margin-bottom: 0; } }
  #dashboard #dash-user .bio-wrap .partner-badge,
  #dashboard #dash-user .bio-wrap .member-badge {
    padding: 15px;
    width: 100%;
    margin-top: -30px;
    margin-bottom: 10px;
    text-align: center;
    background: #28a745; }
    @media only screen and (max-width: 1271px) {
      #dashboard #dash-user .bio-wrap .partner-badge,
      #dashboard #dash-user .bio-wrap .member-badge {
        margin-top: 0; } }
    #dashboard #dash-user .bio-wrap .partner-badge p,
    #dashboard #dash-user .bio-wrap .member-badge p {
      color: white;
      font-size: 18px;
      font-weight: 500;
      font-style: italic; }
    #dashboard #dash-user .bio-wrap .partner-badge.bg-blue,
    #dashboard #dash-user .bio-wrap .member-badge.bg-blue {
      background: #0a3761; }
  @media only screen and (max-width: 1271px) {
    #dashboard #dash-user .bio-wrap > .user-bio.border-bottom-0 {
      padding: 10px 2em 2em;
      align-items: center; } }
  @media only screen and (max-width: 499px) {
    #dashboard #dash-user .bio-wrap > .user-bio.border-bottom-0 {
      flex-direction: column; } }
  #dashboard #dash-user .user-bio {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 0 3em 3em; }
    #dashboard #dash-user .user-bio.border-bottom-0 {
      padding-bottom: 0; }
      @media only screen and (max-width: 1271px) {
        #dashboard #dash-user .user-bio.border-bottom-0 {
          padding: 10px;
          min-height: 40px;
          display: flex;
          display: flex;
          justify-content: space-between; }
          #dashboard #dash-user .user-bio.border-bottom-0 .name, #dashboard #dash-user .user-bio.border-bottom-0 .type, #dashboard #dash-user .user-bio.border-bottom-0 .location {
            display: none; } }
    #dashboard #dash-user .user-bio .actions {
      max-width: 174px;
      margin: 20px auto 50px; }
      @media only screen and (max-width: 1271px) {
        #dashboard #dash-user .user-bio .actions {
          margin: 0; } }
      @media only screen and (max-width: 499px) {
        #dashboard #dash-user .user-bio .actions {
          margin: 20px 0; } }
    #dashboard #dash-user .user-bio .toggle-action {
      display: none; }
      @media only screen and (max-width: 1271px) {
        #dashboard #dash-user .user-bio .toggle-action {
          display: block; } }
  #dashboard #dash-user #more-info.collapse {
    display: block; }
    @media only screen and (max-width: 1271px) {
      #dashboard #dash-user #more-info.collapse {
        display: none;
        margin-top: 55px; } }
  #dashboard #dash-user .user-info {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 3.5em 3em; }
    #dashboard #dash-user .user-info .specialization {
      margin-top: 20px; }
  #dashboard #dash-user .user-association {
    padding: 3.5em 3em; }
  #dashboard #dash-user input {
    border-left: 0; }
    #dashboard #dash-user input:focus {
      border-left: 2px solid; }

#dashboard #dash-notifications {
  max-width: 830px;
  max-height: 1185px;
  overflow-y: auto; }
  @media only screen and (max-width: 1271px) {
    #dashboard #dash-notifications {
      -webkit-box-flex: 0;
      flex: 0 0 90%;
      max-width: 90%;
      padding: 0; } }
  #dashboard #dash-notifications .top.container {
    padding-top: 55px; }

#dashboard #dash-ads {
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  max-width: 335px; }
  @media only screen and (max-width: 1271px) {
    #dashboard #dash-ads {
      -webkit-box-flex: 0;
      flex: 0 0 90%;
      max-width: 90%;
      padding: 0; } }

#dashboard .profile-pic {
  max-width: 167px;
  max-height: 167px;
  width: 100%;
  height: 167px; }

.new-opportunity {
  border-left: 10px solid #44ae4b !important; }
  @media only screen and (max-width: 825px) {
    .new-opportunity {
      border-left: 0 !important;
      border-top: 10px solid #44ae4b !important; } }

.new-application {
  border-left: 10px solid #ad2b53 !important; }
  @media only screen and (max-width: 825px) {
    .new-application {
      border-left: 0 !important;
      border-top: 10px solid #ad2b53 !important; } }

.new-contact-request {
  border-left: 10px solid #6335b8 !important; }
  @media only screen and (max-width: 825px) {
    .new-contact-request {
      border-left: 0 !important;
      border-top: 10px solid #6335b8 !important; } }

.new-team-request {
  border-left: 10px solid #2db7f1 !important; }
  @media only screen and (max-width: 825px) {
    .new-team-request {
      border-left: 0 !important;
      border-top: 10px solid #2db7f1 !important; } }

.new-message {
  border-left: 10px solid #f1ea2d !important; }
  @media only screen and (max-width: 825px) {
    .new-message {
      border-left: 0 !important;
      border-top: 10px solid #f1ea2d !important; } }

.card-notification {
  max-width: 785px;
  width: 100%;
  box-shadow: 0px 4px 10px -8px rgba(0, 0, 0, 0.35);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 10px solid #082a49;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: auto;
  color: #5d5d5d;
  margin-bottom: 30px; }
  @media only screen and (max-width: 825px) {
    .card-notification {
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0px;
      border-left: 0;
      border-top: 10px solid #082a49; } }
  .card-notification .fas, .card-notification .fab {
    color: #1a2344; }
  .card-notification .top {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 23px; }
    .card-notification .top .top-icon {
      padding: 0;
      font-size: 35px; }
    .card-notification .top .type {
      padding: 0;
      display: flex;
      display: flex;
      align-items: center; }
      .card-notification .top .type p {
        margin-bottom: 0; }
    .card-notification .top .more {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center; }
  .card-notification .middle {
    padding: 50px 23px; }
    .card-notification .middle .middle-icon {
      padding: 0;
      margin-top: -6px; }
    .card-notification .middle .content {
      padding: 0; }
      .card-notification .middle .content p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0; }
        .card-notification .middle .content p.heading {
          margin-bottom: 20px; }
        .card-notification .middle .content p.desc {
          margin-bottom: 5px; }
  .card-notification .bottom {
    padding: 0px 23px; }
    .card-notification .bottom .noteb {
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      padding: 23px 0;
      justify-content: space-between; }
    .card-notification .bottom .view {
      border-right: 2px solid rgba(0, 0, 0, 0.1); }
    .card-notification .bottom .accept {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
      padding-right: 5px; }
      @media only screen and (max-width: 825px) {
        .card-notification .bottom .accept i {
          display: none; } }
    .card-notification .bottom .reject {
      padding-left: 5px; }
      @media only screen and (max-width: 825px) {
        .card-notification .bottom .reject i {
          display: none; } }

.user-info a {
  color: #5d5d5d;
  font-weight: normal; }
  .user-info a i {
    color: #1a2344; }

#profile > .container {
  max-width: 1073px;
  padding: 50px 0; }
  #profile > .container .card {
    padding: 35px 56px;
    border: 2px solid rgba(0, 0, 0, 0.1); }
    #profile > .container .card .card-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: .55rem 0;
      align-items: center; }
      @media only screen and (max-width: 485px) {
        #profile > .container .card .card-header .col {
          flex: none; }
          #profile > .container .card .card-header .col .btn, #profile > .container .card .card-header .col span {
            width: 100%;
            margin-top: 10px; } }
      #profile > .container .card .card-header .title {
        display: flex;
        display: flex;
        align-items: center; }
      #profile > .container .card .card-header .extra {
        display: flex;
        display: flex;
        justify-content: flex-end;
        padding: 0; }
    #profile > .container .card .card-body {
      padding: 0rem 0;
      overflow: hidden; }
      #profile > .container .card .card-body .user-data-item {
        padding: 2rem 0; }
    #profile > .container .card .card-footer {
      padding: 1.25rem 0; }
  #profile > .container .row {
    -webkit-align-items: center;
    align-items: stretch; }
  @media only screen and (min-width: 1112px) {
    #profile > .container .profile-image-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }
  @media only screen and (max-width: 426px) {
    #profile > .container .associated-firm a:first-child {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .associated-firm .assoc-left {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .associated-firm .assoc-mid {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .associated-firm .assoc-right p {
      text-align: center !important; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .col3 .left {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .col3 .middle {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .col3 .right {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .col2 .left {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .col2 .middle {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 767px) {
    #profile > .container .col2 .right {
      margin-bottom: 15px; } }
  #profile > .container .education .left:nth-child(n + 2) {
    margin-left: 10px; }

#profile #hero {
  min-height: 735px;
  background-color: #555555;
  padding-top: 92px; }
  @media only screen and (max-width: 1112px) {
    #profile #hero {
      padding-bottom: 20px; } }
  @media only screen and (min-width: 1366px) {
    #profile #hero {
      min-height: 735px;
      padding-top: 85px; } }
  #profile #hero .btn {
    top: 200px;
    right: 200px; }
  #profile #hero .membership-status {
    padding-top: 5px;
    padding-bottom: 10px; }

#profile form {
  transition: margin-top .5s linear; }

#profile .icons-container.fill.green {
  background-color: #1a2344; }

#profile .rounded-div.profile-pic {
  height: 140px;
  width: 140px; }

#profile .industry ul {
  width: 100%; }

#profile .specialization ul {
  width: 100%; }

#profile .fa-plane {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #dedede;
  color: #1a2344;
  border-radius: 50%; }

#profile #profileImageContainer {
  text-align: center; }

.edit #profile #hero {
  height: 513px !important; }

.edit #profile .row .card .card-body {
  overflow: visible; }

li[id^="add-"] .btn.form-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.firm-members .col.l2 {
  padding-bottom: 30px; }

.firm-members .item-container .media-body a.profile-link {
  width: 100%; }

.firm-members .item-container .media-body span.lightbox-trigger {
  width: 100%; }

.results-container {
  box-shadow: none !important;
  top: calc(100% + 2px) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(0, 0, 0, 0.1); }

.form-field .selected-container.has-selected {
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.1); }

.result:hover,
.result:focus,
.result:active,
.result.selected {
  background-color: rgba(15, 82, 145, 0.2) !important; }

.remove-selected {
  background-color: transparent !important;
  color: #e83b37 !important; }

.change-password .form-control {
  padding: .375rem .75rem; }

.change-password .form-field {
  margin-bottom: 1rem; }

.change-password .form-control.password {
  height: auto !important; }

.change-password .form-control.match {
  height: auto !important; }

[id^="op-card"] {
  width: 313px;
  height: 385.5px;
  padding: 0px !important; }
  [id^="op-card"] .card-header {
    background-color: #f4f4f4;
    padding-left: 8px !important;
    padding-right: 8px !important; }
    @media only screen and (max-width: 485px) {
      [id^="op-card"] .card-header .col {
        -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important;
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
        max-width: 100% !important; } }
    [id^="op-card"] .card-header .rounded-div {
      height: 40px;
      width: 40px;
      margin-right: 10px; }
    [id^="op-card"] .card-header .border-bottom {
      height: 100%; }
    [id^="op-card"] .card-header .controls {
      display: flex;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      [id^="op-card"] .card-header .controls .fa-star {
        color: #555555;
        margin-right: 8px;
        cursor: pointer; }
        [id^="op-card"] .card-header .controls .fa-star.favourited {
          color: #f2d900 !important; }
      [id^="op-card"] .card-header .controls .tip-action {
        height: 14px;
        line-height: 14px; }
  [id^="op-card"] .card-body .body-notification {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }
    [id^="op-card"] .card-body .body-notification p {
      margin-bottom: 0; }
  [id^="op-card"] .card-footer {
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3); }
    [id^="op-card"] .card-footer div:first-child {
      padding: 12px 20px; }
    [id^="op-card"] .card-footer.empty div:first-child {
      padding: 26px 20px; }
    [id^="op-card"] .card-footer .btn-group a, [id^="op-card"] .card-footer .btn-group span {
      width: 50%;
      border-radius: 0;
      height: 60px;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center; }

.card-wrapper {
  padding: 0 8px 8px; }

[id^="app-card"] {
  width: 313px;
  height: 420px;
  padding: 0px !important; }
  [id^="app-card"].isOwner {
    height: 470px; }
  [id^="app-card"] .card-header {
    background-color: #f4f4f4;
    height: 61px;
    align-items: center;
    display: flex; }
    [id^="app-card"] .card-header.featured {
      background-color: #f2d900; }
    @media only screen and (max-width: 485px) {
      [id^="app-card"] .card-header .col {
        -ms-flex-preferred-size: 0 !important;
        flex-basis: 0 !important;
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
        max-width: 100% !important; } }
    [id^="app-card"] .card-header .rounded-div {
      height: 40px;
      width: 40px;
      margin-right: 10px; }
    [id^="app-card"] .card-header .border-bottom {
      height: 100%; }
    [id^="app-card"] .card-header .controls {
      display: flex;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      [id^="app-card"] .card-header .controls .fa-star {
        color: #555555;
        margin-right: 8px;
        cursor: pointer; }
      [id^="app-card"] .card-header .controls .fa-circle {
        color: limegreen; }
  [id^="app-card"] .card-body .row > div {
    padding-top: 8px;
    padding-bottom: 8px; }
  [id^="app-card"] .card-body .details {
    padding-top: 25px;
    padding-bottom: 25px; }
  [id^="app-card"] .card-body .end_date {
    padding-left: 8px; }
  [id^="app-card"] .card-footer {
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3); }
    [id^="app-card"] .card-footer div:first-child {
      padding: 12px 20px; }
    [id^="app-card"] .card-footer.empty div:first-child {
      padding: 26px 20px; }
    [id^="app-card"] .card-footer .btn-group a, [id^="app-card"] .card-footer .btn-group span {
      width: 50%;
      border-radius: 0;
      height: 60px;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center; }

.card-wrapper {
  padding: 0 8px 8px; }

[id^="people-card"] {
  width: 313px;
  height: 455px;
  padding: 0 !important; }
  [id^="people-card"] .card-header {
    background-color: transparent !important; }
  [id^="people-card"] .card-body figure {
    height: 140px;
    width: 140px; }
  [id^="people-card"] .card-body .body-notification {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }
    [id^="people-card"] .card-body .body-notification p {
      margin-bottom: 0; }
  [id^="people-card"] .card-body .membership-status {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 20px;
    font-weight: bold; }
  [id^="people-card"] .card-body .partner-badge,
  [id^="people-card"] .card-body .member-badge {
    padding: 5px;
    width: 100%;
    text-align: center;
    background: #28a745;
    margin-top: 5px;
    margin-bottom: 10px; }
    [id^="people-card"] .card-body .partner-badge p,
    [id^="people-card"] .card-body .member-badge p {
      color: white;
      font-size: 18px;
      font-weight: 500;
      font-style: italic; }
    [id^="people-card"] .card-body .partner-badge.bg-blue,
    [id^="people-card"] .card-body .member-badge.bg-blue {
      background: #0a3761; }
  [id^="people-card"] .card-footer {
    background-color: transparent !important; }

#search-people #hero, #search-repository #hero {
  min-height: 504px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 92px;
  padding-bottom: 20px; }
  @media only screen and (max-width: 825px) {
    #search-people #hero, #search-repository #hero {
      padding-top: 110px; } }
  @media only screen and (max-width: 1280px) {
    #search-people #hero .search, #search-repository #hero .search {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%; } }
  @media only screen and (max-width: 1112px) {
    #search-people #hero .search, #search-repository #hero .search {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%; } }

#search-people > .container, #search-repository > .container {
  max-width: 1075px;
  padding-top: 80px; }

#search-people form.ajax .fields-container, #search-repository form.ajax .fields-container {
  margin-bottom: 0; }

#search-people form.ajax .keyword, #search-repository form.ajax .keyword {
  height: 80px;
  display: flex;
  margin-bottom: 15px; }
  #search-people form.ajax .keyword .form-field .input, #search-repository form.ajax .keyword .form-field .input {
    font-size: 20px;
    line-height: 20px; }
    #search-people form.ajax .keyword .form-field .input::placeholder, #search-repository form.ajax .keyword .form-field .input::placeholder {
      font-size: 20px;
      color: #333; }
  #search-people form.ajax .keyword div, #search-repository form.ajax .keyword div {
    display: flex;
    width: 100%; }

#search-people form.ajax .selects, #search-repository form.ajax .selects {
  display: flex;
  display: flex;
  flex-wrap: wrap; }
  #search-people form.ajax .selects > div, #search-repository form.ajax .selects > div {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 10px; }
    @media only screen and (max-width: 910px) {
      #search-people form.ajax .selects > div, #search-repository form.ajax .selects > div {
        flex-basis: auto;
        flex-grow: auto;
        padding-right: 0; } }
    #search-people form.ajax .selects > div:not(last-child), #search-repository form.ajax .selects > div:not(last-child) {
      margin-bottom: .5rem; }
    #search-people form.ajax .selects > div:last-child, #search-repository form.ajax .selects > div:last-child {
      padding-right: 0; }
      @media only screen and (max-width: 910px) {
        #search-people form.ajax .selects > div:last-child, #search-repository form.ajax .selects > div:last-child {
          margin-top: 25px;
          justify-content: center; } }
  #search-people form.ajax .selects div, #search-repository form.ajax .selects div {
    display: flex;
    display: flex; }
  #search-people form.ajax .selects .fields-container, #search-repository form.ajax .selects .fields-container {
    width: 100%; }
    #search-people form.ajax .selects .fields-container .form-field, #search-repository form.ajax .selects .fields-container .form-field {
      width: 100%; }
      #search-people form.ajax .selects .fields-container .form-field .field-wrapper, #search-repository form.ajax .selects .fields-container .form-field .field-wrapper {
        width: 100%; }
        #search-people form.ajax .selects .fields-container .form-field .field-wrapper .form-select, #search-repository form.ajax .selects .fields-container .form-field .field-wrapper .form-select {
          width: 100%; }

.btn-shape {
  border-radius: 10px;
  align-self: flex-end; }

.btn-container {
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  flex-grow: 0 !important; }
  .btn-container button {
    padding-top: 14px;
    padding-bottom: 14px; }

.left-iceberg {
  height: auto;
  width: 200px;
  left: 0;
  bottom: 0; }

.right-iceberg {
  height: auto;
  width: 200px;
  right: 0;
  bottom: 0; }

.card-ad {
  height: 120px;
  margin-bottom: 58px;
  color: #fefefe; }

.extra {
  justify-content: flex-end;
  display: flex; }

.containter-card {
  border: 0; }
  .containter-card > .card-header {
    border: 0; }
  @media only screen and (max-width: 723px) {
    .containter-card > [class$='-results'] .animation-wrapper {
      justify-content: center; } }
  .containter-card .flex .title, .containter-card .flex .extra {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    align-items: center; }

#new-opp > .container {
  max-width: 1005px;
  padding-top: 80px; }

#new-opp #hero {
  display: flex;
  display: flex;
  align-items: center; }
  #new-opp #hero h1 {
    text-align: center;
    width: 100%;
    color: #fefefe; }

#new-opp .card-info.number {
  padding-left: 15px; }

#new-opp .card-info.cvv {
  padding-left: 77px; }

#new-opp .card-info label {
  margin-right: 10px; }

#hero section p.classification-text {
  text-align: left; }

.op-card p.classification-text {
  color: #0a0a0a; }

.row .importer-progress-container {
  background: #fff;
  overflow: hidden;
  padding: 0;
  margin: 0 0 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  color: #555;
  text-align: left; }
  .row .importer-progress-container header {
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 24px 24px 0; }
  .row .importer-progress-container section {
    padding: 24px 24px 0; }
  .row .importer-progress-container h2 {
    margin: 0 0 24px;
    color: #555;
    font-size: 24px;
    font-weight: normal;
    line-height: 1em; }
  .row .importer-progress-container p {
    font-size: 1em;
    line-height: 1.75em;
    font-size: 16px;
    color: #555;
    margin: 0 0 24px; }
  .row .importer-progress-container .spinner {
    display: none; }
  .row .importer-progress-container progress {
    width: 100%;
    height: 42px;
    margin: 0 auto 24px;
    display: block;
    -webkit-appearance: none;
    border: none;
    display: none;
    background: #f5f5f5;
    border: 2px solid #eee;
    border-radius: 4px;
    padding: 0;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2); }
  .row .importer-progress-container progress::-webkit-progress-bar {
    background: transparent none;
    border: 0;
    border-radius: 4px;
    padding: 0;
    box-shadow: none; }
  .row .importer-progress-container progress::-webkit-progress-value {
    border-radius: 3px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
    background: #2e4d9c;
    background: linear-gradient(to bottom, #2e4d9c, #06245c), #073b93;
    transition: width 1s ease; }
  .row .importer-progress-container progress::-moz-progress-bar {
    border-radius: 3px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
    background: #2e4d9c;
    background: linear-gradient(to bottom, #2e4d9c, #06245c), #073b93;
    transition: width 1s ease; }
  .row .importer-progress-container progress::-ms-fill {
    border-radius: 3px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
    background: #2e4d9c;
    background: linear-gradient(to bottom, #2e4d9c, #06245c), #073b93;
    transition: width 1s ease; }
  .row .importer-progress-container.importing .spinner {
    display: block; }
  .row .importer-progress-container.importing progress {
    display: block; }

.row .action-controls .fa-star {
  color: #555555;
  margin-right: 8px;
  cursor: pointer; }
  .row .action-controls .fa-star.favourited {
    color: #f2d900 !important; }

#opps > .container, #manage-rep > .container, #manage-adverts > .container {
  max-width: 1073px;
  padding-top: 80px; }
  @media only screen and (max-width: 825px) {
    #opps > .container .card.mb-3 .card-body, #manage-rep > .container .card.mb-3 .card-body, #manage-adverts > .container .card.mb-3 .card-body {
      justify-content: center; } }
  #opps > .container .card-header, #manage-rep > .container .card-header, #manage-adverts > .container .card-header {
    padding-left: 40px;
    padding-right: 40px; }
    #opps > .container .card-header .title, #opps > .container .card-header .extra, #manage-rep > .container .card-header .title, #manage-rep > .container .card-header .extra, #manage-adverts > .container .card-header .title, #manage-adverts > .container .card-header .extra {
      padding-left: 8px;
      padding-right: 8px; }
    @media only screen and (max-width: 825px) {
      #opps > .container .card-header .title, #manage-rep > .container .card-header .title, #manage-adverts > .container .card-header .title {
        margin-bottom: 15px; } }
    @media only screen and (max-width: 825px) {
      #opps > .container .card-header .title a, #manage-rep > .container .card-header .title a, #manage-adverts > .container .card-header .title a {
        width: 100%; } }
  #opps > .container .card-body, #manage-rep > .container .card-body, #manage-adverts > .container .card-body {
    padding: 35px 40px; }
    #opps > .container .card-body .notice, #manage-rep > .container .card-body .notice, #manage-adverts > .container .card-body .notice {
      padding-left: 8px; }

#opps #hero, #manage-rep #hero, #manage-adverts #hero {
  display: flex;
  display: flex;
  align-items: center; }
  #opps #hero section, #manage-rep #hero section, #manage-adverts #hero section {
    width: 100%; }
    #opps #hero section h1, #manage-rep #hero section h1, #manage-adverts #hero section h1 {
      text-align: center;
      width: 100%;
      color: #fefefe; }

#opps .container, #manage-rep .container, #manage-adverts .container {
  vertical-align: middle; }
  #opps .container .custom-select, #manage-rep .container .custom-select, #manage-adverts .container .custom-select {
    max-width: 289px; }

#contact {
  padding: 98px 0;
  background: #f0f2f5; }
  #contact form {
    max-width: 526px;
    min-height: 658px;
    background-color: rgba(254, 254, 254, 0.7);
    padding: 2rem;
    position: relative; }
    #contact form input, #contact form select {
      border-radius: 10px; }
    #contact form button {
      position: absolute;
      left: 0;
      bottom: -4%;
      background-color: #2f2f2f;
      width: 100%;
      border-radius: 10px; }

#lfp {
  padding-bottom: 2rem; }
  #lfp #hero section h1, #lfp #hero section h2, #lfp #hero section h3 {
    text-align: left; }
  #lfp #hero section .status {
    color: #58d25d; }
  #lfp #hero section .favorited {
    color: #f2d900; }
  #lfp #hero img {
    margin-bottom: -2%; }
  #lfp > .container {
    max-width: 1073px;
    margin-top: 50px; }
  #lfp .card-body {
    padding: 0 50px; }
  @media only screen and (max-width: 767px) {
    #lfp .col-lg-12 > .card > .card-body {
      margin-left: -40px;
      margin-right: -40px;
      justify-content: center; } }

#manage-opportunities > .container {
  max-width: 1073px;
  margin-top: 80px; }

#manage-opportunities .container {
  vertical-align: middle; }
  #manage-opportunities .container .card-header .border-bottom {
    height: 100%; }
  #manage-opportunities .container .custom-select {
    max-width: 289px; }

#profile.chat .messages-container {
  display: flex;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto; }
  #profile.chat .messages-container .message.Sent .message-data {
    padding: 8px 16px;
    background-color: #dedede;
    display: flex;
    display: flex;
    align-items: flex-end;
    flex-direction: column; }
  #profile.chat .messages-container .message.Received {
    padding: 8px 16px;
    display: flex;
    display: flex;
    align-items: flex-start;
    flex-direction: column; }

.lightbox {
  overflow-y: auto;
  visibility: unset !important;
  opacity: 0;
  transition: opacity .3s linear; }
  .lightbox.show {
    opacity: 1; }
    .lightbox.show .lightbox-content {
      transform: translateY(150px);
      transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out !important; }
  .lightbox .overlay {
    background-color: transparent;
    background: transparent; }
  .lightbox .lightbox-content {
    position: static !important;
    margin: auto;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out !important;
    transform: translate(0, 25%); }
    .lightbox .lightbox-content h2 {
      background-color: #082a49 !important;
      border-bottom: 5px solid #0a3761 !important; }
    .lightbox .lightbox-content button {
      background-color: #0a3761 !important; }

#profile.messages .messages {
  padding-bottom: 2em;
  padding-top: 2em; }
  #profile.messages .messages .message {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    #profile.messages .messages .message:hover {
      background-color: #f0f2f5; }

div.notification.success {
  background-color: #2ecc71; }

main div.notification {
  visibility: hidden;
  width: 4%;
  max-width: 1000px;
  max-height: 93px;
  padding-left: 75px;
  margin: auto;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 80px;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: none; }
  @media only screen and (max-width: 825px) {
    main div.notification {
      max-width: 100%;
      max-height: 110px;
      white-space: pre-wrap; } }
  main div.notification p, main div.notification h4 {
    padding-left: 6px; }

main div.notification.show {
  visibility: visible;
  overflow: hidden;
  animation: fadein 0.5s, expand 0.5s 0.5s,stay 5s 1s, shrink 0.5s 6s, fadeout 0.5s 6.5s; }

main .spinner {
  -webkit-animation: spinneranimation 1s infinite linear;
  animation: spinneranimation 1s infinite linear; }

@keyframes fadein {
  from {
    top: 0;
    opacity: 0; }
  to {
    top: 90px;
    opacity: 1; } }

@keyframes expand {
  from {
    top: 90px;
    width: 4%; }
  to {
    top: 90px;
    width: 100%; } }

@keyframes stay {
  from {
    top: 90px;
    width: 100%; }
  to {
    top: 90px;
    width: 100%; } }

@keyframes shrink {
  from {
    top: 90px;
    width: 100%; }
  to {
    top: 90px;
    width: 4%; } }

@keyframes fadeout {
  from {
    top: 90px;
    opacity: 1; }
  to {
    top: 0px;
    opacity: 0; } }

@keyframes spinneranimation {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

#no #hero {
  height: calc(100vh - 145px); }

.edit div.notification {
  box-shadow: none;
  background-color: #2ecc71; }
  .edit div.notification p, .edit div.notification h4 {
    color: #fefefe; }
  .edit div.notification.success {
    background-color: #2ecc71; }
  .edit div.notification.error {
    background-color: #e74c3c; }
  .edit div.notification .material-icons {
    color: #fefefe; }

div.notification p, div.notification h4 {
  color: #fefefe; }

div.notification.success {
  background-color: #2ecc71; }

div.notification.error {
  background-color: #e74c3c; }

div.notification .material-icons {
  color: #fefefe; }

#mCSB_1_dragger_vertical .mCSB_dragger_bar {
  background-color: #1a2344; }

.picker__nav--prev:before, .picker__nav--next:before {
  border-right-color: #0f5291; }

.picker__nav--next:before {
  border-left-color: #0f5291; }

.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected {
  background: #0f5291; }

.picker__day--today:before {
  border-top-color: #1e86e8; }

.picker__holder {
  border: 2px solid #dedede;
  width: 289px;
  position: absolute; }

#pricing > .container {
  max-width: 1260px; }

#pricing .card-wrapper {
  max-width: 315px; }

@media only screen and (max-width: 991px) {
  #pricing .filler {
    justify-content: center; } }

.pricing-card {
  border: 10px solid #a9d1f6; }
  .pricing-card .card-header {
    background-color: #28a745;
    text-align: right; }
    .pricing-card .card-header p, .pricing-card .card-header h3 {
      color: #fefefe; }
  .pricing-card .card-body {
    padding-top: 0;
    padding-bottom: 70px;
    border-bottom: 10px solid #a9d1f6; }
    .pricing-card .card-body p.main {
      margin-bottom: 10px; }
    .pricing-card .card-body ul.main > li {
      font-weight: 500;
      font-size: 17px;
      padding-left: 30px;
      position: relative; }
      .pricing-card .card-body ul.main > li::before {
        content: ' ';
        left: 0;
        top: 5px;
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: #e83b37;
        margin-right: 10px; }
    .pricing-card .card-body ul.main ul.inline-list li {
      display: inline; }
    .pricing-card .card-body .card-price {
      border-top: 10px solid #a9d1f6;
      border-bottom: 10px solid #a9d1f6;
      margin: 0 -1.25rem;
      padding: 50px 1.25rem;
      position: relative;
      background-color: #a9d1f6;
      margin-bottom: 20px; }
      .pricing-card .card-body .card-price h2, .pricing-card .card-body .card-price span {
        color: #1a2344; }
      .pricing-card .card-body .card-price.card-ad-price {
        padding: 10px 1.25rem; }
        .pricing-card .card-body .card-price.card-ad-price h2.type {
          font-size: 30px;
          line-height: 36px; }
  .pricing-card .card-footer {
    background-color: #28a745; }
    .pricing-card .card-footer ul, .pricing-card .card-footer li {
      color: #fefefe; }
    .pricing-card .card-footer li {
      padding-left: 30px;
      position: relative; }
      .pricing-card .card-footer li::before {
        content: ' ';
        left: 0;
        top: 5px;
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: white;
        margin-right: 10px; }
    .pricing-card .card-footer p, .pricing-card .card-footer h3, .pricing-card .card-footer span {
      color: #fefefe; }
  .pricing-card.red .card-body .card-price {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pricing-card.red .card-header {
    background-color: #e83b37; }
  .pricing-card.red .card-footer {
    background-color: #e83b37; }
  .pricing-card.blue .card-header {
    background-color: #1a2344; }
  .pricing-card.blue .card-footer {
    background-color: #1a2344; }
  .pricing-card.brown .card-header {
    background-color: saddlebrown; }
  .pricing-card.brown .card-footer {
    background-color: saddlebrown; }
  .pricing-card.orange .card-header {
    background-color: #ef5822; }
  .pricing-card.orange .card-footer {
    background-color: #ef5822; }
  .pricing-card.purple .card-header {
    background-color: #6335b8; }
  .pricing-card.purple .card-footer {
    background-color: #6335b8; }

#profile.friends #friends-list .media figure {
  position: relative;
  top: inherit;
  left: inherit;
  transform: unset; }

#profile.purchases #hero {
  min-height: 428px; }

#profile.purchases .purchases .no-results {
  padding-top: 40px; }

.featurette ul.featurette-list {
  padding: 20px 0;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 20px; }
  .featurette ul.featurette-list li {
    margin: 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #555555;
    background: #f4f4f4;
    transition: background .3s linear;
    display: block;
    box-shadow: 0 7px 20px 10px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 13px 39px;
    width: 180px;
    max-width: 180px; }
    .featurette ul.featurette-list li:hover {
      background: #0f5291;
      color: #fefefe;
      cursor: pointer; }

.featurette.featurette-spacing {
  padding: 7em 0; }

sup.text-sub {
  color: #ff3c0f;
  font-size: 12px;
  font-weight: bold; }

#publish-rep form .price-info .box-title, #edit-rep form .price-info .box-title {
  background: #082a49;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  padding: 20px 30px; }

#publish-rep form .price-info .pricing, #edit-rep form .price-info .pricing {
  padding: 20px 30px;
  border: 1px solid #cccccc; }
  #publish-rep form .price-info .pricing > div, #edit-rep form .price-info .pricing > div {
    border-top: 1px solid #8e8e8e;
    padding: 20px 10px; }
    #publish-rep form .price-info .pricing > div:first-child, #edit-rep form .price-info .pricing > div:first-child {
      border-top: none; }
    #publish-rep form .price-info .pricing > div p, #edit-rep form .price-info .pricing > div p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      color: #2f2f2f;
      padding: 0;
      margin: 0; }
      #publish-rep form .price-info .pricing > div p:first-child, #edit-rep form .price-info .pricing > div p:first-child {
        font-weight: 400; }

#publish-rep form .payment-info .card-number-container, #edit-rep form .payment-info .card-number-container {
  margin-bottom: 0; }
  #publish-rep form .payment-info .card-number-container.double, #edit-rep form .payment-info .card-number-container.double {
    display: block; }
    #publish-rep form .payment-info .card-number-container.double .form-field, #edit-rep form .payment-info .card-number-container.double .form-field {
      width: 100%;
      margin-bottom: 19px; }

#publish-rep form .terms-container .terms-box, #edit-rep form .terms-container .terms-box {
  height: 150px;
  overflow: auto;
  margin: 10px 0;
  border: 1px solid #cccccc;
  padding: 10px; }

#publish-rep form .terms-container p, #edit-rep form .terms-container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #5d5d5d; }

#publish-rep form .terms-container .highlight-label, #edit-rep form .terms-container .highlight-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #5d5d5d;
  padding: 0;
  margin-bottom: 10px; }

#publish-rep form .terms-container label, #edit-rep form .terms-container label {
  font-size: 14px;
  line-height: 32px;
  padding: 0 5px 5px; }

#publish-rep form .terms-container ul li, #edit-rep form .terms-container ul li {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #5d5d5d;
  position: relative;
  padding-left: 15px; }
  #publish-rep form .terms-container ul li i, #edit-rep form .terms-container ul li i {
    position: absolute;
    left: -15px;
    top: 4px;
    color: #ff3c0f; }

#publish-rep form .content-preview-box, #edit-rep form .content-preview-box {
  width: 380px;
  width: 100%;
  height: 380px;
  overflow: hidden;
  padding-top: 5px;
  text-align: center; }
  #publish-rep form .content-preview-box img, #edit-rep form .content-preview-box img {
    max-width: 100%;
    height: auto;
    border: 1px solid #eceff1;
    max-height: 380px; }

.form .image-upload-container .imageUploadWrap {
  text-align: center;
  position: relative;
  width: 430px;
  height: 400px; }
  @media only screen and (max-width: 825px) {
    .form .image-upload-container .imageUploadWrap {
      height: 300px; } }
  .form .image-upload-container .imageUploadWrap figure {
    width: 100%;
    height: 100%;
    position: relative; }
    .form .image-upload-container .imageUploadWrap figure::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: 10; }
    .form .image-upload-container .imageUploadWrap figure img {
      max-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5; }
    .form .image-upload-container .imageUploadWrap figure div.image {
      width: 100%;
      height: 100%;
      max-width: 100%;
      position: absolute;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5; }
      .form .image-upload-container .imageUploadWrap figure div.image.has-image ~ .add {
        opacity: 0; }
      .form .image-upload-container .imageUploadWrap figure div.image.has-image ~ .remove {
        opacity: 1; }
    .form .image-upload-container .imageUploadWrap figure .fa {
      font-size: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 15; }
      .form .image-upload-container .imageUploadWrap figure .fa.add {
        opacity: 1; }
      .form .image-upload-container .imageUploadWrap figure .fa.remove {
        opacity: 0; }

#ratings .stars a {
  position: relative; }
  #ratings .stars a i {
    visibility: hidden;
    opacity: 0;
    width: 0;
    color: #082a49; }
    #ratings .stars a i.on {
      visibility: hidden;
      opacity: 0;
      width: 0; }
    #ratings .stars a i.off {
      visibility: visible;
      opacity: 1;
      width: auto; }

#ratings .stars.selected a:not(.active) i.on {
  visibility: visible;
  opacity: 1;
  width: auto; }

#ratings .stars.selected a:not(.active) i.off {
  visibility: hidden;
  opacity: 0;
  width: 0; }

#ratings .stars.selected a.active ~ a i.on {
  visibility: hidden;
  opacity: 0;
  width: 0; }

#ratings .stars.selected a.active ~ a i.off {
  visibility: visible;
  opacity: 1;
  width: auto; }

#ratings .stars.selected a.active i.on {
  visibility: visible;
  opacity: 1;
  width: auto; }

#ratings .stars.selected a.active i.off {
  visibility: hidden;
  opacity: 0;
  width: 0; }

#ratings select#star-rate {
  display: none; }

.content-preview-box img {
  max-width: 100%; }

@media only screen and (max-width: 1099px) {
  section#manage-rep .card:not(.op-card) > .card-body, section#manage-adverts .card:not(.op-card) > .card-body {
    display: flex;
    justify-content: center; } }

.button.more {
  background-color: #1a2344 !important; }

#rep-orders .table {
  max-width: 1072px; }
  #rep-orders .table .flex {
    padding: 0 15px;
    border-radius: .5em; }
    #rep-orders .table .flex:not(:first-child) {
      background-color: #fefefe; }
    #rep-orders .table .flex:not(:last-child):not(:first-child) {
      margin-bottom: 15px; }
    #rep-orders .table .flex .data {
      display: flex;
      flex: 1;
      max-width: 100%;
      align-items: center;
      height: 75px; }
      #rep-orders .table .flex .data:nth-child(3) {
        width: 125px;
        flex: unset; }
      #rep-orders .table .flex .data:nth-child(2) {
        width: 330px;
        flex: unset; }
    #rep-orders .table .flex .data:last-child {
      width: 266px;
      text-align: end; }
  #rep-orders .table .btn {
    width: 205px; }
  #rep-orders .table .labels .data {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #8e8f90; }
  #rep-orders .table .data {
    font-size: 14px;
    color: #5d5d5d; }

#rep-orders .total-revenue {
  font-size: 16px;
  color: #1a2344; }

@media only screen and (max-width: 1112px) {
  #view-rep .flex {
    justify-content: center;
    text-align: center; } }

@media only screen and (max-width: 1112px) {
  #view-rep .content {
    margin: auto; } }

#view-rep .media > p {
  width: 115px; }

#view-rep .content {
  max-width: 422px; }

#view-rep .img-container {
  padding-right: 50px; }
  @media only screen and (max-width: 1112px) {
    #view-rep .img-container {
      margin-bottom: 30px; } }
  #view-rep .img-container img {
    max-width: 100%;
    height: auto;
    max-height: 415px; }

#view-rep .price .lower {
  align-self: flex-end; }

#view-rep .btn-div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px; }
  #view-rep .btn-div .btn {
    flex: 1;
    max-width: 200px; }
    #view-rep .btn-div .btn:not(:last-child) {
      margin-right: 15px; }

.repo-card .card-body.flex, .ad-card .card-body.flex {
  flex-direction: column;
  justify-content: space-between; }

.howitworks nav {
  background: none;
  min-height: unset;
  padding: unset !important;
  width: 100%; }
  @media only screen and (max-width: 992px) {
    .howitworks nav .nav-tabs {
      display: block; } }
  .howitworks nav .nav-link {
    background-color: #2f2f2f;
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
    margin: 0 7.5px;
    width: 150px;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .howitworks nav .nav-link#cws-tab {
      margin-left: 0; }
    @media only screen and (max-width: 992px) {
      .howitworks nav .nav-link {
        width: 100%;
        margin: 0 !important;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0; } }
    .howitworks nav .nav-link.active {
      background-color: #0f5291;
      align-items: center;
      color: #fefefe;
      border-color: #dee2e6 #dee2e6 #fff;
      display: flex !important; }

.howitworks #nav-tabContent.tab-content .bold.border-bottom.border-1 {
  border-color: #0f5291 !important; }

#publish-rep .field-wrapper {
  max-width: 100%; }
  #publish-rep .field-wrapper .imageUploadWrap {
    max-width: 100%; }

#publish-rep .accepted-cards img {
  max-width: 100%; }

.bg-btn-success {
  background-color: #44ae4b;
  border-color: #44ae4b !important; }
  .bg-btn-success:hover {
    background-color: #3d9c43;
    border-color: #3d9c43 !important; }

.summary-box {
  border: 1px solid #cccccc; }

.summary-box-header {
  color: #fff;
  background-color: #082a49;
  padding: 20px 35px;
  font-size: 16px; }

.summary-box-content {
  padding: 18px 25px; }

.summary-table {
  width: 100%;
  color: #2f2f2f; }
  .summary-table td, .summary-table th {
    border-bottom: 1px solid #8e8e8e;
    padding: 24px 10px; }
    .summary-table td:last-child, .summary-table th:last-child {
      text-align: right; }
  .summary-table th {
    border-bottom: 3px solid #696969;
    padding: 10px 10px 20px 10px; }
  .summary-table tr:last-child td {
    border-bottom: none; }
  .summary-table tr.total td {
    padding-top: 40px; }
    .summary-table tr.total td:last-child {
      font-size: 18px;
      color: #082a49; }

.row-block {
  display: block;
  margin: 0 -15px; }

.btn-darker {
  background-color: #2f2f2f; }
  .btn-darker:hover {
    background-color: #222222; }

.btn-md {
  font-size: 14px;
  font-weight: 500;
  padding: 18px 52px;
  border-radius: 6px; }

.btn-advertise {
  font-size: 15px !important;
  padding: 12px 55px !important;
  font-weight: 500;
  line-height: 1.3 !important; }

.landing-adv * {
  box-sizing: border-box; }

.landing-adv #hero {
  margin-top: -65px;
  background: #156dbe;
  /* Old browsers */
  background: -moz-linear-gradient(top, #156dbe 0%, #11538f 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #156dbe 0%, #11538f 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #156dbe 0%, #11538f 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#156dbe', endColorstr='#11538f',GradientType=0 );
  /* IE6-9 */
  height: 100vh;
  padding: 0 0 140px 0;
  min-height: 750px;
  max-height: 1000px; }
  .landing-adv #hero .image {
    padding-left: 50px; }

.landing-adv .hero-content {
  float: right;
  padding-right: 50px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%); }
  .landing-adv .hero-content h1 {
    letter-spacing: 1px; }

.landing-adv .action-item .item-title {
  letter-spacing: 1px;
  max-width: 270px;
  margin: 0 auto; }

.landing-adv .know-your-target .img-container {
  padding-top: 150px; }

.landing-adv .know-your-target-cube {
  position: absolute;
  bottom: 40px;
  left: 8%; }

.landing-adv .personalised .item-text {
  padding-left: 80px; }

.landing-adv .cost-effective {
  padding: 60px 0; }
  .landing-adv .cost-effective .img-container {
    padding-left: 80px; }

.preview-ad {
  max-width: 100%;
  overflow: auto;
  padding: 5px 0;
  margin-bottom: 10px; }
  .preview-ad img {
    width: auto;
    max-width: none; }

.preview-leaderboard {
  width: 728px;
  height: 90px;
  background-color: #ccc; }
  .preview-leaderboard img {
    max-width: 728px;
    max-height: 90px; }

.preview-fullbanner {
  width: 468px;
  height: 60px;
  background-color: #ccc; }
  .preview-fullbanner img {
    max-width: 468px;
    max-height: 60px; }

.preview-square {
  width: 300px;
  height: 250px;
  background-color: #ccc; }
  .preview-square img {
    max-width: 300px;
    max-height: 250px; }

.preview-halfpage {
  width: 300px;
  height: 600px;
  background-color: #ccc; }
  .preview-halfpage img {
    max-width: 300px;
    max-height: 600px; }

@media screen and (max-width: 825px) {
  .container .featurette.cost-effective .col-lg-8 {
    display: flex;
    flex-wrap: wrap; }
  .container .featurette.cost-effective a.btn {
    margin: 0 auto; } }

@media screen and (max-width: 991px) {
  .landing-adv #hero {
    height: auto;
    padding: 75px 0 220px 0;
    max-height: none; }
    .landing-adv #hero .image {
      padding-left: 15px;
      text-align: center; }
  .landing-adv .hero-content {
    text-align: center;
    float: none;
    transform: translate(0, 0);
    position: static;
    padding-right: 0;
    padding-bottom: 50px; }
  .landing-adv .know-your-target-cube {
    display: none; } }

@media screen and (max-width: 767px) {
  .landing-adv .know-your-target .img-container {
    padding-top: 0;
    text-align: center;
    justify-content: center !important;
    margin-top: 20px !important; }
    .landing-adv .know-your-target .img-container img {
      max-width: 75%; }
  .landing-adv .personalised .item-text {
    padding-left: 30px;
    padding-top: 50px; }
  .landing-adv .cost-effective {
    padding: 0; }
    .landing-adv .cost-effective .img-container {
      padding-left: 80px; } }

#dashboard .banner.banner-square {
  max-height: 250px; }

#dashboard .banner.banner-fullbanner {
  max-height: 60px; }

#dashboard .banner.banner-leaderboard {
  max-height: 90px; }

#dashboard .banner.banner-halfpage {
  max-height: 600px; }

#dashboard .banner a img {
  max-width: 100%; }

.banner {
  display: block;
  text-align: center;
  overflow: hidden;
  margin: 20px 0; }
  .banner.banner-leaderboard a img {
    max-width: 100%; }
  .banner > a, .banner > img, .banner > div {
    display: inline-block; }

.banner-square {
  display: none; }

.banner-fullbanner {
  display: none; }

@media screen and (max-width: 1520px) and (min-width: 1272px) {
  .banner-leaderboard {
    display: none; }
  .banner-fullbanner {
    display: block; } }

@media screen and (max-width: 815px) {
  .banner-leaderboard {
    display: none; }
  .banner-fullbanner {
    display: block; } }

@media screen and (max-width: 560px) {
  .banner-fullbanner {
    display: none; }
  .banner-square {
    display: block; } }

body.membership-page main {
  background-color: #f4f4f5; }
  @media only screen and (max-width: 825px) {
    body.membership-page main {
      padding-top: 110px; } }

section#memberships .title-section {
  padding: 20px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    section#memberships .title-section {
      padding: 0; } }
  section#memberships .title-section h3 {
    color: #2f2f2f; }
    section#memberships .title-section h3.page-title {
      padding-bottom: 20px;
      font-weight: 500; }
      @media only screen and (max-width: 640px) {
        section#memberships .title-section h3.page-title {
          font-size: 24px;
          padding-bottom: 10px; } }
  section#memberships .title-section p {
    color: #2f2f2f;
    max-width: 60%;
    margin: 0 auto;
    line-height: 22px; }
    @media only screen and (max-width: 767px) {
      section#memberships .title-section p {
        max-width: 100%; } }
    @media only screen and (max-width: 640px) {
      section#memberships .title-section p {
        max-width: 70%; } }

section#memberships ul.memberships-details {
  margin: 0;
  list-style: none;
  padding: 40px 0;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 640px) {
    section#memberships ul.memberships-details {
      flex-wrap: wrap !important;
      padding: 20px 0; } }
  section#memberships ul.memberships-details .membership {
    max-width: 396px;
    padding: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: 50px;
    position: relative;
    -webkit-box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0);
    box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    min-height: 600px; }
    section#memberships ul.memberships-details .membership:first-child {
      margin-left: 0; }
    @media only screen and (max-width: 767px) {
      section#memberships ul.memberships-details .membership {
        margin-left: 20px;
        -webkit-box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2); }
        section#memberships ul.memberships-details .membership:first-child {
          margin-left: 0; } }
    @media only screen and (max-width: 640px) {
      section#memberships ul.memberships-details .membership {
        margin-left: 0;
        margin-top: 20px;
        min-height: 0; } }
    section#memberships ul.memberships-details .membership .overlay-anchor {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10; }
    section#memberships ul.memberships-details .membership .picture img {
      max-width: 100%;
      height: auto;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px; }
    section#memberships ul.memberships-details .membership .membership-data {
      padding: 30px 0;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        section#memberships ul.memberships-details .membership .membership-data {
          padding: 10px 0; } }
      section#memberships ul.memberships-details .membership .membership-data .title {
        font-size: 30px;
        color: #2f2f2f;
        line-height: 36px;
        padding: 5px 0;
        font-weight: 500; }
      section#memberships ul.memberships-details .membership .membership-data .price {
        position: relative;
        padding: 10px 0; }
        section#memberships ul.memberships-details .membership .membership-data .price span {
          color: #0f5291;
          font-size: 17px;
          font-weight: 700; }
        section#memberships ul.memberships-details .membership .membership-data .price.free span {
          background: white;
          padding: 0 20px;
          z-index: 10;
          position: relative; }
        section#memberships ul.memberships-details .membership .membership-data .price.free::before {
          content: "";
          z-index: 1;
          width: 100%;
          height: 1px;
          background: #0f5291;
          position: absolute;
          top: 50%;
          left: 0;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); }
      section#memberships ul.memberships-details .membership .membership-data .description {
        max-width: 70%;
        margin: 0 auto;
        padding-top: 10px; }
        section#memberships ul.memberships-details .membership .membership-data .description p {
          font-size: 12px;
          color: #7a8b9b;
          line-height: 22px;
          padding-bottom: 25px; }
        section#memberships ul.memberships-details .membership .membership-data .description a.btn {
          width: 150px;
          font-size: 15px;
          font-weight: 500;
          background: white;
          border: 1px solid #0f5291; }
          section#memberships ul.memberships-details .membership .membership-data .description a.btn:hover {
            background: #0f5291;
            color: white; }
        section#memberships ul.memberships-details .membership .membership-data .description .annual-print {
          font-size: 12px;
          line-height: 18px;
          padding-top: 10px;
          padding-bottom: 5px; }
        section#memberships ul.memberships-details .membership .membership-data .description .fine-print {
          padding-bottom: 0;
          padding-top: 5px;
          font-size: 12px;
          line-height: 14px;
          height: 19px; }
    section#memberships ul.memberships-details .membership:hover {
      -webkit-box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2); }
      section#memberships ul.memberships-details .membership:hover .membership-data .description a.btn {
        background: #0f5291;
        color: white; }

section#memberships .memberships-features {
  margin: 50px 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(179, 179, 179, 0.75);
  -moz-box-shadow: 0px 0px 15px 1px rgba(179, 179, 179, 0.75);
  box-shadow: 0px 0px 15px 1px rgba(179, 179, 179, 0.75);
  max-width: 100%;
  overflow: hidden; }
  section#memberships .memberships-features .feature-cols {
    display: flex;
    display: flex;
    width: 100%; }
    section#memberships .memberships-features .feature-cols.heading {
      background-color: #ecf0f4;
      padding: 30px 0;
      align-items: center; }
      @media only screen and (max-width: 499px) {
        section#memberships .memberships-features .feature-cols.heading {
          padding: 10px 0; } }
      section#memberships .memberships-features .feature-cols.heading p {
        font-size: 16px;
        font-weight: 500; }
        @media only screen and (max-width: 499px) {
          section#memberships .memberships-features .feature-cols.heading p {
            font-size: 12px; } }
      section#memberships .memberships-features .feature-cols.heading .feature-col {
        padding-left: 30px; }
        @media only screen and (max-width: 499px) {
          section#memberships .memberships-features .feature-cols.heading .feature-col {
            padding-left: 10px; } }
    section#memberships .memberships-features .feature-cols .feature-col {
      flex: 1; }
    section#memberships .memberships-features .feature-cols .type-col {
      width: 250px;
      text-align: center;
      border-left: 1px solid #ecf0f4;
      display: flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      @media only screen and (max-width: 992px) {
        section#memberships .memberships-features .feature-cols .type-col {
          width: 185px; } }
      @media only screen and (max-width: 767px) {
        section#memberships .memberships-features .feature-cols .type-col {
          width: 140px; } }
      @media only screen and (max-width: 640px) {
        section#memberships .memberships-features .feature-cols .type-col {
          width: 110px; } }
      @media only screen and (max-width: 499px) {
        section#memberships .memberships-features .feature-cols .type-col {
          width: 78px; } }
      section#memberships .memberships-features .feature-cols .type-col p {
        max-width: 80%;
        font-size: 12px;
        padding-top: 5px; }
        @media only screen and (max-width: 639px) {
          section#memberships .memberships-features .feature-cols .type-col p {
            max-width: 100%; } }
      section#memberships .memberships-features .feature-cols .type-col i.green {
        color: #26ac2a; }
      section#memberships .memberships-features .feature-cols .type-col i.red {
        color: #e83b37; }
      section#memberships .memberships-features .feature-cols .type-col i.blue {
        color: #0f5291; }
      section#memberships .memberships-features .feature-cols .type-col.basic {
        border-left: none; }
  section#memberships .memberships-features .features {
    background-color: #ffffff; }
    section#memberships .memberships-features .features .feature-cols .feature-col {
      padding: 15px 30px; }
      section#memberships .memberships-features .features .feature-cols .feature-col:first-child {
        padding-top: 25px; }
      @media only screen and (max-width: 499px) {
        section#memberships .memberships-features .features .feature-cols .feature-col {
          padding: 10px; }
          section#memberships .memberships-features .features .feature-cols .feature-col:first-child {
            padding-top: 10px; } }
      section#memberships .memberships-features .features .feature-cols .feature-col .feature-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #082a49; }
        @media only screen and (max-width: 499px) {
          section#memberships .memberships-features .features .feature-cols .feature-col .feature-title {
            font-size: 12px;
            line-height: 16px; } }
      section#memberships .memberships-features .features .feature-cols .feature-col p {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #7a8b9b; }
        @media only screen and (max-width: 499px) {
          section#memberships .memberships-features .features .feature-cols .feature-col p {
            font-size: 10px;
            line-height: 14px; } }

section#membership-subscription .row h3 {
  padding-bottom: 10px; }

section#membership-subscription .row p {
  line-height: 22px;
  padding-bottom: 10px; }

section#upgrade-membership .payment-summary-text,
section#upgrade-membership p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 10px 0; }

section#upgrade-membership .row .summary-table tr th {
  padding: 20px 10px 20px; }

section#upgrade-membership .row .summary-table tr.total td {
  padding-top: 25px; }

body.partners .container img.hq-featurette {
  max-height: 600px; }

body.partners .container .row.featurette ul.featurette-list li {
  max-width: 270px;
  width: auto; }

body.partners .container .row.featurette.partners-contact {
  padding-top: 100px; }
  body.partners .container .row.featurette.partners-contact h3.featurette-heading {
    margin-bottom: 10px !important; }
  body.partners .container .row.featurette.partners-contact .form-container {
    padding-top: 40px; }
    body.partners .container .row.featurette.partners-contact .form-container form .radio-fields-wrapper {
      display: flex;
      display: flex;
      align-items: center; }
      body.partners .container .row.featurette.partners-contact .form-container form .radio-fields-wrapper .radio-wrap .radio-label {
        padding: 0 15px; }
      body.partners .container .row.featurette.partners-contact .form-container form .radio-fields-wrapper .radio-wrap .input-radio + label {
        padding-left: 10px; }
    body.partners .container .row.featurette.partners-contact .form-container form .buttons-container {
      padding-top: 30px; }
      body.partners .container .row.featurette.partners-contact .form-container form .buttons-container .button {
        padding: 25px 75px; }

body .manage-offers .card .card-footer .btn-single {
  width: 100%; }
  body .manage-offers .card .card-footer .btn-single a {
    width: 100%;
    border-radius: 0;
    height: 60px;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center; }

body.hq #home #hero.hero-content-wrap h1 {
  font-size: 36px;
  line-height: 40px;
  padding-bottom: 20px;
  color: #ffffff; }

body.hq #home #hero.hero-content-wrap h3 {
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 20px;
  color: #ffffff; }

body.hq #home #hero.hero-content-wrap p {
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 20px;
  color: #ffffff; }

body.hq #home #hero.hero-content-wrap .home-buttons {
  justify-content: flex-start; }

@media only screen and (max-width: 1112px) {
  body.hq #home #hero.hero-content-wrap .tag-container {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; } }

@media only screen and (max-width: 1112px) {
  body.hq #home #hero.hero-content-wrap .tagline h1 {
    text-align: left; } }

@media only screen and (max-width: 825px) {
  body.hq #home #hero.hero-content-wrap .tagline h1 {
    text-align: center; } }

@media only screen and (max-width: 1112px) {
  body.hq #home #hero.hero-content-wrap .tagline p {
    text-align: left; } }

@media only screen and (max-width: 825px) {
  body.hq #home #hero.hero-content-wrap .tagline p {
    text-align: center; } }

body.hq #home .container .row.featurette .featurette-list li {
  max-width: none;
  width: auto;
  padding: 13px 36px; }

@media only screen and (max-width: 825px) {
  body.hq #home .container .row .firm-right-cube {
    display: none; } }

body.hq #home .container .row .list-action {
  padding-top: 25px;
  padding-bottom: 100px; }

body.hq #home .container .shadowed-2 {
  margin-top: 50px;
  margin-bottom: 20px; }

body #publish-offer .sp-replacer {
  width: 100%;
  height: 42.33px;
  border: 2px solid rgba(0, 0, 0, 0.1); }
  body #publish-offer .sp-replacer .sp-preview {
    width: 94%;
    height: 100%; }
  body #publish-offer .sp-replacer .sp-dd {
    padding: 8px 0;
    height: 100%;
    color: #0a3761; }

body #publish-offer .use-business-profile .field-wrap .form-field-textarea {
  width: 100%; }
  body #publish-offer .use-business-profile .field-wrap .form-field-textarea textarea {
    min-height: 100px; }

body #publish-offer .use-business-profile .field-wrap .description-help .checkbox-container {
  width: 100%; }
  body #publish-offer .use-business-profile .field-wrap .description-help .checkbox-container .check-wrap {
    padding: 0; }
    body #publish-offer .use-business-profile .field-wrap .description-help .checkbox-container .check-wrap .check-label label {
      padding: 5px 0;
      cursor: pointer; }

body #publish-offer .learn-more-section > .form-field {
  width: 100%; }
  body #publish-offer .learn-more-section > .form-field.form-field-textarea {
    margin-top: 22px; }
    body #publish-offer .learn-more-section > .form-field.form-field-textarea textarea {
      min-height: 100px; }

body #publish-offer .form-field-textarea textarea#how_it_works {
  min-height: 200px; }

body #publish-offer .preview-section {
  padding-top: 20px; }
  body #publish-offer .preview-section > label {
    font-size: 20px;
    line-height: 24px; }
  body #publish-offer .preview-section > p {
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px; }
  body #publish-offer .preview-section .preview .offer-card {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    padding: 12px 50px 0px 40px;
    border: 1px solid #dedede;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 2px 4px 0px #e6e6e6;
    -moz-box-shadow: 0px 2px 4px 0px #e6e6e6;
    box-shadow: 0px 2px 4px 0px #e6e6e6;
    background: #ffffff; }
    @media only screen and (max-width: 1200px) {
      body #publish-offer .preview-section .preview .offer-card {
        padding: 12px 30px 0 20px; } }
    body #publish-offer .preview-section .preview .offer-card .card-content .top {
      display: flex;
      display: flex;
      align-items: center;
      padding: 30px 0; }
      @media only screen and (max-width: 1200px) {
        body #publish-offer .preview-section .preview .offer-card .card-content .top {
          padding: 20px 0; } }
      @media only screen and (max-width: 768px) {
        body #publish-offer .preview-section .preview .offer-card .card-content .top {
          flex-direction: column;
          text-align: center;
          padding: 0; } }
      body #publish-offer .preview-section .preview .offer-card .card-content .top .logo {
        min-width: 170px;
        width: 170px;
        height: 160px;
        border: 1px solid #dedede;
        position: relative;
        -webkit-box-shadow: 0px 2px 4px 0px #e6e6e6;
        -moz-box-shadow: 0px 2px 4px 0px #e6e6e6;
        box-shadow: 0px 2px 4px 0px #e6e6e6;
        overflow: hidden; }
        body #publish-offer .preview-section .preview .offer-card .card-content .top .logo img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
      body #publish-offer .preview-section .preview .offer-card .card-content .top .offer-content {
        padding: 10px 0 15px 40px; }
        @media only screen and (max-width: 768px) {
          body #publish-offer .preview-section .preview .offer-card .card-content .top .offer-content {
            padding: 10px; } }
        body #publish-offer .preview-section .preview .offer-card .card-content .top .offer-content h2 {
          font-size: 30px;
          font-weight: 500;
          line-height: 26px;
          color: #2f2f2f;
          padding-bottom: 20px; }
          @media only screen and (max-width: 768px) {
            body #publish-offer .preview-section .preview .offer-card .card-content .top .offer-content h2 {
              font-size: 24px;
              padding-bottom: 10px; } }
        body #publish-offer .preview-section .preview .offer-card .card-content .top .offer-content p {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          color: #555555;
          padding-bottom: 10px; }
          body #publish-offer .preview-section .preview .offer-card .card-content .top .offer-content p br {
            display: none; }
    body #publish-offer .preview-section .preview .offer-card .card-content .bottom {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #dedede;
      padding: 30px 0; }
      @media only screen and (max-width: 768px) {
        body #publish-offer .preview-section .preview .offer-card .card-content .bottom {
          padding: 10px 0 20px 0;
          flex-direction: column; } }
      @media only screen and (max-width: 768px) {
        body #publish-offer .preview-section .preview .offer-card .card-content .bottom .meta {
          padding: 10px 0; } }
      body #publish-offer .preview-section .preview .offer-card .card-content .bottom .meta p {
        font-size: 14px;
        font-weight: 500;
        color: #999999; }
        body #publish-offer .preview-section .preview .offer-card .card-content .bottom .meta p span {
          color: #555555; }
      body #publish-offer .preview-section .preview .offer-card .card-content .bottom .buttons .btn {
        padding: 12px 45px; }
        @media only screen and (max-width: 1200px) {
          body #publish-offer .preview-section .preview .offer-card .card-content .bottom .buttons .btn {
            padding: 12px 36px; } }
        body #publish-offer .preview-section .preview .offer-card .card-content .bottom .buttons .btn:first-child {
          margin-right: 10px; }
        @media only screen and (max-width: 420px) {
          body #publish-offer .preview-section .preview .offer-card .card-content .bottom .buttons .btn {
            display: block;
            margin: 10px 0;
            width: 100%; } }
  body #publish-offer .preview-section .preview .offer {
    max-width: 100%;
    width: 100%;
    margin-top: 20px; }
    @media only screen and (max-width: 1024px) {
      body #publish-offer .preview-section .preview .offer > .container {
        max-width: 100%;
        padding: 0; } }
    body #publish-offer .preview-section .preview .offer .offer-main {
      text-align: center;
      padding: 100px; }
      @media only screen and (max-width: 768px) {
        body #publish-offer .preview-section .preview .offer .offer-main {
          padding: 30px; } }
      body #publish-offer .preview-section .preview .offer .offer-main .logo img {
        max-width: 100%;
        height: auto;
        max-height: 250px; }
        @media only screen and (max-width: 768px) {
          body #publish-offer .preview-section .preview .offer .offer-main .logo img {
            max-width: 100%; } }
      body #publish-offer .preview-section .preview .offer .offer-main .exclusive-offer h2 {
        font-size: 30px;
        font-weight: 500;
        line-height: 36px;
        color: #fdfeff;
        padding: 10px 0; }
        @media only screen and (max-width: 768px) {
          body #publish-offer .preview-section .preview .offer .offer-main .exclusive-offer h2 {
            font-size: 24px;
            line-height: 30px; } }
      body #publish-offer .preview-section .preview .offer .offer-main .offer-content {
        padding: 50px 0;
        max-width: 820px;
        margin: 0 auto;
        display: flex;
        display: flex;
        justify-content: space-between;
        text-align: left; }
        @media only screen and (max-width: 768px) {
          body #publish-offer .preview-section .preview .offer .offer-main .offer-content {
            padding: 20px 0; } }
        @media only screen and (max-width: 768px) {
          body #publish-offer .preview-section .preview .offer .offer-main .offer-content {
            max-width: 100%;
            flex-direction: column; } }
        @media only screen and (max-width: 768px) {
          body #publish-offer .preview-section .preview .offer .offer-main .offer-content .col {
            flex-basis: auto; } }
        body #publish-offer .preview-section .preview .offer .offer-main .offer-content .left-col ul li {
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-bottom: 5px; }
      body #publish-offer .preview-section .preview .offer .offer-main h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff;
        padding: 20px 0; }
      body #publish-offer .preview-section .preview .offer .offer-main p {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-bottom: 5px; }
      body #publish-offer .preview-section .preview .offer .offer-main .website {
        text-align: left;
        padding-left: 15px;
        max-width: 820px;
        margin: 0 auto; }
    body #publish-offer .preview-section .preview .offer .offer-card {
      margin-top: 0; }

body.main_hq main {
  padding-top: 92px;
  padding-bottom: 0; }
  @media only screen and (max-width: 1112px) {
    body.main_hq main {
      padding-top: 85px; } }

body #main-offers .layout {
  display: flex;
  display: flex;
  position: relative; }
  @media only screen and (max-width: 1024px) {
    body #main-offers .layout {
      display: block; } }
  body #main-offers .layout .left {
    background-color: #313a43;
    padding: 50px 0 30px 0;
    min-width: 350px;
    width: 15%; }
    @media only screen and (max-width: 1112px) {
      body #main-offers .layout .left {
        padding: 30px 0;
        min-width: 320px; } }
    @media only screen and (max-width: 1024px) {
      body #main-offers .layout .left {
        width: 100%;
        min-width: 0;
        max-width: 90%;
        margin: 20px auto;
        padding: 0; } }
    @media only screen and (max-width: 1024px) {
      body #main-offers .layout .left .panel {
        border-top: 3px solid #293038; } }
    @media only screen and (max-width: 1024px) {
      body #main-offers .layout .left .panel:first-child {
        border-top: none; } }
    body #main-offers .layout .left .panel .panel-title {
      font-size: 19px;
      font-weight: 500;
      line-height: 22px;
      color: #ffffff;
      padding: 20px 44px;
      position: relative;
      cursor: pointer; }
      @media only screen and (max-width: 1112px) {
        body #main-offers .layout .left .panel .panel-title {
          padding: 20px 30px; } }
      @media only screen and (max-width: 420px) {
        body #main-offers .layout .left .panel .panel-title {
          font-size: 16px; } }
      body #main-offers .layout .left .panel .panel-title a {
        font-size: 19px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff; }
        @media only screen and (max-width: 420px) {
          body #main-offers .layout .left .panel .panel-title a {
            font-size: 16px; } }
      body #main-offers .layout .left .panel .panel-title .arrow {
        position: absolute;
        top: 50%;
        right: 40px;
        cursor: pointer;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
        @media only screen and (min-width: 1025px) {
          body #main-offers .layout .left .panel .panel-title .arrow {
            display: none; } }
        body #main-offers .layout .left .panel .panel-title .arrow i {
          position: absolute;
          top: 50%;
          left: 50%;
          color: white;
          padding-right: 0;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
        body #main-offers .layout .left .panel .panel-title .arrow .fa-angle-up {
          opacity: 0; }
        body #main-offers .layout .left .panel .panel-title .arrow .fa-angle-down {
          opacity: 1; }
        body #main-offers .layout .left .panel .panel-title .arrow.open .fa-angle-up {
          opacity: 1; }
        body #main-offers .layout .left .panel .panel-title .arrow.open .fa-angle-down {
          opacity: 0; }
      body #main-offers .layout .left .panel .panel-title .fa {
        color: #6d737a;
        padding-right: 20px; }
    body #main-offers .layout .left .panel .panel-border {
      width: 100%;
      height: 3px;
      background: #293038;
      margin: 20px 0; }
      @media only screen and (max-width: 1024px) {
        body #main-offers .layout .left .panel .panel-border {
          display: none; } }
    body #main-offers .layout .left .panel ul.categories {
      padding: 20px 40px 40px; }
      @media only screen and (max-width: 1112px) {
        body #main-offers .layout .left .panel ul.categories {
          padding: 20px 30px; } }
      @media only screen and (min-width: 1025px) {
        body #main-offers .layout .left .panel ul.categories {
          display: block !important; } }
      @media only screen and (max-width: 1024px) {
        body #main-offers .layout .left .panel ul.categories {
          display: none;
          border-top: 3px solid #293038; } }
      body #main-offers .layout .left .panel ul.categories li {
        display: block;
        margin: 12px 0;
        padding: 0;
        position: relative; }
        body #main-offers .layout .left .panel ul.categories li a {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: white;
          -webkit-transition: color 0.3s;
          -moz-transition: color 0.3s;
          -ms-transition: color 0.3s;
          -o-transition: color 0.3s;
          transition: color 0.3s; }
          body #main-offers .layout .left .panel ul.categories li a:hover {
            color: #fc403c; }
        body #main-offers .layout .left .panel ul.categories li .cat-arrow {
          position: absolute;
          top: 4px;
          right: 10px;
          cursor: pointer; }
          body #main-offers .layout .left .panel ul.categories li .cat-arrow i {
            position: absolute;
            top: 0;
            left: 0;
            color: white; }
          body #main-offers .layout .left .panel ul.categories li .cat-arrow .fa-angle-up {
            opacity: 0; }
          body #main-offers .layout .left .panel ul.categories li .cat-arrow .fa-angle-down {
            opacity: 1; }
          body #main-offers .layout .left .panel ul.categories li .cat-arrow.open .fa-angle-up {
            opacity: 1; }
          body #main-offers .layout .left .panel ul.categories li .cat-arrow.open .fa-angle-down {
            opacity: 0; }
        body #main-offers .layout .left .panel ul.categories li > .sub-menu {
          padding: 20px; }
    body #main-offers .layout .left .panel.lms-panel ul.categories {
      padding-top: 10px;
      padding-bottom: 10px; }
      @media only screen and (max-width: 1024px) {
        body #main-offers .layout .left .panel.lms-panel ul.categories {
          display: block;
          border-top: 0;
          padding: 0; } }
      body #main-offers .layout .left .panel.lms-panel ul.categories > li {
        margin: 0; }
        @media only screen and (max-width: 1024px) {
          body #main-offers .layout .left .panel.lms-panel ul.categories > li {
            padding: 20px 29px; } }
        @media only screen and (max-width: 1024px) {
          body #main-offers .layout .left .panel.lms-panel ul.categories > li > a {
            font-size: 19px;
            font-weight: 500;
            line-height: 22px; } }
        @media only screen and (max-width: 420px) {
          body #main-offers .layout .left .panel.lms-panel ul.categories > li > a {
            font-size: 16px; } }
        @media only screen and (max-width: 360px) {
          body #main-offers .layout .left .panel.lms-panel ul.categories > li > a {
            font-size: 14px; } }
        @media only screen and (max-width: 1024px) {
          body #main-offers .layout .left .panel.lms-panel ul.categories > li .cat-arrow {
            top: 24px;
            right: 40px; }
            body #main-offers .layout .left .panel.lms-panel ul.categories > li .cat-arrow i {
              top: 50%;
              left: 50%;
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              transform: translateX(-50%); } }
  body #main-offers .layout .right {
    background: #fbfcfe;
    padding: 60px 0px;
    width: 85%;
    position: relative; }
    @media only screen and (max-width: 1200px) {
      body #main-offers .layout .right {
        padding: 30px 0; } }
    @media only screen and (max-width: 1024px) {
      body #main-offers .layout .right {
        width: 100%; } }
    body #main-offers .layout .right .welcome {
      text-align: center; }
      @media only screen and (max-width: 1024px) {
        body #main-offers .layout .right .welcome > .container {
          width: 90%;
          padding: 0; } }
      body #main-offers .layout .right .welcome h1 {
        font-size: 61px;
        font-weight: 400;
        line-height: 72px;
        color: #0a3761; }
        @media only screen and (max-width: 768px) {
          body #main-offers .layout .right .welcome h1 {
            font-size: 40px;
            line-height: 50px; } }
      body #main-offers .layout .right .welcome h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: #2f2f2f;
        padding: 10px 0; }
      body #main-offers .layout .right .welcome .picture {
        padding: 20px 0; }
        body #main-offers .layout .right .welcome .picture img {
          max-width: 100%; }
      body #main-offers .layout .right .welcome p {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        color: #717171;
        padding-bottom: 20px; }
    body #main-offers .layout .right .requestLoader {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      display: none;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      body #main-offers .layout .right .requestLoader .spinner-container .spinner {
        border-top-color: white; }
      body #main-offers .layout .right .requestLoader.loading {
        display: block; }
        body #main-offers .layout .right .requestLoader.loading .spinner-container {
          -webkit-transform: translate(50%, -50%);
          -moz-transform: translate(50%, -50%);
          -ms-transform: translate(50%, -50%);
          -o-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
          opacity: 1;
          position: absolute;
          top: 10%;
          right: 50%; }
        body #main-offers .layout .right .requestLoader.loading .overlay {
          background-color: #dddddd; }
    body #main-offers .layout .right .offers {
      padding: 10px 50px; }
      @media only screen and (max-width: 1200px) {
        body #main-offers .layout .right .offers {
          padding: 10px 30px; } }
      body #main-offers .layout .right .offers .see-more-container {
        padding: 30px 0;
        width: 100%; }
      body #main-offers .layout .right .offers .offers-title {
        font-size: 19px;
        font-weight: 500;
        line-height: 24px;
        color: #2f2f2f;
        padding-bottom: 5px; }
    body #main-offers .layout .right .offer-card {
      width: 100%;
      max-width: 100%;
      margin-top: 20px;
      padding: 12px 50px 0px 40px;
      border: 1px solid #dedede;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: 0px 2px 4px 0px #e6e6e6;
      -moz-box-shadow: 0px 2px 4px 0px #e6e6e6;
      box-shadow: 0px 2px 4px 0px #e6e6e6;
      background: #ffffff; }
      @media only screen and (max-width: 1200px) {
        body #main-offers .layout .right .offer-card {
          padding: 12px 30px 0 20px; } }
      body #main-offers .layout .right .offer-card .card-content .top {
        display: flex;
        display: flex;
        align-items: center;
        padding: 30px 0; }
        @media only screen and (max-width: 1200px) {
          body #main-offers .layout .right .offer-card .card-content .top {
            padding: 20px 0; } }
        @media only screen and (max-width: 640px) {
          body #main-offers .layout .right .offer-card .card-content .top {
            flex-direction: column;
            text-align: center;
            padding: 0; } }
        body #main-offers .layout .right .offer-card .card-content .top .logo {
          min-width: 170px;
          width: 170px;
          height: 160px;
          border: 1px solid #dedede;
          position: relative;
          -webkit-box-shadow: 0px 2px 4px 0px #e6e6e6;
          -moz-box-shadow: 0px 2px 4px 0px #e6e6e6;
          box-shadow: 0px 2px 4px 0px #e6e6e6;
          overflow: hidden; }
          body #main-offers .layout .right .offer-card .card-content .top .logo img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
        body #main-offers .layout .right .offer-card .card-content .top .offer-content {
          padding: 10px 0 15px 40px; }
          @media only screen and (max-width: 640px) {
            body #main-offers .layout .right .offer-card .card-content .top .offer-content {
              padding: 10px; } }
          body #main-offers .layout .right .offer-card .card-content .top .offer-content h2 {
            font-size: 30px;
            font-weight: 500;
            line-height: 26px;
            color: #2f2f2f;
            padding-bottom: 20px; }
            @media only screen and (max-width: 640px) {
              body #main-offers .layout .right .offer-card .card-content .top .offer-content h2 {
                font-size: 24px;
                padding-bottom: 10px; } }
          body #main-offers .layout .right .offer-card .card-content .top .offer-content p {
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            color: #555555;
            padding-bottom: 10px; }
            body #main-offers .layout .right .offer-card .card-content .top .offer-content p br {
              display: none; }
      body #main-offers .layout .right .offer-card .card-content .bottom {
        display: flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #dedede;
        padding: 30px 0; }
        @media only screen and (max-width: 640px) {
          body #main-offers .layout .right .offer-card .card-content .bottom {
            padding: 10px 0 20px 0;
            flex-direction: column; } }
        @media only screen and (max-width: 640px) {
          body #main-offers .layout .right .offer-card .card-content .bottom .meta {
            padding: 10px 0; } }
        body #main-offers .layout .right .offer-card .card-content .bottom .meta p {
          font-size: 14px;
          font-weight: 500;
          color: #999999; }
          body #main-offers .layout .right .offer-card .card-content .bottom .meta p span {
            color: #555555; }
        body #main-offers .layout .right .offer-card .card-content .bottom .buttons .btn {
          padding: 12px 45px; }
          @media only screen and (max-width: 1200px) {
            body #main-offers .layout .right .offer-card .card-content .bottom .buttons .btn {
              padding: 12px 36px; } }
          body #main-offers .layout .right .offer-card .card-content .bottom .buttons .btn:first-child {
            margin-right: 10px; }
          @media only screen and (max-width: 420px) {
            body #main-offers .layout .right .offer-card .card-content .bottom .buttons .btn {
              display: block;
              margin: 10px 0;
              width: 100%; } }
    body #main-offers .layout .right .offer {
      max-width: 100%;
      width: 100%; }
      @media only screen and (max-width: 1024px) {
        body #main-offers .layout .right .offer > .container {
          max-width: 90%;
          padding: 0; } }
      body #main-offers .layout .right .offer .purchase-title {
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: #555555;
        text-align: center; }
        @media only screen and (max-width: 639px) {
          body #main-offers .layout .right .offer .purchase-title {
            font-size: 20px;
            line-height: 24px; } }
        body #main-offers .layout .right .offer .purchase-title a {
          font-size: 24px; }
          @media only screen and (max-width: 639px) {
            body #main-offers .layout .right .offer .purchase-title a {
              font-size: 20px;
              line-height: 24px; } }
      body #main-offers .layout .right .offer .offer-main {
        text-align: center;
        padding: 100px; }
        @media only screen and (max-width: 768px) {
          body #main-offers .layout .right .offer .offer-main {
            padding: 30px; } }
        body #main-offers .layout .right .offer .offer-main .logo img {
          max-width: 100%;
          height: auto;
          max-height: 250px; }
          @media only screen and (max-width: 640px) {
            body #main-offers .layout .right .offer .offer-main .logo img {
              max-width: 100%; } }
        body #main-offers .layout .right .offer .offer-main .exclusive-offer h2 {
          font-size: 30px;
          font-weight: 500;
          line-height: 36px;
          color: #fdfeff;
          padding: 10px 0; }
          @media only screen and (max-width: 640px) {
            body #main-offers .layout .right .offer .offer-main .exclusive-offer h2 {
              font-size: 24px;
              line-height: 30px; } }
        body #main-offers .layout .right .offer .offer-main .offer-content {
          padding: 50px 0 20px 0;
          max-width: 820px;
          margin: 0 auto;
          display: flex;
          display: flex;
          justify-content: space-between;
          text-align: left; }
          @media only screen and (max-width: 768px) {
            body #main-offers .layout .right .offer .offer-main .offer-content {
              padding: 20px 0; } }
          @media only screen and (max-width: 640px) {
            body #main-offers .layout .right .offer .offer-main .offer-content {
              max-width: 100%;
              flex-direction: column; } }
          @media only screen and (max-width: 640px) {
            body #main-offers .layout .right .offer .offer-main .offer-content .col {
              flex-basis: auto; } }
          body #main-offers .layout .right .offer .offer-main .offer-content .left-col ul li {
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 5px; }
        body #main-offers .layout .right .offer .offer-main h3 {
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          color: #ffffff;
          padding: 20px 0; }
        body #main-offers .layout .right .offer .offer-main p {
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-bottom: 5px; }
        body #main-offers .layout .right .offer .offer-main .website {
          text-align: left;
          padding-left: 15px;
          max-width: 820px;
          margin: 0 auto; }
      body #main-offers .layout .right .offer .offer-card {
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }

body .manage-offers #offers-list .offer-card {
  height: 300px; }
  body .manage-offers #offers-list .offer-card .card-body .status {
    padding-top: 8px;
    padding-bottom: 8px; }
  body .manage-offers #offers-list .offer-card .card-body .details {
    padding-top: 20px;
    padding-bottom: 20px; }
    body .manage-offers #offers-list .offer-card .card-body .details .card-text {
      padding: 5px 0; }
  body .manage-offers #offers-list .offer-card .card-footer div {
    padding: 0; }

.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 35px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 35px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }
