body {
  &.partners {
    & .container {
      & img.hq-featurette{
        max-height: 600px;
      }
      & .row {
        &.featurette {
          & ul.featurette-list {
            & li {
              max-width: 270px;
              width: auto;
            }
          }
          &.partners-contact {
            padding-top: 100px;
            & h3.featurette-heading {
              margin-bottom: 10px !important;
            }
            & .form-container {
              padding-top: 40px;
              & form{
                & .radio-fields-wrapper{
                  @include flex(flex, ai_center);
                  & .radio-wrap{
                    & .radio-label{
                      padding: 0 15px;
                    }
                    & .input-radio{
                      & + label{
                        padding-left: 10px;
                      }
                    }
                  }
                }
                & .buttons-container{
                  padding-top: 30px;
                  & .button{
                    padding: 25px 75px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}