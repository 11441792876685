#register {
  // background: $light-grey;
  .container {
    max-width: 100%;

    @include media-type(991px) {
      max-width: 720px;
    }
  }
  .tag-container {
    .tag {
      @include media-type(1570px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      @include media-type(991px) {
        // padding-right: 0;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  & .register-content{
    & h2{
      padding-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
    }
    & p{
      line-height: 22px;
      padding-bottom: 20px;
    }
  }
  .form-container {
    @include flex(flex, jc_center);
    form {
      width: $register-form-width;
      // min-height: $register-form-height;
      background-color: $light-grey;
      padding: $register-form-padding;
      position: relative;

      @include media-type(991px) {
        width: 100%;
      }
      input, select {
        // border-radius: 10px;
      }
      button {
        position: absolute;
        left: 0;
        bottom: -4%;
        background-color: $register-form-btn-color;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
  & form {
    & label {
      & .membership-info {
        font-size: 12px;
        & a {
          font-size: 12px;
        }
      }
    }
    & .business-wrapper{
      margin-bottom: 30px;
      & .check-wrap{
        padding: 0;
        & .check-label{
          & label{
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
