body.home {
  #hero {
    min-height: $front-page-hero-height;
    background-color: $front-page-hero-bg-color;
    background-image: none !important;
    @include media-type(phone) {
      padding-top: 110px;
      padding-bottom: 150px;
    }
    .btn {
      //border-radius: .25rem;
      //background-color: map-get($palette, btn-grey);
      //padding: 1.5em 2.5em;
      &:hover {
        //background-color: map-get($palette, btn-red);
      }
    }
    & .home-buttons{
      @include flex(flex, ai_center, jc_center);
      padding: 20px 0;
      @include media-type(phone) {
        @include flex(column);
      }
      & a{
        &:first-child{
          margin-right: 20px;
          @include media-type(phone) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
    .btn-group {
      box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.2);

      @include media-type(tablet) {
        text-align: center;
      }
      @include media-type(phone) {
        @include flex(column);
      }
    }
    .btn-group > .btn-group:not(:first-child) > .btn,
    .btn-group > .btn:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid #515151;

      @include media-type(phone) {

        border-left: 0px;
        border-top-right-radius: 0;
        border-bottom-left-radius: .25rem;
      }
    }
    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid #515151;
      @include media-type(phone) {

        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0;
        border-right: 0px;
        border-bottom: 1px solid #515151;
      }
    }
    .tag-container {
      @include flex(flex, ai_center, wrap);
      margin-right: -15px;
      margin-left: -15px;
      max-width: 1300px;
      @include media-type(tablet) {
        margin-right: 0px;
        margin-left: 0px;

      }
      @include media-type(1144px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center !important;
      }
    }
    .tagline {
      @include media-type(laptop) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include media-type(phone) {
        text-align: center;
      }

      h1, p, h2 {
        @include media-type(1144px) {
          text-align: center;
        }
      }
      .btn-group {
        @include media-type(1144px) {
          margin: auto;
        }
        .search-btn {

          @include media-type(phone) {
            border-bottom: 0 !important;
          }
        }
        .opp-btn {
          @include media-type(phone) {
            display: none;
          }
        }
      }

    }

    .image {
      @include media-type(1144px) {
        display: none;
      }
    }
    &.main-panel{
      & .mobile-hide {
        @include media-type(phone) {
          display: none;
        }
      }
    }
  }

  & .tabs-panel {
    width: 100%;
    padding: 55px 20px;
    max-width: 1050px;
    margin: 0 auto;
    @include media-type(639px) {
      padding-top: 25px;
    }
    & nav.tabs {
      background-color: transparent;
      min-height: 0;
      @include flex(flex, jc_space_between, ai_center);
      @include media-type(phone) {
        display: none;
      }
      & a {
        display: block !important;
        padding: 10px 15px;
        margin: 0 5px;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: map-get($palette, darkfont);
        -webkit-transition: border 0.3s;
        -moz-transition: border 0.3s;
        -ms-transition: border 0.3s;
        -o-transition: border 0.3s;
        transition: border 0.3s;
        background: transparent;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        color: #5d5d5d;
        text-align: center;
        @include media-type(1040px) {
          padding: 10px;
        }
        &:hover {
          border-color: #e0e0e0;
          -webkit-box-shadow: 3px 3px 15px rgba(145, 145, 145, .2);
          -moz-box-shadow: 3px 3px 15px rgba(145, 145, 145, .2);
          box-shadow: 3px 3px 15px rgba(145, 145, 145, .2);
          color: #5d5d5d;
        }
        &.active {
          background: #0056b3;
          color: white;
        }
      }
    }
    & .tab-section {
      position: relative;
      & .tab-arrows {
        width: 100%;
        @include media-type(phone) {
          display: none;
        }
        & .arrow {
          position: absolute;
          left: -35px;
          right: auto;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
          @include media-type(laptop) {
            left: 0;
          }
          &.right-arrow {
            right: -35px;
            left: auto;
            @include media-type(laptop) {
              right: 0;
            }
          }
          & .fa {
            font-size: 60px;
            @include media-type(tablet) {
              font-size: 40px;
            }
          }
        }
      }
    }
    & button.tab {
      display: none;
      width: 100%;
      padding: 15px 20px;
      text-align: center;
      padding: 10px 20px;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      background: map-get($palette, hero-blue);
      -webkit-transition: border 0.3s;
      -moz-transition: border 0.3s;
      -ms-transition: border 0.3s;
      -o-transition: border 0.3s;
      transition: border 0.3s;
      color: white;
      cursor: pointer;
      outline: none;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      margin-top: 5px;
      @include media-type(phone) {
        display: block;
      }
    }
    & .tab-content {
      display: none;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      max-width: 820px;
      margin: 0 auto;
      min-height: 550px;
      @include media-type(phone) {
        min-height: 0;
      }
      &.active {
        @include flex(flex, ai_center);
      }
      & .tab-content-holder {
        padding: 40px 20px;
        @include media-type(laptop) {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
      & h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: #2f2f2f;
        margin-bottom: 20px;
        @include media-type(499px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      & p {
        //font-size: 14px;
        color: #2f2f2f;
        font-weight: 400;
        //line-height: 22px;
        padding-bottom: 20px;
        font-size: 18px;
        letter-spacing: .105px;
        line-height: 28px;
      }
      & .img-col {
        text-align: center;
        & img {
          max-width: 200px;
        }
      }
    }
  }

}

#home {
  // background-color: $white;

  .container {
    max-width: $front-page-hero-container-width;

    &.marketing {
      margin-top: -128px;

      .info {
        & > div {
          @include media-type(tablet) {
            margin-top: 2em;
          }
        }
      }
      & a.action-item {
        & .img-wrap {
          max-width: 148px;
          margin: 0 auto;
          & > img {
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
          }
        }
        &:hover {
          & .img-wrap {
            & > img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .row {
      max-width: $front-page-row-width;
      @include flex(ai_center);

      &.featurette {
        margin: auto;
        @include media-type(tablet) {
          margin-bottom: 6em;
        }
        @include media-type(phone) {
          margin-bottom: 6em;
        }

        .featurette-heading {
          font-size: 30px;
          line-height: 40px;
          letter-spacing: .025em;
          margin-bottom: 25px;

        }
        .featurette-body {
          font-size: 18px;
          letter-spacing: .105px;
          line-height: 28px;
          margin-bottom: 50px;
        }

        a {
          padding: .8rem 1.5rem;
          font-size: 15px;
          line-height: 17.5px;
          letter-spacing: .025em;
        }

        .img-container {
          @include media-type(phone) {
            margin-top: 3em;
          }
        }
        .has-image {
          height: 757px;

          @include media-type(499px) {
            height: 500px;
          }

          img {
            left: 0;
          }
        }

      }
      // NOTE: end of featurette

      &.get-started {
        max-height: 640px;
        height: 61vh;
        padding: 100px 0;
      }
      &.mission {
        padding: 180px 0 50px 0;
        @include media-type(phone) {
          padding: 50px 0;
          margin-bottom: 0;
        }
      }

      &.info {
        padding: 10rem 0;
        margin: auto;

        div {
          text-align: center;
          img {
            margin-bottom: 2em;
          }
        }
        a.btn {
          padding: 1.2rem 2.5rem;
          font-size: 17px;
          line-height: 21px;
          letter-spacing: .025em;
        }
      }
      // NOTE: end of info

    }
    // NOTE: end of row

  }
  // NOTE: end of container

  & .row{
    &.full-featurette{
      padding: 40px 0;
      background-color: map-get($palette, hero-blue);
      & h4{
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        color: white;
      }
      & .button-col{
        padding-left: 50px;
      }
    }
  }

  .bottom-container {
    position: absolute;
    bottom: 12%;
    width: 100%;

    img {
      max-width: 100%;
    }
    .home-Leftside-Arrow {
      left: 0;
      width: 450px;
      @include media-type(laptop) {
        top: 100px;
        width: 400px;
      }
      @include media-type(1205px) {
        visibility: hidden;
      }
    }
    .home-Rightside-Arrow {
      right: 0;
      top: 100px;
      width: 500px;
      @include media-type(laptop) {
        top: 170px;
        width: 400px;
      }
      @include media-type(1205px) {
        visibility: hidden;
      }
      @include media-type(1205px) {
        visibility: hidden;
      }
    }

    .get-started-cube-left {
      top: 338px;
      left: 30%;
      @include media-type(tablet) {

        top: 438px;
      }
      @include media-type(phone) {

        top: 538px;
        left: 20%;
      }
    }
  }
  // NOTE: end of bottom container

  .shadowed {
    margin-bottom: 15px;
    background: linear-gradient(
                    to bottom,
                    map-get($palette, off-white), $white 75%
    );
    border-radius: 20px;
    box-shadow: 0px -20px 10px rgba(0, 0, 0, 0.1);
  }
  .shadowed-2 {
    margin-bottom: 155px;
    background-color: map-get($palette, off-white);

    border-radius: 20px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);

    @include media-type(tablet) {

      padding-top: 4.5em;
    }
  }
  .firms-cube-top-left {
    top: -100px;
    left: 40px;
    z-index: 1;
  }
  .firm-right-cube {
    right: 0;
    bottom: 0;
  }
  .business-cube-center {
    left: 0;
    right: 0;
    bottom: -80px;
    @include media-type(phone) {
      visibility: hidden;
    }
  }
}

// NOTE: end of home
