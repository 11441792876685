footer {
  nav.navbar {
    padding: 30px 0 !important;
    @include media-type(980px) {
      padding: 20px 0 !important;
    }
    & .nav {
      margin-bottom: 5px;
      & li.nav-item {
        & .nav-link {
          height: auto;
          font-size: 14px;
        }
      }
    }

    .container {
      @include flex(flex, ai_center);
      .navbar-brand {
        // padding-top: 40px!important;
        // height: 130px;
        @include media-type(980px) {
          width: 100%;
          @include flex(jc_center);
        }
      }
      .mid {
        @include media-type(980px) {
          width: 100%;
        }
        .col-lg-12 {
          @include flex(flex, jc_center);
          @include media-type(1466px) {
            @include flex(column);
          }
          .nav {
            @include flex(jc_center);
            @include media-type(1024px) {
              @include flex(jc_end);
            }
            @include media-type(980px) {
              @include flex(jc_center);
            }
          }
        }
        & .footer-text{
          @include media-type(1466px) {
            text-align: center;
          }
          @include media-type(1024px) {
            text-align: right;
          }
          @include media-type(980px) {
            text-align: center;
          }
        }
      }
      .end {
        @include media-type(980px) {
          width: 100%;
        }
        & > div{
          @include media-type(1024px) {
            width: 100%;
          }
        }
        ul {
          @include media-type(980px) {
            justify-content: center !important;
          }
        }
        .footer-text {
          @include media-type(980px) {
            text-align: center;
          }
        }
      }

      .mid, .end {
        //padding-top: 15px !important;
        //padding-bottom: 35px !important;
      }
      & .end{
        @include media-type(980px) {
          margin-top: 10px;
        }
      }
      .footer-text {
        //margin-top: -15px;
      }
    }
  }
}
