.profile-image-container{
  &.premium-member{
    border: 3px solid #1a2344;
  }
}
#dashboard {
  @include media-type(1271px) {
    // NOTE: break all into full-width rows
  }

  #hero {
    height: $dashboard-hero-height;
    background-color: $med-grey;
    @include media-type(500px) {
      min-height: $dashboard-mobile-hero-height;
      height: $dashboard-mobile-hero-height;
    }
    & .become-partner-section {
      @include flex(flex, jc_end);
      text-align: center;
      & a {
        color: white;
        font-size: 24px;
        line-height: 30px;
        @include media-type(768px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &.together-strong-section{
        @include media-type(768px) {
          padding: 0 10px;
        }
        & span{
          display: block;
          color: white;
          font-size: 12px;
          line-height: 16px;
          padding: 0 20px;
          &.text-main{
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 8px;
            padding: 0;
          }
        }
      }
    }
  }
  #dash-user {
    padding: 0;
    background-color: #fff;
    // box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 13px;
    max-width: 335px;

    @include media-type(1271px) {
      // NOTE: break all into full-width rows
      -webkit-box-flex: 0;
      flex: 0 0 90%;
      max-width: 90%;
    }
    p {
      font-size: 13px;
      line-height: 15px;
    }
    li {
      font-size: 13px;
      line-height: 15px;
    }
    a {

      line-height: 15px;
      font-size: 13px;
    }
    & .membership-status {
      padding-bottom: 5px;
      @include media-type(1271px) {
        order: 1;
      }
      @include media-type(499px) {
        order: 0;
      }
    }
    .firm-members {
      // border-bottom: 2px solid rgba(0,0,0,.1);
      padding: 3.5em 3em;

      .col.l3 {
        padding-bottom: 0;
        padding: 1em 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .container {
      padding: 0;
    }
    .med {
      margin-bottom: 13px;
    }
    & .bio-wrap {
      figure {
        width: 230px;
        height: 230px;
        &.rounded-div {
          margin-top: -65px;
          margin-bottom: 40px;

          @include media-type(1271px) {
            height: 40px;
            width: 40px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      & .partner-badge,
      & .member-badge{
        padding: 15px;
        width: 100%;
        margin-top: -30px;
        margin-bottom: 10px;
        text-align: center;
        background: map-get($palette, green);
        @include media-type(1271px) {
          margin-top: 0;
        }
        & p {
          color: white;
          font-size: 18px;
          font-weight: 500;
          font-style: italic;
        }
        &.bg-blue{
          background: map-get($palette, hero-blue);
        }
      }
      & > .user-bio {
        &.border-bottom-0 {
          @include media-type(1271px) {
            padding: 10px 2em 2em;
            @include flex(ai_center);
          }
          @include media-type(499px) {
            @include flex(column);
          }
        }
      }
    }
    .user-bio {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      padding: 0 3em 3em;

      &.border-bottom-0 {
        padding-bottom: 0;

        @include media-type(1271px) {
          padding: 10px;
          min-height: 40px;
          @include flex(flex, jc_space_between);
          .name, .type, .location {
            display: none;
          }
        }
      }
      .actions {
        max-width: 174px;
        margin: 20px auto 50px;

        @include media-type(1271px) {
          // margin-bottom: 15px;
          margin: 0;
        }
        @include media-type(499px) {
          margin: 20px 0;
        }
      }
      .toggle-action {
        display: none;
        @include media-type(1271px) {
          display: block;
          // margin-bottom: 55px;
        }
      }
    }
    // NOTE: end of user-bio
    #more-info.collapse {
      display: block;
      @include media-type(1271px) {
        display: none;
        margin-top: 55px;
      }
    }

    .user-info {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      padding: 3.5em 3em;

      .specialization {
        margin-top: 20px;
      }
    }

    .user-association {

      padding: 3.5em 3em;
    }
    input {
      border-left: 0;

      &:focus {

        border-left: 2px solid;
      }
    }
  }
  // NOTE: end of dash-user

  #dash-notifications {
    max-width: 830px;
    max-height: 1185px;
    overflow-y: auto;
    @include media-type(1271px) {
      // NOTE: break all into full-width rows
      -webkit-box-flex: 0;
      flex: 0 0 90%;
      max-width: 90%;

      padding: 0;
    }

    .top.container {
      padding-top: 55px;
    }

  }
  #dash-ads {
    background-color: #fff;
    // box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
    border: 2px solid rgba(0, 0, 0, 0.1);
    max-width: 335px;

    @include media-type(1271px) {
      // NOTE: break all into full-width rows
      -webkit-box-flex: 0;
      flex: 0 0 90%;
      max-width: 90%;

      padding: 0;
    }
  }

  .profile-pic {
    max-width: 167px;
    max-height: 167px;
    width: 100%;
    height: 167px;
  }
}

// NOTE: notification cards
.new-opportunity { // green
  border-left: 10px solid #44ae4b !important;
  @include media-type(phone) {
    border-left: 0 !important;
    border-top: 10px solid #44ae4b !important;
  }
}

.new-application { //red
  border-left: 10px solid #ad2b53 !important;
  @include media-type(phone) {
    border-left: 0 !important;
    border-top: 10px solid #ad2b53 !important;
  }
}

.new-contact-request { //purple
  border-left: 10px solid #6335b8 !important;
  @include media-type(phone) {
    border-left: 0 !important;
    border-top: 10px solid #6335b8 !important;
  }
}

.new-team-request { // light blue
  border-left: 10px solid #2db7f1 !important;
  @include media-type(phone) {
    border-left: 0 !important;
    border-top: 10px solid #2db7f1 !important;
  }
}

.new-message { // yellow
  border-left: 10px solid #f1ea2d !important;
  @include media-type(phone) {
    border-left: 0 !important;
    border-top: 10px solid #f1ea2d !important;
  }
}

.card-notification { // dark blue
  max-width: 785px;
  width: 100%;
  box-shadow: 0px 4px 10px -8px rgba(0, 0, 0, 0.35);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 10px solid #082a49;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: auto;
  color: #5d5d5d;
  margin-bottom: 30px;

  @include media-type(phone) {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;

    border-left: 0;

    border-top: 10px solid #082a49;
  }
  .fas, .fab {
    color: map-get($palette, btn-dark-blue);
  }
  .top {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 23px;

    .top-icon {
      padding: 0;
      font-size: 35px;
    }
    .type {
      padding: 0;
      @include flex(flex, ai_center);

      p {
        margin-bottom: 0;
      }
    }
    .more {
      @include flex(flex, ai_center, jc_center);
    }
  }
  // NOTE: end of top
  .middle {
    padding: 50px 23px;

    .middle-icon {
      padding: 0;
      margin-top: -6px
    }

    .content {
      padding: 0;

      p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;

        &.heading {
          margin-bottom: 20px;
        }
        &.desc {
          margin-bottom: 5px;
        }
      }
    }
  }
  // NOTE: end of middle
  .bottom {
    padding: 0px 23px;

    .noteb {
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      padding: 23px 0;
      @include flex(jc_space_between);

      .col {
        // padding: 0; removed because I might not have cols nested here anymore
      }
    }

    .view {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
    .accept {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
      padding-right: 5px;
      @include media-type(phone) {
        i {
          display: none;
        }
      }
    }
    .reject {
      padding-left: 5px;

      @include media-type(phone) {
        i {
          display: none;
        }
      }
    }
  }
  // NOTE: end of bottom

  &.opportunity {
  }

}

.user-info {
  a {
    color: #5d5d5d;
    font-weight: normal;
    i {
      color: map-get($palette, btn-dark-blue);
    }
  }
}
