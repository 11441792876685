#pricing {
  & > .container {
    max-width: 1260px;
  }
  .card-wrapper {
    max-width: 315px;
  }

  .filler {
    @include media-type(991px) {

      justify-content: center;
    }
  }
}

.pricing-card {
  border: 10px solid lighten(map-get($palette, btn-light-blue), 50%);

  .card-header {
    p, h3 {
      color: $white;
    }
    background-color: map-get($palette, green);
    text-align: right;
  }
  .card-body {
    padding-top: 0;
    padding-bottom: 70px;
    border-bottom: 10px solid lighten(map-get($palette, btn-light-blue), 50%);
    p.main {
      margin-bottom: 10px;
    }

    ul.main {

      & > li {
        font-weight: 500;
        font-size: 17px;

        padding-left: 30px;
        position: relative;

        &::before {
          content: ' ';
          left: 0;
          top: 5px;
          position: absolute;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          background-color: map-get($palette, btn-red);
          margin-right: 10px;
        }
      }

      ul.inline-list {
        li {
          display: inline;
        }
      }
    }

    .card-price {
      border-top: 10px solid lighten(map-get($palette, btn-light-blue), 50%);
      border-bottom: 10px solid lighten(map-get($palette, btn-light-blue), 50%);
      margin: 0 -1.25rem;
      padding: 50px 1.25rem;
      position: relative;
      background-color: lighten(map-get($palette, btn-light-blue), 50%);
      margin-bottom: 20px;
      h2, span {
        color: map-get($palette, btn-dark-blue);
      }
      &.card-ad-price {
        padding: 10px 1.25rem;
        & h2.type {
          font-size: 30px;
          line-height: 36px;
        }
      }
    }
  }
  .card-footer {
    ul, li {
      color: $white;

    }

    li {
      padding-left: 30px;
      position: relative;

      &::before {
        content: ' ';
        left: 0;
        top: 5px;
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: white;
        margin-right: 10px;
      }
    }
    p, h3, span {
      color: $white;
    }
    background-color: map-get($palette, green);
  }
  &.red{
    & .card-body{
      & .card-price{
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    .card-header {
      background-color: map-get($palette, btn-red);
    }
    .card-footer {
      background-color: map-get($palette, btn-red);
    }
  }
  &.blue {
    .card-header {
      background-color: map-get($palette, btn-dark-blue);
    }
    .card-footer {
      background-color: map-get($palette, btn-dark-blue);
    }
  }
  &.brown {
    .card-header {
      background-color: saddlebrown;
    }
    .card-footer {
      background-color: saddlebrown;
    }
  }
  &.orange {
    .card-header {
      background-color: #ef5822;
    }
    .card-footer {
      background-color: #ef5822;
    }
  }
  &.purple {
    .card-header {
      background-color: #6335b8;
    }
    .card-footer {
      background-color: #6335b8;
    }
  }
}
