$topbar-bg-color: #082a49;
$nav-link-color: map-get($palette, white);






//    3. menus
//    ---------
// global Navigation

// nav node
$topbar-mobile-menu-trigger-width: tablet;

$topbar-height: 92px;
$topbar-background: map-get($palette, nav-blue);
$topbar-flex-direction: column;
$topbar-justify-content: flex-start;
$topbar-align-items: center;
$topbar-mobile-height: 85px;
$topbar-bg-mobile: map-get($palette, nav-blue);
$topbar-position: absolute;
$topbar-top: 35px;
// nav wrapper
$nav-wrapper-width: 100%;
$nav-wrapper-justify-content: space-between;
$nav-wrapper-justify-content-mobile: space-between;
$nav-wrapper-width-mobile: 100%;
$nav-wrapper-flex-direction: row;
$nav-wrapper-height: 90px;
$nav-wrapper-postion: relative;

// nav
$nav-display: flex;

//nav menu
$menu-flex-direction: row;

//nav menu links
$nav-link-height: $topbar-height;
$nav-link-padding: 1rem 2rem;
$nav-link-padding-laptop: 1rem 1.5rem;

$nav-link-color:  map-get($palette, white);

$nav-link-hover-color: $dark-grey;
$nav-link-hover-bg: map-get($palette, white);
$nav-link-hover-color-mobile: $dark-grey;
$nav-link-hover-bg-mobile:  map-get($palette, white);;

$nav-link-font-size: 14px; //firaSans Medium
$nav-link-line-height: 17.65px;
$nav-link-letter-spacing: .05em;
$nav-link-font-weight: 500;
$nav-link-text-transform: uppercase;


// navbar logo
$nav-logo-width: 281px;
$nav-logo-position: center;
$nav-logo-margin: 0 0 0 1em;
$nav-logo-margin-mobile: 0 0 0 30px;



// NOTE: hamburger

$hamburger-padding-x                       : 10px !default;
$hamburger-padding-y                       : 10px !default;
$hamburger-layer-width                     : 35px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : #fff !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
  // 3dx,
  // 3dx-r,
  // 3dy,
  // 3dy-r,
  // 3dxy,
  // 3dxy-r,
  // arrow,
  // arrow-r,
  // arrowalt,
  // arrowalt-r,
  // arrowturn,
  // arrowturn-r,
  // boring,
  // collapse,
  // collapse-r,
  // elastic,
  // elastic-r,
  // emphatic,
  // emphatic-r,
  minus,
  // slider,
  // slider-r,
  // spring,
  // spring-r,
  // stand,
  // stand-r,
  // spin,
  // spin-r,
  // squeeze,
  // vortex,
  // vortex-r
) !default;
