
.edit {

  div.notification {
    box-shadow: none;
    background-color: #2ecc71;

    p,h4 {

      color: $white;
    }

    &.success {
      background-color: #2ecc71;
    }


    &.error {
      background-color: #e74c3c;
    }

    .material-icons {
      color: $white;
    }
  }
}
div.notification {
  p,h4 {

    color: $white;
  }
  &.success {
    background-color: #2ecc71;
  }


  &.error {
    background-color: #e74c3c;
  }

  .material-icons {
    color: $white;
  }
}
