[id^="people-card"] {
  width: 313px;
  height: 455px;
  padding: 0 !important;

  .card-header {
    background-color: transparent !important;
  }

  .card-body {
    figure {
      height: 140px;
      width: 140px;
    }
    .body-notification {
      @include flex(flex, jc_space_between, ai_center);
      padding: 5px 0;
      p {
        margin-bottom: 0;
      }
    }
    .membership-status{
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 20px;
      font-weight: bold;
    }
    & .partner-badge,
    & .member-badge{
      padding: 5px;
      width: 100%;
      text-align: center;
      background: map-get($palette, green);
      margin-top: 5px;
      margin-bottom: 10px;
      & p {
        color: white;
        font-size: 18px;
        font-weight: 500;
        font-style: italic;
      }
      &.bg-blue{
        background: map-get($palette, hero-blue);
      }
    }
  }

  .card-footer {
    background-color: transparent !important;
  }
}