input, input.form-control,
select, select.custom-select,
.custom-file-label,
textarea, textarea.form-control,
button.form-button,
.custom-checks-box .checks-box,
.form-field input.input,
.form-field select.input,
.form-field textarea.input,
.file-upload .file-upload-wrapper .file-uploader .file-container {
  color: map-get($palette, font);
  font-size: 14px;
  line-height: 14px;
  letter-spacing: .025em;
  border: 2px solid rgba(0,0,0,0.1);
  // border-radius: 0;
}

body:not(.search) .form-field input.input,
body:not(.search) .form-field select.input {
  height: 42.3333px;
}
textarea.profile {
  height: 164px;
}
.form-field .input:focus {
  border-color: map-get($palette, btn-dark-blue);
}
.form-field-label {
  text-transform: capitalize;
}

// checkboxes
.checkbox-item {
  display: block;

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0;

    &:checked + label span.checked,
    &:checked:active + label span.checked{
      display: block;
    }
    &:checked + label span.not-checked,
    &:checked:active + label span.not-checked{
      display: none;
    }
  } // NOTE: input checkbox end



  label {
    cursor: pointer;
    @include flex(flex,nowrap,jc_start,ai_center);

    span {
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      color: #7a7a7a;
      letter-spacing: .1em;
      text-transform: capitalize;

      &.text {
        margin-left: 10px;
      }

      &.checked {
        display: none;

        div {
          padding-top: 2px;
          height: 14px;
          width: 14px;

          background-color: map-get($palette, btn-dark-blue);
          border: thin solid map-get($palette, btn-dark-blue);
          box-shadow: inset 0 0 0 3px $white;
          // fill: #098642;
        }
      }

      &.not-checked {

        div {
          padding-top: 2px;
          height: 14px;
          width: 14px;
          box-shadow: inset 0 0 0 1px #7a7a7a;
          // fill: #7a7a7a;
        }
      }

    }


  }
}


// new opp checkbox styles
.checks-box {
  padding: 10px 0!important;
  height: 100%;
  max-height: 200px!important;
  overflow: scroll;
  // &::-webkit-scrollbar {
  //   width: 1em;  /* for vertical scrollbars */
  // }
  // &::-webkit-scrollbar-track {
  //   // background: map-get($palette, btn-dark-blue);
  //    -webkit-box-shadow: inset 0 0 6px $white;
  //    width: 1em;
  // }
  // &::-webkit-scrollbar-thumb {
  //   // background: rgba(0, 0, 0, 0.5);
  //   background-color: map-get($palette, btn-dark-blue);
  //   border-radius:5px;
  //   width: 10px;
  // // outline: 1px solid slategrey;
  // }

  .list-item {
    padding: 0px!important;
    padding-left: 20px!important;
  }
}
.custom-checks-box .checks-box ul.checks-list li .check-field input[type="checkbox"] + label {
  border: 0;
  position: relative;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.custom-checks-box .checks-box ul.checks-list li .check-field input[type="checkbox"]:checked + label {
  border: 0;
  background-color: transparent;

}

.form-field.checkbox-container .check-wrap .check-field input[type=checkbox]:checked+label {
  background-color: map-get($palette, btn-dark-blue);
  border-color: map-get($palette, btn-dark-blue)!important;
}
.custom-checks-box {
  .check-field input[type="checkbox"] + label {

    visibility: hidden;
  }

}
.check-field input[type="checkbox"] + label {
  color: map-get($palette, btn-light-blue);

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.check-field {
  @include flex(flex,ai_center);
}
.list-item:hover {
  background-color: rgba(map-get($palette, btn-light-blue), .2);
}
.check-field input[type="checkbox"]:checked + label {
  visibility: visible;
}
.check-label {
  width: 100%;
  label {
    width: 100%;
    font-weight: 300!important;
    padding: 9px 0;
    color: map-get($palette, dark-font)!important;
  }
}
// ----- DONE



.btn-container {
  button.button {
    display: inline-block!important;
  }
}
.input-group-append {
  button {

    padding: .375rem .75rem;
  }
}
.fa-edit {
  cursor: pointer;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "";
}
.custom-file-label::after {
  padding: 0;
  border: 0;
}


.file-container {
  background-color: white;

  .filename {
    background-color: white;
  }
}

.form-field .icons-container .spinner-container {
  opacity: 0;
  transform: transformY(-50%) scale(1)!important;
}
.form-field.show-spinner .icons-container .spinner-container {
  opacity: 1;
  transform: transformY(-50%) scale(1)!important;
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  margin-bottom: 10px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 13px;
  height: 15px;
  border: 1px solid #cccccc;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 7px;
  height: 7px;
  background: #0c4173;
  position: absolute;
  top: 6px;
  left: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 2px;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
