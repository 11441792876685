#rep-orders {

  .table {
    max-width: 1072px;
    
    .thead {

    }

    .flex {
      padding: 0 15px;
      border-radius: .5em;
      &:not(:first-child) {
        background-color: $white;
      }

      &:not(:last-child):not(:first-child) {
        margin-bottom: 15px;
      }
      .data {
        display: flex;
        flex: 1;
        max-width: 100%;
        align-items: center;
        height: 75px;
        
        &:nth-child(3) {
          width: 125px;
          flex: unset;
        }
        &:nth-child(2) {
          width: 330px;
          flex: unset;
        }
      }
      .data:last-child {
        width: 266px;
        text-align: end;
      }
    }
    .btn {
      width: 205px;
    }
    & .labels{
      & .data{
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #8e8f90;
      }
    }
    & .data{
      font-size: 14px;
      color: #5d5d5d;
    }
  }
  & .total-revenue{
    font-size: 16px;
    color: #1a2344;
  }
}