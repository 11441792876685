nav {
  background-color: $topbar-bg-color;
  min-height: $topbar-height;
  padding: 0 !important;
  @include media-type(tablet) {
    min-height: $topbar-mobile-height;
  }

  .navbar-brand {
    @include media-type(laptop) {

      height: 85px !important;
      margin-right: 10px !important;
    }
  }

  & > .container {
    max-width: $nav-container-max-width;
    max-height: none;

    padding: 0 4rem !important;

    @include media-type(laptop) {

      padding: 0 2rem !important;

      .form-inline {
        .navbar-nav {
          width: 100%;
          .nav-item {
            padding: 10px 0;
            a, span {
              width: 100%;
              justify-content: center;
            }
          }
        }
      }
    }

    .navbar-nav {
      @include media-type(tablet) {
        margin-right: inherit !important;
        width: 100%;
        order: 2;
      }
      .nav-item {
        a {
          @include media-type(tablet) {
            justify-content: center;
          }
        }
      }
    }
    .collapse.show {
      max-height: calc(100vh - 85px);
      overflow: hidden;
      overflow-y: auto;
      padding-bottom: 20px;
    }

    //@include media-type(tablet, min) {
    @media screen and (min-width: 1113px) {
      @include flex(nowrapi);
      .hamburger {
        display: none !important;
      }
      .collapse {
        @include flex(flexi);
        padding-bottom: 0 !important;
        .flex {
          flex: 1;
        }
      }
      .navbar-nav {
        @include flex(row);
      }
    }
  }

  .navbar-brand, .nav-link, .dropdown-item {
    color: $nav-link-color;
    height: $nav-link-height;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: .025em;

    @include media-type(tablet) {
      height: 80px;
    }
    i {
      margin-right: 8px;
      color: inherit;
    }

    .nav-logo, .nav-item {
      height: 100%;
    }
  }
  .nav-link {
    width: 100%;
  }
  .nav-item.dropdown {
    display: block;
  }
  .dropdown-menu {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding-top: .525rem;
    .dropdown-inner-menu {
      background-color: $topbar-bg-color;
      border-radius: .25rem;
      .dropdown-item {
        height: auto;
        padding: 10px 20px;
        &:hover {
          background-color: inherit;
          color: $white;
          border-radius: .25rem;
        }
      }
    }
  }

  .dropdown-toggle::after {
    content: none;
  }
  .navbar-collapse {
    background-color: $topbar-bg-color;
  }

  .form-inline {
    @include media-type(tablet) {
      width: 100%;
      order: 1;
    }
    .navbar-nav {
      padding: 0;

      .nav-item {
        padding-bottom: 0;

        .btn {
          font-size: 14px;
          letter-spacing: .025em;
        }
      }
    }
  }
  .navbar-nav {
    padding-bottom: 0;

    .nav-item {
      padding-bottom: 0;

      &:hover .dropdown-menu,
      a[aria-expanded="true"] + .dropdown-menu {

        @include media-type(tablet, min) {
          display: block;
          position: absolute;
        }
      }
      &.nav-btn{
        // Hide the Button as it breaks the layout
        @include media-type(custom, 1113px, 1200px) {
          display: none;
        }
      }

    }
  }
  .nav-item {
    @include flex(flex, ai_center);
  }
  a {
    display: flex !important;
    @include flex(ai_center);
    vertical-align: middle;
  }

  .action-container {
    ul li:first-child {
      margin-right: 20px;
      @include media-type(1111px) {
        margin-right: 0px;
      }
    }
    ul.log-in li:first-child {
      margin-right: 40px;
    }

    .bg-btn-red.lightbox-trigger {
      padding-left: 3.5em;
      padding-right: 3.5em;
    }
  }

  .desktop-hide {
    @include media-type(tablet, min) {
      display: none;
    }
  }
  .mobile-hide {

    @include media-type(tablet) {
      display: none;
    }
  }
}

.navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

nav {
  & .navbar-nav {
    @include media-type(500px) {
      margin-top: 10px;
    }
    & .nav-item {
      & a.nav-link {
        @include media-type(500px) {
          height: 60px;
        }
      }
    }
  }
}
