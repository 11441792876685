.featurette{
  & ul.featurette-list{
    padding: 20px 0;
    //width: 60%;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 20px;
    & li{
      margin: 15px;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #555555;
      background: #f4f4f4;
      transition: background .3s linear;
      display: block;
      box-shadow: 0 7px 20px 10px rgba(0,0,0,.1);
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 13px 39px;
      width: 180px;
      max-width: 180px;
      & a{

      }
      &:hover{
        background: #0f5291;
        color: #fefefe;
        cursor: pointer;
        & a{

        }
      }
    }
  }
  &.featurette-spacing{
    padding: 7em 0;
  }
}
sup.text-sub{
  color: #ff3c0f;
  font-size: 12px;
  font-weight: bold;
}
#publish-rep, #edit-rep{
  & form{
    & .price-info{
      & .box-title{
        background: #082a49;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #ffffff;
        padding: 20px 30px;
      }
      & .pricing{
        padding: 20px 30px;
        border: 1px solid #cccccc;
        & > div{
          border-top: 1px solid #8e8e8e;
          padding: 20px 10px;
          &:first-child{
            border-top: none;
          }
          & p{
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            color: #2f2f2f;
            padding: 0;
            margin: 0;
            &:first-child{
              font-weight: 400;
            }
          }
        }
      }
    }
    & .payment-info{
      & .card-number-container{
        margin-bottom: 0;
        &.double{
          display: block;
          & .form-field{
            width: 100%;
            margin-bottom: 19px;
          }
        }
      }
    }
    & .terms-container{
      & .terms-box{
        height: 150px;
        overflow: auto;
        margin: 10px 0;
        border: 1px solid #cccccc;
        padding: 10px;
      }
      & p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #5d5d5d;
      }
      & .highlight-label{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #5d5d5d;
        padding: 0;
        margin-bottom: 10px;
      }
      & label{
        font-size: 14px;
        line-height: 32px;
        padding: 0 5px 5px;
      }
      & ul{
        & li{
          margin: 10px 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #5d5d5d;
          position: relative;
          padding-left: 15px;
          & i{
            position: absolute;
            left: -15px;
            top: 4px;
            color: #ff3c0f;
          }
        }
      }
    }
    & .content-preview-box{
      width: 380px;
      width: 100%;
      height: 380px;
      overflow: hidden;
      padding-top: 5px;
      text-align: center;
      & img{
        max-width: 100%;
        //width: 100%;
        height: auto;
        border: 1px solid #eceff1;
        max-height: 380px;
      }
    }
  }
}
.form{
  & .image-upload-container{
    & .imageUploadWrap{
      text-align: center;
      position: relative;
      width: 430px;
      height: 400px;
      @include media-type(phone) {
        //width: 100%;
        height: 300px;
      }
      & figure{
        width: 100%;
        height: 100%;
        position: relative;
        &::before{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 100%;
          z-index: 10;
        }
        & img{
          max-width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;
        }
        & div.image{
          width: 100%;
          height: 100%;
          max-width: 100%;
          position: absolute;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;
          &.has-image{
            & ~ .add{
              opacity: 0;
            }
            & ~ .remove{
              opacity: 1;
            }
          }
        }
        & .fa{
          font-size: 40px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 15;
          &.add{
            opacity: 1;
          }
          &.remove{
            opacity: 0;
          }
        }
      }
    }
  }
}
#ratings{
  & .stars{
    & a{
      position: relative;
      & i{
        visibility: hidden;
        opacity: 0;
        width: 0;
        color: #082a49;
        &.on{
          visibility: hidden;
          opacity: 0;
          width: 0;
        }
        &.off{
          visibility: visible;
          opacity: 1;
          width: auto;
        }
      }
    }
    &.selected{
      & a:not(.active) {
        & i{
          &.on{
            visibility: visible;
            opacity: 1;
            width: auto;
          }
          &.off{
            visibility: hidden;
            opacity: 0;
            width: 0;
          }
        }
      }
      & a.active~a{
        & i{
          &.on{
            visibility: hidden;
            opacity: 0;
            width: 0;
          }
          &.off{
            visibility: visible;
            opacity: 1;
            width: auto;
          }
        }
      }
      & a.active{
        & i{
          &.on{
            visibility: visible;
            opacity: 1;
            width: auto;
          }
          &.off{
            visibility: hidden;
            opacity: 0;
            width: 0;
          }
        }
      }
    }
  }
  & select#star-rate{
    display: none;
  }
}

.content-preview-box {
  img {
    max-width: 100%;
  }
}