
// NOTE: nav
$nav-container-max-width: 100%;

// NOTE: FRONTPAGE
$front-page-hero-height: 108vh;
$front-page-hero-bg-color: #0c4173;
$front-page-hero-container-width: 1811px;
$front-page-row-width: 1300px;
$front-page-main-padding: 0 0 145px 0;

// NOTE: PAGE
$main-padding:  98px 0;


// NOTE: register
$register-form-width: 526px;
$register-form-height: 780px;
$register-form-btn-color: map-get($palette, btn-grey);
$register-form-padding: 2rem;

// NOTE: dashboard
$dashboard-hero-height: 345px;
$dashboard-mobile-hero-height: 300px;

// NOTE: profile
$profile-hero-height: 735px;
$profile-hero-padding-top: $topbar-height;
$profile-hero-padding-top-mobile: $topbar-mobile-height;
$profile-image-height: 140px;
$profile-image-width: 140px;
// NOTE: contact
$contact-hero-min-height: 658px;
