#dashboard{
  & .banner{
    &.banner-square{
      max-height: 250px;
    }
    &.banner-fullbanner{
      max-height: 60px;
    }
    &.banner-leaderboard{
      max-height: 90px;
    }
    &.banner-halfpage{
      max-height: 600px;
    }
    & a{
      & img{
        max-width: 100%;
      }
    }
  }
}
.banner {
  display: block;
  text-align: center;
  overflow: hidden;
  margin: 20px 0;
  &.banner-leaderboard{
    & a{
      & img{
        max-width: 100%;
      }
    }
  }

  >a,>img,>div {
    display: inline-block;
  }
}

.banner-square {
  display: none;
}
.banner-fullbanner {
  display: none;
}

@media screen and (max-width: 1520px) and (min-width: 1272px) {
  .banner-leaderboard {
    display: none;
  }
  .banner-fullbanner {
    display: block;
  }
}

@media screen and (max-width: 815px) {
  .banner-leaderboard {
    display: none;
  }
  .banner-fullbanner {
    display: block;
  }
}

@media screen and (max-width: 560px) {
  .banner-fullbanner {
    display: none;
  }
  .banner-square {
    display: block;
  }
}