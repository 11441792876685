
[id^="op-card"] {
  width: 313px;
  height: 385.5px;
  // max-width: 313px;
  padding: 0px!important;

  .card-header {
    background-color: map-get($palette, off-white);
    padding-left: 8px!important;
    padding-right: 8px!important;

    .col {
      @include media-type(485px) {
        -ms-flex-preferred-size: 0!important;
        flex-basis: 0!important;
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1!important;
        max-width: 100%!important;
      }
    }
    .rounded-div {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }

    .border-bottom {
      height: 100%;
    }

    .controls {
      @include flex(flex, jc_end,ai_center);

      .fa-star {
        color: $med-grey;
        margin-right: 8px;
        cursor: pointer;
        &.favourited{
          color: map-get($palette, yellow) !important;
        }
      }
      .tip-action {
        height: 14px;
        line-height: 14px;
      }
      // .fa-circle {
      //   color: limegreen;
      // }
    }
  }

  .card-body {
    .body-notification {
      @include flex(flex, jc_space_between, ai_center);
      padding: 5px 0;
      p {
        margin-bottom: 0;
      }
    }
  }


  .card-footer {
    padding: 0;
    border-top: 1px solid rgba(0,0,0,.3);

    & div:first-child {
      padding: 12px 20px;
    }
    &.empty {
      div:first-child {
        padding: 26px 20px;
      }
    }

    .btn-group {

      a,span {
        width:50%;
        border-radius: 0;
        height: 60px;
        @include flex(flex,ai_center,jc_center);
      }
    }
  }
}


.card-wrapper {
  padding: 0 8px 8px;
}
