#view-rep {
  .flex {
    @include media-type(tablet) {
      justify-content: center;
      text-align: center;
    }
  }
  .content {
    
    @include media-type(tablet) {
      margin: auto;
    }
  }
  .media {
    & > p {
      width: 115px;
    }
  }

  .content {
    max-width: 422px;
  }

  .img-container {
    padding-right: 50px;
    //align-items: center;
    @include media-type(tablet) {
      margin-bottom: 30px;
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 415px;
    }
  }

  .price {
    .lower {
      align-self: flex-end;
    }
  }

  .btn-div {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .btn {
      flex: 1;
      max-width: 200px;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

}