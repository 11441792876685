// NOTE: FONT AND BG HELPERS
@each $color-key, $color-hex in $palette {

  .bg-#{$color-key} {
    background-color: $color-hex !important;
  }
  .text-#{$color-key} {
    color: $color-hex !important;
  }
  .border-#{$color-key} {
    border-color: $color-hex !important;
  }
}
// NOTE: MARGIN BOTTOM HELPERS
@each $key, $value in $mb-helpers {

  .#{$key} {
    margin-bottom: $value;
  }
}
// NOTE: FONT SIZE HELPERS
@each $key, $value in $fontSize-helpers {

  .#{$key} {
    font-size: $value!important;
  }
}
// NOTE: BORDER SIZE HELPERS
@each $key, $value in $borderWidth-helpers {

  .#{$key} {
    border-width: $value!important;
  }
}

.btn {
  padding: $btn-padding;
  border-radius: $btn-border-radius;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  font-weight: $btn-font-weight;
}
// AA styles
.scr-reader-text{
   // clip: rect(1px, 1px, 1px, 1px);
   top:-1000%;
   height: 1px;
   width: 1px;
   overflow: hidden;
   position: absolute !important;
}
.scr-reader-text:focus     {
    left: 6px;
    top: 7px;
    height: auto;
    width: auto;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 23px 14px;
    background: $white;
    color: $black;
    z-index: 999999;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.6);
  }
