#profile {

  &>.container {
    max-width: 1073px;
    padding: 50px 0;
    .card {
      padding: 35px 56px;
      border: 2px solid rgba(0,0,0,0.1);
      // box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);

      .card-header {
        border-bottom: 1px solid rgba(0,0,0,.1);
        // padding: 1.25rem 0;
        padding: .55rem 0;
        @include flex(ai_center);
        @include media-type(485px) {
          .col {
            flex: none;

            .btn,span {
              width: 100%;
              margin-top: 10px;
            }
          }
        }

        .title {
          @include flex(flex,ai_center);
        }
        .extra {
          @include flex(flex,jc_end);
          padding: 0;
        }

      } // NOTE: end of card-header

      .card-body {
        padding: 0rem 0;
        overflow: hidden;

        .user-data-item {
          padding: 2rem 0;
        }
      } // NOTE: end of card-body

      .card-footer {
        padding: 1.25rem 0;
      } // NOTE: end of card-body


    } // NOTE: end of card



    .row {
      -webkit-align-items: center;
      align-items: stretch;
    }
    @include media-type(tablet, min) {

      .profile-image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }


    .associated-firm {

      @include media-type(426px) {
        a:first-child {
          margin-bottom: 15px;
        }
      }

      .assoc-left {
        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }
      .assoc-mid {
        @include media-type(767px) {
          margin-bottom: 15px;
        }

      }
      .assoc-right {

        @include media-type(767px) {
          p {
            text-align: center!important;
          }
        }
      }
    }

    .col3 {
      .left {
        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }
      .middle {
        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }
      .right {
        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }
    }

    .col2 {
      .left {
        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }
      .middle {

        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }
      .right {
        @include media-type(767px) {
          margin-bottom: 15px;
        }
      }

    }
    .education {
      .left {

        &:nth-child(n + 2) {
          margin-left: 10px;
        }
      }
    }
    // waiting-approval was here
  } // NOTE: end of default profile styles




  #hero {
    min-height: $profile-hero-height;
    background-color: $med-grey;
    padding-top: $profile-hero-padding-top;

    @include media-type(tablet) {
      padding-bottom: 20px;
    }
    @include media-type(desktop) {
      min-height: $profile-hero-height;
      padding-top: $profile-hero-padding-top-mobile;
    }

    .btn {
      top: 200px;
      right: 200px;
    }
    & .membership-status{
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }

  form {
    // overflow: hidden;
    // max-height: 1100px;
    // margin-top: 0;
    transition: margin-top .5s linear;

    // .form-button.button {
    //   border-radius: .55rem!important;
    // }
    // .form-button.btn {
    //
    //   border-top-left-radius: .55rem;
    //   border-bottom-left-radius: .55rem;
    // }
  }

  // NOTE: for search button BG
  .icons-container.fill.green {
    background-color: map-get($palette,btn-dark-blue);
  }

  // NOTE: profile pic rounded div
  .rounded-div.profile-pic {
    height: $profile-image-height;
    width: $profile-image-width;
  }



  .industry {
    ul {
      width: 100%;
    }
  }
  .specialization {
    ul {
      width: 100%;
    }
  }


  .fa-plane {
    height: 25px;
    width: 25px;
    display: inline-flex;
    @include flex(ai_center,jc_center);
    background-color: map-get($palette, light-grey);
    color: map-get($palette, btn-dark-blue);
    border-radius: 50%;
  }


  // profile info image
  #profileImageContainer {
    text-align: center;
  }
}

.edit #profile {
  #hero {
    height: 513px!important;
  }
  & .row{
    & .card{
      & .card-body{
        overflow: visible;
      }
    }
  }
}

li[id^="add-"] .btn.form-button{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.firm-members .col.l2 {
  padding-bottom: 30px;
}
.firm-members .item-container {
  .media-body {

    a.profile-link {
      width: 100%;
    }
    span.lightbox-trigger {
      width: 100%;
    }
  }
}
.results-container {
  box-shadow: none!important;
  top: calc(100% + 2px)!important;
  border-bottom: 2px solid rgba(0,0,0,0.1);
  border-right: 2px solid rgba(0,0,0,0.1);
  border-left: 2px solid rgba(0,0,0,0.1);
}
.form-field .selected-container.has-selected {
  border: 2px solid;
  border-color: rgba(0,0,0,0.1);
}
.result:hover,
.result:focus,
.result:active,
.result.selected {
  background-color: rgba(map-get($palette,btn-light-blue),.2)!important;
}

[id^="add"].collapse {

}
.remove-selected {
  background-color: transparent!important;
  color: map-get($palette,btn-red)!important;
  // cursor: pointer;
}
// .col3 {
//   padding: 10px;
// }


.change-password {
  .form-control {
    padding: .375rem .75rem;
  }
  .form-field {

    margin-bottom: 1rem;
  }
  .form-control.password {
    height: auto!important;
  }
  .form-control.match {
    height: auto!important;

  }

}
