#new-opp {

  // background-color: white;

  & > .container {
    max-width: 1005px;
    padding-top: 80px;
  }

  #hero {
    @include flex(flex,ai_center);

    h1 {
      text-align: center;
      width: 100%;
      color: $white;
    }
  } // NOTE: end of hero

  .card-info {

    &.number {
      padding-left: 15px;
    }
    &.cvv {
      padding-left: 77px;
    }
    label {
      margin-right: 10px;
    }

  }
}
