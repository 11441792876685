section#manage-rep, section#manage-adverts {


  .card:not(.op-card) {


    &>.card-body {
      @include media-type(1099px) {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.button.more {
  background-color: #1a2344!important;
}