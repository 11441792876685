.row-block {
  display: block;
  margin: 0 -15px;
}

.btn-darker {
  background-color: #2f2f2f;

  &:hover {
    background-color: darken(#2f2f2f,5%);
  }
}
.btn-md {
  font-size: 14px;
  font-weight: 500;
  padding: 18px 52px;
  border-radius: 6px;
}
.btn-advertise {
  font-size: 15px !important;
  padding: 12px 55px !important;
  font-weight: 500;
  line-height: 1.3 !important;
}

.landing-adv {

  * {
    box-sizing: border-box;
  }

    #hero {
      margin-top: -65px;
      background: rgb(21,109,190); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(21,109,190,1) 0%, rgba(17,83,143,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(21,109,190,1) 0%,rgba(17,83,143,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(21,109,190,1) 0%,rgba(17,83,143,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#156dbe', endColorstr='#11538f',GradientType=0 ); /* IE6-9 */
      height: 100vh;
      padding: 0 0 140px 0;
      min-height: 750px;
      max-height: 1000px;

      .image {
        padding-left: 50px;
      }
    }

  .hero-content {
    float: right;
    padding-right: 50px;
    position: relative;
    top: 50%;
    @include translate(0,-50%);

    h1 {
      letter-spacing: 1px;
    }
  }

  .action-item {
    .item-title {
      letter-spacing: 1px;
      max-width: 270px;
      margin: 0 auto;
    }
  }

  .know-your-target {
    .img-container {
      padding-top: 150px;
    }
  }

  .know-your-target-cube {
    position: absolute;
    bottom: 40px;
    left: 8%;
  }

  .personalised {
    .item-text {
      padding-left: 80px;
    }
  }

  .cost-effective {
    padding: 60px 0;

    .img-container {
      padding-left: 80px;
    }
  }

}

.preview-ad {
  max-width: 100%;
  overflow: auto;
  padding: 5px 0 ;
  margin-bottom: 10px;

  img {
    width: auto;
    max-width: none;
  }
}

.preview-leaderboard {
  width: 728px;
  height: 90px;
  background-color: #ccc;

  img {
    max-width: 728px;
    max-height: 90px;
  }
}
.preview-fullbanner {
  width: 468px;
  height: 60px;
  background-color: #ccc;

  img {
    max-width: 468px;
    max-height: 60px;
  }
}
.preview-square {
  width: 300px;
  height: 250px;
  background-color: #ccc;

  img {
    max-width: 300px;
    max-height: 250px;
  }
}
.preview-halfpage {
  width: 300px;
  height: 600px;
  background-color: #ccc;

  img {
    max-width: 300px;
    max-height: 600px;
  }
}




@media screen and (max-width: 825px) {
  .container{
    & .featurette{
      &.cost-effective{
        & .col-lg-8{
          display: flex;
          flex-wrap: wrap;
        }
        & a.btn{
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {

  .landing-adv {

      #hero {
        height: auto;
        padding: 75px 0 220px 0;
        max-height: none;

        .image {
          padding-left: 15px;
          text-align: center;
        }
      }

    .hero-content {
      text-align: center;
      float: none;
      @include translate(0,0);
      position: static;
      padding-right: 0;
      padding-bottom: 50px;
    }

    .know-your-target-cube {
      display: none;
    }

  }



}

@media screen and (max-width: 767px) {

  .landing-adv {

    .know-your-target {
      .img-container {
        padding-top: 0;
        text-align: center;
        justify-content: center !important;
        margin-top: 20px !important;

        img {
          max-width: 75%;
        }
      }
    }

    .personalised {
      .item-text {
        padding-left: 30px;
        padding-top: 50px;
      }
    }

    .cost-effective {
      padding: 0;

      .img-container {
        padding-left: 80px;
      }
    }

  }
}

