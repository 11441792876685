#lfp {

  padding-bottom: 2rem;

  #hero {
    section {
      h1, h2, h3 {
        text-align: left;
      }
      .status {
        color: map-get($palette, active);
      }
      .favorited {
        color: map-get($palette, yellow);
      }
    }
    img {
      margin-bottom: -2%;
    }
  }
  & > .container {
    max-width: 1073px;
    margin-top: 50px;
  }

  .card-body {
    padding: 0 50px;
  }

  .col-lg-12 > .card {
    & > .card-body {
      @include media-type(767px) {
        margin-left: -40px;
        margin-right: -40px;
        justify-content: center;
      }
    }
  }
}
