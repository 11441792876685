#publish-rep {
  .field-wrapper {
    max-width: 100%;
    .imageUploadWrap {
      max-width: 100%;
    }
  }

  .accepted-cards {
    img {
      max-width: 100%;
    }
  }
}

.bg-btn-success {
  background-color: #44ae4b;
  border-color: #44ae4b !important;

  &:hover {
    background-color: darken(#44ae4b,5%);
    border-color: darken(#44ae4b,5%) !important;
  }
}

.summary-box {
  border: 1px solid #cccccc;
}

.summary-box-header {
  color: #fff;
  background-color: #082a49;
  padding: 20px 35px;
  font-size: 16px;
}

.summary-box-content {
  padding: 18px 25px;
}

.summary-table {
  width: 100%;
  color: #2f2f2f;

  td, th {
    border-bottom: 1px solid #8e8e8e;
    padding: 24px 10px;

    &:last-child {
      text-align: right;
    }
  }

  th {
    border-bottom: 3px solid #696969;
    padding: 10px 10px 20px 10px;
  }

  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }

    &.total {
      td {
        padding-top: 40px;

        &:last-child {
          font-size: 18px;
          color: #082a49;
        }
      }
    }
  }
}