body.membership-page{
  & main{
    background-color: #f4f4f5;
    @include media-type(phone) {
      padding-top: 110px;
    }
  }
}
section#memberships{
  & .title-section{
    padding: 20px;
    text-align: center;
    @include media-type(767px) {
      padding: 0;
    }
    & h3{
      color: #2f2f2f;
      &.page-title{
        padding-bottom: 20px;
        font-weight: 500;
        @include media-type(640px) {
          font-size: 24px;
          padding-bottom: 10px;
        }
      }
    }
    & p{
      color: #2f2f2f;
      max-width: 60%;
      margin: 0 auto;
      line-height: 22px;
      @include media-type(767px) {
        max-width: 100%;
      }
      @include media-type(640px) {
        max-width: 70%;
      }
    }
  }
  & ul.memberships-details{
    margin: 0;
    list-style: none;
    padding: 40px 0;
    @include flex(flex, ai_center, jc_center);
    @include media-type(640px) {
      @include flex(wrapi);
      padding: 20px 0;
    }
    & .membership{
      max-width: 396px;
      padding: 10px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: #ffffff;
      margin-left: 50px;
      position: relative;
      -webkit-box-shadow: 0px 7px 6px -2px rgba(0,0,0,0);
      -moz-box-shadow: 0px 7px 6px -2px rgba(0,0,0,0);
      box-shadow: 0px 7px 6px -2px rgba(0,0,0,0);
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      min-height: 600px;
      &:first-child{
        margin-left: 0;
      }
      @include media-type(767px) {
        margin-left: 20px;
        -webkit-box-shadow: 0px 7px 6px -2px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 7px 6px -2px rgba(0,0,0,0.2);
        box-shadow: 0px 7px 6px -2px rgba(0,0,0,0.2);
        &:first-child{
          margin-left: 0;
        }
      }
      @include media-type(640px) {
        margin-left: 0;
        margin-top: 20px;
        min-height: 0;
      }
      & .overlay-anchor{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
      & .picture{
        & img{
          max-width: 100%;
          height: auto;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
      }
      & .membership-data{
        padding: 30px 0;
        text-align: center;
        @include media-type(767px) {
          padding: 10px 0;
        }
        & .title{
          font-size: 30px;
          color: #2f2f2f;
          line-height: 36px;
          padding: 5px 0;
          font-weight: 500;
        }
        & .price{
          position: relative;
          padding: 10px 0;
          & span{
            color: map_get($palette, btn-light-blue);
            font-size: 17px;
            font-weight: 700;
          }
          &.free{
            & span{
              background: white;
              padding: 0 20px;
              z-index: 10;
              position: relative;
            }
            &::before{
              content: "";
              z-index: 1;
              width: 100%;
              height: 1px;
              background: map_get($palette, btn-light-blue);
              position: absolute;
              top: 50%;
              left: 0;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
        }
        & .description{
          max-width: 70%;
          margin: 0 auto;
          padding-top: 10px;
          & p{
            font-size: 12px;
            color: #7a8b9b;
            line-height: 22px;
            padding-bottom: 25px;
          }
          & a.btn{
            width: 150px;
            font-size: 15px;
            font-weight: 500;
            background: white;
            border: 1px solid map_get($palette, btn-light-blue);
            &:hover{
              background: map_get($palette, btn-light-blue);
              color: white;
            }
          }
          & .annual-print{
            font-size: 12px;
            line-height: 18px;
            padding-top: 10px;
            padding-bottom: 5px;
          }
          & .fine-print{
            padding-bottom: 0;
            padding-top: 5px;
            font-size: 12px;
            line-height: 14px;
            height: 19px;
          }
        }
      }
      &:hover{
        -webkit-box-shadow: 0px 7px 6px -2px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 7px 6px -2px rgba(0,0,0,0.2);
        box-shadow: 0px 7px 6px -2px rgba(0,0,0,0.2);
        & .membership-data{
          & .description{
            & a.btn{
              background: map_get($palette, btn-light-blue);
              color: white;
            }
          }
        }
      }
    }
  }
  & .memberships-features{
    margin: 50px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(179,179,179,0.75);
    -moz-box-shadow: 0px 0px 15px 1px rgba(179,179,179,0.75);
    box-shadow: 0px 0px 15px 1px rgba(179,179,179,0.75);
    max-width: 100%;
    overflow: hidden;
    & .feature-cols{
      @include flex(flex);
      width: 100%;
      &.heading{
        background-color: #ecf0f4;
        padding: 30px 0;
        @include flex(ai_center);
        @include media-type(499px) {
          padding: 10px 0;
        }
        & p{
          font-size: 16px;
          font-weight: 500;
          color: map_get($palette, $med-grey);
          @include media-type(499px) {
            font-size: 12px;
          }
        }
        & .feature-col{
          padding-left: 30px;
          @include media-type(499px) {
            padding-left: 10px;
          }
        }
      }
      & .feature-col{
        flex: 1;
      }
      & .type-col{
        width: 250px;
        text-align: center;
        border-left: 1px solid #ecf0f4;
        @include flex(flex, column, jc_center, ai_center);
        @include media-type(992px) {
          width: 185px;
        }
        @include media-type(767px) {
          width: 140px;
        }
        @include media-type(640px) {
          width: 110px;
        }
        @include media-type(499px) {
          width: 78px;
        }
        & p{
          max-width: 80%;
          font-size: 12px;
          padding-top: 5px;
          @include media-type(639px) {
            max-width: 100%;
          }
        }
        & i{
          &.green{
            color: #26ac2a;
          }
          &.red{
            color: #e83b37;
          }
          &.blue{
            color: #0f5291;
          }
        }
        &.basic{
          border-left: none;
        }
      }

    }
    & .features{
      background-color: #ffffff;
      & .feature-cols{
        & .feature-col{
          padding: 15px 30px;
          &:first-child{
            padding-top: 25px;
          }
          @include media-type(499px) {
            padding: 10px;
            &:first-child{
              padding-top: 10px;
            }
          }
          & .feature-title{
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #082a49;
            @include media-type(499px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
          & p{
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            color: #7a8b9b;
            @include media-type(499px) {
              font-size: 10px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
section#membership-subscription{
  & .row{
    & h3{
      padding-bottom: 10px;
    }
    & p{
      line-height: 22px;
      padding-bottom: 10px;
    }
  }
}
section#upgrade-membership{
  & .payment-summary-text,
  & p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 0;
  }
  & .row{
    & .summary-table{
      & tr{
        & th{
          padding: 20px 10px 20px;
        }
        &.total{
          & td{
            padding-top: 25px;
          }
        }
      }
    }
  }
}