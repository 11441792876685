#search-people, #search-repository {

  #hero {
    min-height: 504px;
    background-repeat: no-repeat;
    background-size:cover;
    padding-top: $topbar-height;
    padding-bottom: 20px;
    @include media-type(phone) {

      padding-top: 110px;
    }
    .search {
      @include media-type(1280px) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      @include media-type(tablet) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
    }
  }

  & > .container {
    max-width: 1075px;
    padding-top: 80px;
  }

  form.ajax {

    .fields-container {
      margin-bottom: 0;
    }
    .keyword {
      height: 80px;
      display: flex;
      margin-bottom: 15px;

      .form-field .input {
        font-size: 20px;
        line-height: 20px;

        &::placeholder {
          font-size: 20px;
          // line-height: 20px;
          color: #333;
        }
      }
      div {
        display: flex;
        width: 100%;
      }
    }
    .selects {
      @include flex(flex,wrap);
      & > div {

        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;

        padding-right: 10px;

        @include media-type(910px) {
          flex-basis: auto;
          flex-grow: auto;
          padding-right: 0;
        }
        &:not(last-child) {
          margin-bottom: .5rem;
        }
        &:last-child {
          padding-right: 0;

          @include media-type(910px) {
            margin-top: 25px;
            justify-content: center;
          }
        }
      }
      div {
        @include flex(flex);
      }
      & .fields-container{
        width: 100%;
        & .form-field{
          width: 100%;
          & .field-wrapper{
            width: 100%;
            & .form-select{
              width: 100%;
            }
          }
        }
      }
    }
  } // NOTE: end of form

}

.btn-shape {
  border-radius: 10px;
  @include flex(as_end);
}
.btn-container {
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  flex-grow: 0!important;

  button {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}


.left-iceberg {
  height: auto;
  width: 200px;
  left: 0;
  bottom:0;
}
.right-iceberg {
  height: auto;
  width: 200px;
  right: 0;
  bottom:0;
}
.card-ad {
  height: 120px;
  margin-bottom: 58px;
  color: $white;
}

.extra {
  justify-content: flex-end;
    display: flex;
}

.containter-card {
  border: 0;

  & > .card-header {
    border: 0;
  }
  & > [class$='-results'] {
    .animation-wrapper {

      @include media-type(723px) {
        @include flex(jc_center);
      }
    }
  }
  .flex .title, .flex .extra{
    border-bottom: 1px solid rgba(0,0,0,.125);
    align-items: center;
  }
}
