.mCSB_scrollTools {

  // position: absolute;
}
.mCustomScrollBox {
  // position: static;
}


#mCSB_1_dragger_vertical {

  .mCSB_dragger_bar {

    background-color: map-get($palette, btn-dark-blue);
  }
}
