#profile.chat {
  .messages-container {

    @include flex(flex, column);
    max-height: 500px;
    overflow-y: auto;

    .message.Sent .message-data {
      padding: 8px 16px;
      background-color: map-get($palette,light-grey);
      @include flex(flex,ai_end, column);
    }
    .message.Received {
      padding: 8px 16px;
      @include flex(flex,ai_start, column);
    }

  }

}
