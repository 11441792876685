body {
  & .manage-offers{
    & .card{
      & .card-footer{
        & .btn-single{
          width: 100%;
          & a{
            width: 100%;
            border-radius: 0;
            height: 60px;
            @include flex(flex, jc_center, ai_center);
          }
        }
      }
    }
  }
  &.hq {
    & #home {
      & #hero{
        &.hero-content-wrap{
          & h1{
            font-size: 36px;
            line-height: 40px;
            padding-bottom: 20px;
            color: #ffffff;
          }
          & h3{
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 20px;
            color: #ffffff;
          }
          & p{
            font-size: 20px;
            line-height: 26px;
            padding-bottom: 20px;
            color: #ffffff;
          }
          & .home-buttons{
            @include flex(jc_start);
          }
          & .tag-container{
            @include media-type(tablet) {
              -ms-flex-pack: start !important;
              justify-content: flex-start !important;
            }
          }
          & .tagline{
            & h1{
              @include media-type(tablet) {
                text-align: left;
              }
              @include media-type(phone) {
                text-align: center;
              }
            }
            & p{
              @include media-type(tablet) {
                text-align: left;
              }
              @include media-type(phone) {
                text-align: center;
              }
            }
          }
        }
      }
      & .container {
        & .row {
          &.featurette {
            & .featurette-list {
              & li {
                max-width: none;
                width: auto;
                padding: 13px 36px;
              }
            }
          }
          & .firm-right-cube {
            @include media-type(phone) {
              display: none;
            }
          }
          & .list-action {
            padding-top: 25px;
            padding-bottom: 100px;
          }
        }
        & .shadowed-2 {
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }
  }
  & #publish-offer {
    & .sp-replacer {
      width: 100%;
      height: 42.33px;
      border: 2px solid rgba(0, 0, 0, .1);
      & .sp-preview {
        width: 94%;
        height: 100%;
      }
      & .sp-dd {
        padding: 8px 0;
        height: 100%;
        color: map-get($palette, hero-blue);
      }
    }
    & .use-business-profile {
      & .field-wrap {
        & .form-field-textarea {
          width: 100%;
          & textarea {
            min-height: 100px;
          }
        }
        & .description-help {
          & .checkbox-container {
            width: 100%;
            & .check-wrap {
              padding: 0;
              & .check-label {
                & label {
                  padding: 5px 0;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    & .learn-more-section {
      & > .form-field {
        width: 100%;
        &.form-field-textarea {
          margin-top: 22px;
          & textarea {
            min-height: 100px;
          }
        }
      }
    }
    & .form-field-textarea {
      & textarea#how_it_works {
        min-height: 200px;
      }
    }
    & .preview-section{
      padding-top: 20px;
      & > label{
        font-size: 20px;
        line-height: 24px;
      }
      & > p{
        font-size: 14px;
        line-height: 20px;
        padding-top: 5px;
      }
      & .preview{
        & .offer-card {
          width: 100%;
          max-width: 100%;
          margin-top: 20px;
          padding: 12px 50px 0px 40px;
          border: 1px solid #dedede;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          -webkit-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
          -moz-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
          box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
          background: #ffffff;
          @include media-type(1200px) {
            padding: 12px 30px 0 20px;
          }
          & .card-content {
            & .top {
              @include flex(flex, ai_center);
              padding: 30px 0;
              @include media-type(1200px) {
                padding: 20px 0;
              }
              @include media-type(768px) {
                @include flex(column);
                text-align: center;
                padding: 0;
              }
              & .logo {
                min-width: 170px;
                width: 170px;
                height: 160px;
                border: 1px solid #dedede;
                position: relative;
                -webkit-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
                -moz-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
                box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
                overflow: hidden;
                & img {
                  max-width: 100%;
                  max-height: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  -webkit-transform: translate(-50%, -50%);
                  -moz-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                  -o-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                }
              }
              & .offer-content {
                padding: 10px 0 15px 40px;
                @include media-type(768px) {
                  padding: 10px;
                }
                & h2 {
                  font-size: 30px;
                  font-weight: 500;
                  line-height: 26px;
                  color: #2f2f2f;
                  padding-bottom: 20px;
                  @include media-type(768px) {
                    font-size: 24px;
                    padding-bottom: 10px;
                  }
                }
                & p {
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 26px;
                  color: #555555;
                  padding-bottom: 10px;
                  & br{
                    display: none;
                  }
                }
              }
            }
            & .bottom {
              @include flex(flex, ai_center, jc_space_between);
              border-top: 1px solid #dedede;
              padding: 30px 0;
              @include media-type(768px) {
                padding: 10px 0 20px 0;
                @include flex(column);
              }
              & .meta {
                @include media-type(768px) {
                  padding: 10px 0;
                }
                & p {
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  & span {
                    color: #555555;
                  }
                }
              }
              & .buttons {
                & .btn {
                  padding: 12px 45px;
                  @include media-type(1200px) {
                    padding: 12px 36px;
                  }
                  &:first-child {
                    margin-right: 10px;
                  }
                  @include media-type(420px) {
                    display: block;
                    margin: 10px 0;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        & .offer{
          max-width: 100%;
          width: 100%;
          margin-top: 20px;
          & > .container{
            @include media-type(1024px) {
              max-width: 100%;
              padding: 0;
            }
          }
          & .offer-main{
            text-align: center;
            padding: 100px;
            @include media-type(768px) {
              padding: 30px;
            }
            & .logo{
              & img{
                //max-width: 300px;
                max-width: 100%;
                height: auto;
                max-height: 250px;
                @include media-type(768px) {
                  max-width: 100%;
                }
              }
            }
            & .exclusive-offer{
              & h2{
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
                color: #fdfeff;
                padding: 10px 0;
                @include media-type(768px) {
                  font-size: 24px;
                  line-height: 30px;
                }
              }
            }
            & .offer-content{
              padding: 50px 0;
              max-width: 820px;
              margin: 0 auto;
              @include flex(flex, jc_space_between);
              text-align: left;
              @include media-type(768px) {
                padding: 20px 0;
              }
              @include media-type(768px) {
                max-width: 100%;
                @include flex(column);
              }
              & .col{
                @include media-type(768px) {
                  flex-basis: auto;
                }
              }
              & .left-col{
                & ul{
                  & li{
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    padding-bottom: 5px;
                  }
                }
              }
            }
            & h3{
              font-size: 18px;
              font-weight: 500;
              line-height: 22px;
              color: #ffffff;
              padding: 20px 0;
            }
            & p{
              color: #ffffff;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              padding-bottom: 5px;
            }
            & .website{
              text-align: left;
              padding-left: 15px;
              max-width: 820px;
              margin: 0 auto;
            }
          }
          & .offer-card{
            margin-top: 0;
          }
        }
      }
    }
  }
  &.main_hq {
    & main {
      padding-top: 92px;
      padding-bottom: 0;
      @include media-type(tablet) {
        padding-top: 85px;
      }
    }
  }
  & #main-offers {
    & .layout {
      @include flex(flex);
      position: relative;
      @include media-type(1024px) {
        display: block;
      }
      & .left {
        background-color: #313a43;
        padding: 50px 0 30px 0;
        min-width: 350px;
        width: 15%;
        @include media-type(tablet) {
          padding: 30px 0;
          min-width: 320px;
        }
        @include media-type(1024px) {
          width: 100%;
          min-width: 0;
          max-width: 90%;
          margin: 20px auto;
          padding: 0;
        }
        & .panel {
          @include media-type(1024px) {
            border-top: 3px solid #293038;
          }
          &:first-child{
            @include media-type(1024px) {
              border-top: none;
            }
          }
          & .panel-title {
            font-size: 19px;
            font-weight: 500;
            line-height: 22px;
            color: #ffffff;
            padding: 20px 44px;
            position: relative;
            cursor: pointer;
            @include media-type(tablet) {
              padding: 20px 30px;
            }
            @include media-type(420px) {
              font-size: 16px;
            }
            & a{
              font-size: 19px;
              font-weight: 500;
              line-height: 22px;
              color: #ffffff;
              @include media-type(420px) {
                font-size: 16px;
              }
            }
            & .arrow{
              position: absolute;
              top: 50%;
              right: 40px;
              cursor: pointer;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
              @media only screen and (min-width: 1025px) {
                display: none;
              }
              & i {
                position: absolute;
                top: 50%;
                left: 50%;
                color: white;
                padding-right: 0;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
              }
              & .fa-angle-up {
                opacity: 0;
              }
              & .fa-angle-down {
                opacity: 1;
              }
              &.open {
                & .fa-angle-up {
                  opacity: 1;
                }
                & .fa-angle-down {
                  opacity: 0;
                }
              }
            }
            & .fa {
              color: #6d737a;
              padding-right: 20px;
            }
          }
          & .panel-border {
            width: 100%;
            height: 3px;
            background: #293038;
            margin: 20px 0;
            @include media-type(1024px) {
              display: none;
            }
          }
          & ul.categories {
            padding: 20px 40px 40px;
            @include media-type(tablet) {
              padding: 20px 30px;
            }
            @media only screen and (min-width: 1025px) {
              display: block !important;
            }
            @include media-type(1024px) {
              display: none;
              border-top: 3px solid #293038;
            }
            & li {
              display: block;
              margin: 12px 0;
              padding: 0;
              position: relative;
              & a {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: white;
                -webkit-transition: color 0.3s;
                -moz-transition: color 0.3s;
                -ms-transition: color 0.3s;
                -o-transition: color 0.3s;
                transition: color 0.3s;
                &:hover {
                  color: #fc403c;
                }
              }
              & .cat-arrow {
                position: absolute;
                top: 4px;
                right: 10px;
                cursor: pointer;
                & i {
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: white;
                }
                & .fa-angle-up {
                  opacity: 0;
                }
                & .fa-angle-down {
                  opacity: 1;
                }
                &.open {
                  & .fa-angle-up {
                    opacity: 1;
                  }
                  & .fa-angle-down {
                    opacity: 0;
                  }
                }
              }
              & > .sub-menu {
                padding: 20px;
              }
            }
          }
          &.lms-panel{
            & ul.categories{
              padding-top: 10px;
              padding-bottom: 10px;
              @include media-type(1024px) {
                display: block;
                border-top: 0;
                //padding-left: 30px;
                //padding-right: 30px;
                padding: 0;
              }
              & > li{
                margin: 0;
                @include media-type(1024px) {
                  padding: 20px 29px;
                }
                & > a{
                  @include media-type(1024px) {
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 22px;
                  }
                  @include media-type(420px) {
                    font-size: 16px;
                  }
                  @include media-type(360px) {
                    font-size: 14px;
                  }
                }
                & .cat-arrow{
                  @include media-type(1024px) {
                    top: 24px;
                    right: 40px;
                    & i{
                      top: 50%;
                      left: 50%;
                      -webkit-transform: translateX(-50%);
                      -moz-transform: translateX(-50%);
                      -ms-transform: translateX(-50%);
                      -o-transform: translateX(-50%);
                      transform: translateX(-50%);
                    }
                  }
                }
              }
            }
          }
        }
      }
      & .right {
        background: #fbfcfe;
        padding: 60px 0px;
        width: 85%;
        position: relative;
        @include media-type(1200px) {
          padding: 30px 0;
        }
        @include media-type(1024px) {
          width: 100%;
        }
        & .welcome {
          text-align: center;
          & > .container{
            @include media-type(1024px) {
              width: 90%;
              padding: 0;
            }
          }
          & h1 {
            font-size: 61px;
            font-weight: 400;
            line-height: 72px;
            color: map-get($palette, hero-blue);
            @include media-type(768px) {
              font-size: 40px;
              line-height: 50px;
            }
          }
          & h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            color: #2f2f2f;
            padding: 10px 0;
          }
          & .picture {
            padding: 20px 0;
            & img{
              max-width: 100%;
            }
          }
          & p {
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            color: #717171;
            padding-bottom: 20px;
          }
        }
        & .requestLoader{
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          display: none;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          & .spinner-container{
            & .spinner{
              border-top-color: white;
            }
          }
          &.loading{
            display: block;
            & .spinner-container{
              -webkit-transform: translate(50%, -50%);
              -moz-transform: translate(50%, -50%);
              -ms-transform: translate(50%, -50%);
              -o-transform: translate(50%, -50%);
              transform: translate(50%, -50%);
              opacity: 1;
              position: absolute;
              top: 10%;
              right: 50%;
            }
            & .overlay{
              background-color: #dddddd;
            }
          }
        }
        & .offers {
          padding: 10px 50px;
          @include media-type(1200px) {
            padding: 10px 30px;
          }
          & .see-more-container{
            padding: 30px 0;
            width: 100%;
          }
          & .offers-title {
            font-size: 19px;
            font-weight: 500;
            line-height: 24px;
            color: #2f2f2f;
            padding-bottom: 5px;
          }
        }
        & .offer-card {
          width: 100%;
          max-width: 100%;
          margin-top: 20px;
          padding: 12px 50px 0px 40px;
          border: 1px solid #dedede;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          -webkit-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
          -moz-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
          box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
          background: #ffffff;
          @include media-type(1200px) {
            padding: 12px 30px 0 20px;
          }
          & .card-content {
            & .top {
              @include flex(flex, ai_center);
              padding: 30px 0;
              @include media-type(1200px) {
                padding: 20px 0;
              }
              @include media-type(640px) {
                @include flex(column);
                text-align: center;
                padding: 0;
              }
              & .logo {
                min-width: 170px;
                width: 170px;
                height: 160px;
                border: 1px solid #dedede;
                position: relative;
                -webkit-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
                -moz-box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
                box-shadow: 0px 2px 4px 0px rgba(230, 230, 230, 1);
                overflow: hidden;
                & img {
                  max-width: 100%;
                  max-height: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  -webkit-transform: translate(-50%, -50%);
                  -moz-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                  -o-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                }
              }
              & .offer-content {
                padding: 10px 0 15px 40px;
                @include media-type(640px) {
                  padding: 10px;
                }
                & h2 {
                  font-size: 30px;
                  font-weight: 500;
                  line-height: 26px;
                  color: #2f2f2f;
                  padding-bottom: 20px;
                  @include media-type(640px) {
                    font-size: 24px;
                    padding-bottom: 10px;
                  }
                }
                & p {
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 26px;
                  color: #555555;
                  padding-bottom: 10px;
                  & br{
                    display: none;
                  }
                }
              }
            }
            & .bottom {
              @include flex(flex, ai_center, jc_space_between);
              border-top: 1px solid #dedede;
              padding: 30px 0;
              @include media-type(640px) {
                padding: 10px 0 20px 0;
                @include flex(column);
              }
              & .meta {
                @include media-type(640px) {
                  padding: 10px 0;
                }
                & p {
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  & span {
                    color: #555555;
                  }
                }
              }
              & .buttons {
                & .btn {
                  padding: 12px 45px;
                  @include media-type(1200px) {
                    padding: 12px 36px;
                  }
                  &:first-child {
                    margin-right: 10px;
                  }
                  @include media-type(420px) {
                    display: block;
                    margin: 10px 0;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        & .offer{
          max-width: 100%;
          width: 100%;
          & > .container{
            @include media-type(1024px) {
              max-width: 90%;
              padding: 0;
            }
          }
          & .purchase-title{
            padding-bottom: 20px;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            color: #555555;
            text-align: center;
            @include media-type(639px) {
              font-size: 20px;
              line-height: 24px;
            }
            & a{
              font-size: 24px;
              @include media-type(639px) {
                font-size: 20px;
                line-height: 24px;
              }
            }
          }
          & .offer-main{
            text-align: center;
            padding: 100px;
            @include media-type(768px) {
              padding: 30px;
            }
            & .logo{
              & img{
                max-width: 100%;
                height: auto;
                max-height: 250px;
                @include media-type(640px) {
                  max-width: 100%;
                }
              }
            }
            & .exclusive-offer{
              & h2{
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
                color: #fdfeff;
                padding: 10px 0;
                @include media-type(640px) {
                  font-size: 24px;
                  line-height: 30px;
                }
              }
            }
            & .offer-content{
              padding: 50px 0 20px 0;
              max-width: 820px;
              margin: 0 auto;
              @include flex(flex, jc_space_between);
              text-align: left;
              @include media-type(768px) {
                padding: 20px 0;
              }
              @include media-type(640px) {
                max-width: 100%;
                @include flex(column);
              }
              & .col{
                @include media-type(640px) {
                  flex-basis: auto;
                }
              }
              & .left-col{
                & ul{
                  & li{
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    padding-bottom: 5px;
                  }
                }
              }
            }
            & h3{
              font-size: 18px;
              font-weight: 500;
              line-height: 22px;
              color: #ffffff;
              padding: 20px 0;
            }
            & p{
              color: #ffffff;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              padding-bottom: 5px;
            }
            & .website{
              text-align: left;
              padding-left: 15px;
              max-width: 820px;
              margin: 0 auto;
            }
          }
          & .offer-card{
            margin-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
  & .manage-offers {
    & #offers-list {
      & .offer-card {
        height: 300px;
        & .card-body {
          .status {
            padding-top: 8px;
            padding-bottom: 8px;
          }
          .details {
            padding-top: 20px;
            padding-bottom: 20px;
            & .card-text{
              padding: 5px 0;
            }
          }
        }
        & .card-footer{
          & div{
            padding: 0;
          }
        }
      }
    }
  }
}