#profile.messages {



  // NOTE: messages
  .messages {
    padding-bottom: 2em;
    padding-top: 2em;

    .message {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(0,0,0,.1);

      &:hover {
        background-color: $light-grey;
      }
    }
  }
}
