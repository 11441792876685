#profile.friends {
  #friends-list {
    .media {
      figure {

        position: relative;
        top: inherit;
        left: inherit;
        transform: unset;
      }
    }
  }
}
