#profile.purchases {
  #hero {
    min-height: 428px;
  }
  & .purchases{
    & .no-results{
      padding-top: 40px;
    }
  }
}
