.lightbox {
  overflow-y: auto;
  //unset because visibility is not able to be transitioned iow usless for the perpose it's being used for
  visibility: unset!important;
  opacity: 0;
  transition: opacity .3s linear;
  &.show {
    opacity: 1;

    .lightbox-content {
      transform: translateY(150px);
      transition: transform .4s ease-out,-webkit-transform .4s ease-out!important;
    }
  }

  .overlay {
    background-color: transparent;
    background: transparent;
  }
  .lightbox-content {
    position: static!important;
    margin: auto;

    transition: transform .4s ease-out,-webkit-transform .4s ease-out!important;
    transform: translate(0,25%);

    h2 {
      background-color: $topbar-bg-color!important;
      border-bottom: 5px solid map-get($palette, hero-blue)!important;
    }
    button {
      background-color: map-get($palette, hero-blue)!important;
    }
  }

}
