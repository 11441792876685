.picker__nav--prev:before, .picker__nav--next:before {
  border-right-color: map-get($palette, btn-light-blue);
}
.picker__nav--next:before {
  border-left-color: map-get($palette, btn-light-blue);
}
.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected {
  background: map-get($palette, btn-light-blue);
}
.picker__day--today:before {
  border-top-color: lighten(map-get($palette, btn-light-blue), 20%);
}
.picker__holder {
  border: 2px solid map-get($palette, light-grey);
  width: 289px;
  position: absolute;
}
