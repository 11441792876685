//overlay mixin

@mixin active-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@mixin absolute-center {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@mixin bg-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
// $breakpoints: (
//   // phone: 768px,
//   phone: 825px,
//   tablet: 1024px,
//   laptop: 1700px,
//   desktop: 1700px
// );

@mixin translate($x, $y) {
  transform: translate($x, $y);
}
@mixin box-shadow($a: 0, $b: 4px, $c: 7px, $d: 4px, $opacity: .2 ) {
    -webkit-box-shadow: $a $b $c $d rgba(0,0,0,$opacity);
    -moz-box-shadow: $a $b $c $d rgba(0,0,0,$opacity);
    box-shadow: $a $b $c $d rgba(0,0,0,$opacity);
}
@mixin box-shadow-1 {
    @include box-shadow(0, 4px, 7px, 4px, .2 );
}

@mixin flex( $args... ) {

  @each $arg in $args {

    @if $arg == flex {
      display: $arg;
    }
    @if $arg == iflex {
      display: inline-flex!important;
    }
    @if $arg == flexi {
      display: flex!important;
    }
    @else if $arg == wrap or $arg == nowrap {
      flex-wrap: $arg;
    }
    @else if $arg == wrapi {
      flex-wrap: wrap!important;
    }
    @else if $arg == nowrapi {
      flex-wrap: nowrap!important;
    }
    @else if $arg == row or $arg == row_reverse or $arg == column or $arg == column_reverse {
      @if $arg == row_reverse {flex-direction: row-reverse;}
      @else if $arg == column_reverse {flex-direction: column-reverse;}
      @else {flex-direction: $arg;}
    }
    @else if $arg == ai_center or $arg == ai_start or $arg == ai_end or $arg == ai_stretch or $arg == ai_baseline or $arg == ai_auto {

      @if $arg == ai_center {align-items: center;}
      @else if $arg == ai_start {align-items: flex-start;}
      @else if $arg == ai_end {align-items: flex-end;}
      @else if $arg == ai_stretch {align-items: stretch;}
      @else if $arg == ai_baseline {align-items: baseline;}
      @else if $arg == ai_auto {align-items: auto;}
      @else {align-items: $args;}

    }
    @else if $arg == as_center or $arg == as_start or $arg == as_end or $arg == as_stretch or $arg == as_baseline or $arg == as_auto {
      @if $arg == as_center {align-self: center;}
      @else if $arg == as_start {align-self: flex-start;}
      @else if $arg == as_end {align-self: flex-end;}
      @else if $arg == as_stretch {align-self: stretch;}
      @else if $arg == as_baseline {align-self: baseline;}
      @else if $arg == as_auto {align-self: auto;}
      @else {align-self: $args;}
    }
    @else if $arg == jc_center or $arg == jc_start or $arg == jc_end or $arg == jc_space_around or $arg == jc_space_between {
      @if $arg == jc_center {justify-content: center;}
      @else if $arg == jc_start {justify-content: flex-start;}
      @else if $arg == jc_end {justify-content: flex-end;}
      @else if $arg == jc_space_around {justify-content: space-around;}
      @else if $arg == jc_space_between {justify-content: space-between;}
      @else {justify-content: $args;}
    }
    @else {
      display: flex;
    }
  }
}

@mixin split-row-container-lg($max-width){
    position: relative;
    width: 90%;
    max-width: 1365px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $max-width) {
        padding-left: 0;
        padding-right: 0;
        width: 88%;
    }
}
// NOTE: MEDIA QUERY MIXIN
@mixin media-type($media, $min:null, $max:null) {

  @if $media == phone and $min == min {
    @media only screen and ( min-width: map-get($breakpoints, "phone") ){ @content; }
  }
  @else if $media == tablet and $min == min {
    @media only screen and ( min-width: map-get($breakpoints, "tablet") ){ @content; }
  }
  @else if $media == laptop and $min == min {
    @media only screen and ( min-width: map-get($breakpoints, "laptop") ){ @content; }
  }

  @else if $media == phone {
    @media only screen and (max-width: map-get($breakpoints, "phone")) { @content; }
  }
  @else if $media == tablet {
    @media only screen and (max-width: map-get($breakpoints, "tablet")) { @content; }
  }
  @else if $media == laptop {
    @media only screen and (max-width: map-get($breakpoints, "laptop")) { @content; }
  }
  @else if $media == desktop {
    @media only screen and (min-width: map-get($breakpoints, "desktop")) { @content; }
  }
  @else if $media == custom {
    @media only screen and (min-width: $min) and (max-width: $max) { @content; }
  }

  @else {
    @media only screen and (max-width: $media) { @content }
  }

}
