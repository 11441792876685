#contact {
  padding: $main-padding;
  background: $light-grey;

  form {
    max-width: $register-form-width;
    min-height: $contact-hero-min-height;
    background-color: rgba($white, .7);
    padding: $register-form-padding;
    position: relative;

    input, select {
      border-radius: 10px;
    }
    button {
      position: absolute;
      left: 0;
      bottom: -4%;
      background-color: $register-form-btn-color;
      width: 100%;
      border-radius: 10px;
    }
  }
}
