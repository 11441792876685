h1 {
  font-size: 61px;
  font-weight: normal;
  font-family: 'Fira Sans', sans-serif;
  @include media-type(laptop) {
    font-size: 55px !important;
  }
  @include media-type(phone) {
    font-size: 40px !important;
  }
}

h2 {
  color: map-get($palette,dark-font);
  font-size: 42px;
  line-height: 42px;
  letter-spacing: .025em;
  font-family: 'Fira Sans', sans-serif;
  @include media-type(laptop) {
    font-size: 35px
  }
  @include media-type(phone) {

    font-size: 30px
  }
}

h3 {
  color: map-get($palette,dark-font);
  font-size: 30px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
  @include media-type(laptop) {
    font-size: 30px;
  }
  @include media-type(phone) {

  }
}
a {
  color: #082a49;
  font-weight: bold;
  font-family: 'Fira Sans', sans-serif;
  @include media-type(laptop) {

  }
  @include media-type(phone) {

  }
}
label {
  font-weight: normal!important;
  color: map-get($palette, dark-font);
  font-family: 'Fira Sans', sans-serif;
  @include media-type(laptop) {

  }
  @include media-type(phone) {

  }
}
p {
  padding-bottom: 0;
  // margin-bottom: 11px;
  font-family: 'Fira Sans', sans-serif;
  @include media-type(laptop) {

  }
  @include media-type(phone) {
    font-size: 14px;
  }
}
