// VARIABLES

//    1. Node display Settings
//    -------------------------
$theme-display: flex !default;
// defaults to column to offer a dom flow similar to block.
$theme-flex-direction: column !default;

$container-width: 1550px;

// //    1. Lists
// //    ----------
// $theme-list-bullet: url('../../../assets/images/mini-logo-icon.png');
// $theme-list-margin-left: 60px;
// $theme-list-bullet-width: 22px;
// $theme-list-bullet-height: 22px;



//    1. Breakpoints
//    ---------------
// TODO: script to auto generate media queries in the media-type mixin
$breakpoints: (
  "phone": 825px,
  "tablet": 1112px,
  "laptop": 1366px,
  "desktop": 1366px
);

//    1. Margin Helpers
//    ---------------
// TODO: script to auto generate media queries in the media-type mixin
$mb-helpers: (
  mb-0: 0,
  mb-1: 1em,
  mb-2: 2em,
  mb-3: 3em,
  mb-4: 4em,
  mb-5: 5em,
);

$fontSize-helpers: (
  text-12: 12px,
  text-13: 13px,
  text-14: 14px,
  text-15: 15px,
  text-16: 16px,
  text-20: 20px,
  text-30: 30px,
  text-80: 80px,
);
$borderWidth-helpers: (
  border-1: 1px,
  border-2: 2px,
);

//    1. Colors
//    ---------

// NOTE: for colors that you don't need helpers for
$white: #fefefe;
$light-grey: #f0f2f5;
$med-grey: #555555;
$dark-grey: #e83b37;
$black: #0a0a0a;
$hero-bg-color: #0c4173;

// DONE: for use of colors that need helpers
$palette: (
  transparent: transparent,
  white: #fefefe,
  off-white: #f4f4f4,
  light-grey: #dedede,
  nav-blue: #cc4b37,
  hero-blue: #0a3761,
  btn-light-blue: #0f5291,
  btn-dark-blue: #1a2344,
  btn-red: #e83b37,
  btn-grey: #2f2f2f,
  active: #58d25d,
  pink: #ad2b53,
  font: #5d5d5d,
  dark-font: #2f2f2f,
  yellow: #f2d900,
  unfavourite: #f2d900,
  muted: #939393,
  green: #28a745,
  orange: orange
);





//    2. Fonts
//    ---------
$font-stack: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$strong-font-weight: 700;
// $p-font-size: responsive-font(32px, 35px, 150px, 50px);

//        P
//    ---------

$p-font-size: 16px;
$p-line-height: 23px;
$p-color: $med-grey;
$p-margin-bottom: 19px;
$p-font-weight: 500;


//        H1
//    ---------

$h1-color: $black;
$h1-color-2: $med-grey;
$h1-font-size: 37px;
$h1-line-height: 37px;
$h1-letter-spacing: .18em;
$h1-font-weight: 300;
$h1-margin-bottom: 10px;
// $h1-margin-bottom: 10px;

$h1-font-size-laptop: 37px;
$h1-line-height-laptop: 37px;
$h1-margin-bottom-laptop: 10px;

$h1-font-size-tablet: 37px;
$h1-line-height-tablet: 37px;
$h1-margin-bottom-tablet: 10px;

$h1-font-size-phone: 34px;
$h1-line-height-phone: 34px;
$h1-margin-bottom-phone: 10px;


//        H2
//    ---------

$h2-color: $black;
$h2-font-size: 29px;
$h2-line-height: 29px;
$h2-letter-spacing: .22em;
$h2-font-weight: 300;
$h2-margin-bottom: 15px;

$h2-font-size-laptop: 29px;
$h2-line-height-laptop: 29px;
$h2-margin-bottom-laptop: 15px;
$h2-font-weight-laptop: 300;

$h2-font-size-tablet: 29px;
$h2-line-height-tablet: 29px;
$h2-margin-bottom-tablet: 15px;
$h2-font-weight-tablet: 300;

$h2-font-size-phone: 29px;
$h2-line-height-phone: 29px;
$h2-margin-bottom-phone: 15px;
$h2-font-weight-phone: 300;





//        H3
//    ---------


$h3-color: $white;
$h3-font-size: 24px;
$h3-line-height: 24px;
$h3-font-weight: 400;
$h3-margin-bottom: 24px;

$h3-font-size-laptop: 24px;
$h3-line-height-laptop: 24px;
$h3-margin-bottom-laptop: 19px;
$h3-font-weight-laptop: 500;

$h3-font-size-tablet: 24px;
$h3-line-height-tablet: 24px;
$h3-margin-bottom-tablet: 19px;
$h3-font-weight-tablet: 500;

$h3-font-size-phone: 24px;
$h3-line-height-phone: 24px;
$h3-margin-bottom-phone: 19px;
$h3-font-weight-phone: 500;


//      Anchor
//    ---------

$link-color: $white;
$link-text-decoration: none;


$link-font-size: $p-font-size;
$link-line-height: $p-line-height;
$link-font-weight: $p-font-weight;
$link-color-2: map-get($palette, btn-dark-blue);
$link-margin-bottom: 24px;

$link-font-size-laptop: $p-font-size;
$link-line-height-laptop: $p-line-height;
$link-margin-bottom-laptop: 24px;

$link-font-size-tablet: $p-font-size;
$link-line-height-tablet: $p-line-height;
$link-margin-bottom-tablet: 24px;

$link-font-size-phone: $p-font-size;
$link-line-height-phone: $p-line-height;
$link-margin-bottom-phone: 24px;


//    3. BTN
//    ---------
$btn-padding: 12px 15px;
$btn-border-radius: .55rem;
$btn-font-size: 14px;
$btn-line-height:17.54px;
$btn-letter-spacing: .025em;
$btn-font-weight: 400;


//    3. Body
//    ---------
$body-bg-color: $white;
$body-min-height: 100%;

//    3. Header
//    ---------
$header-bg-color: $white;
$header-min-height: 100%;

//    3. Footer
//    ---------
$footer-bg-color: map-get($palette, nav-blue);
$footer-min-height: 141px;
